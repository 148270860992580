import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { H5, MUIIcons } from "../../../../AbstractElements";
import "./style.scss";

const ProfileUserDetails = ({ data }) => {
  return (
    <Row className="user-profile-info pt-2 pb-2 position-relative">
      {data?.map((item, i) => (
        <Col xs={6} lg={3} key={i} className={"d-flex flex-column mb-0"}>
          <div className="profile-user-detail">
            <H5 className="text-gray d-flex gap-1">
              <MUIIcons iconName={item.icon} size={20} /> {item.title}
            </H5>
            <span className="mt-2 text-black">{item.detail}</span>
          </div>
        </Col>
      ))}
    </Row>
  );
};

ProfileUserDetails.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProfileUserDetails;
