import React from "react";
import { Container, Row, Col } from "reactstrap";

import { dynamicImage } from "../../Service";
import "./style.scss";
import { scheduledDate } from "../../utils/helper/helper";
import MUIIcons from "../MUIIcon/MUIIcons";

interface DateRangeDisplayProps {
  startDate: string;
  endDate: string;
}

const DateRangeDisplay: React.FC<DateRangeDisplayProps> = ({
  startDate,
  endDate,
}) => {
  return (
    <Container className="date-range-display p-2">
      <Row className="align-items-center">
        <Col lg="1">
          <MUIIcons
            className="svg-color mt-1"
            size={17}
            iconName="CalendarTodayOutlined"
          />
        </Col>
        <Col className="date-text" xs="auto">
          {startDate && scheduledDate(startDate)}
        </Col>
        <Col lg="1" xs="auto" className="line">
          <img src={dynamicImage("line.svg")} alt="Line" />
        </Col>
        <Col className="date-text" xs="auto">
          {endDate && scheduledDate(endDate)}
        </Col>
      </Row>
    </Container>
  );
};

export default DateRangeDisplay;
