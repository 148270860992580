import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";

import { Btn, H4 } from "../../../../../AbstractElements";
import { ALL_USERS_STATUS, STATUS_200 } from "../../../../../Api/constants";
import CommonModal from "../../../../../CommonElements/Modal";
import StepperHorizontal from "../../../../../CommonElements/StepperHorizontal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  deleteScheduled,
  editScheduledAssessmentCatlog,
  getScheduledDetailsByScheduleId,
  handleBackButton,
  handleNextButton,
  scheduleAssessmentCatlog,
} from "../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../../Service";
import {
  Assessment,
  Back,
  Close,
  Confirm,
  ConfirmSchedule,
  ConfirmScheduleBody,
  ConfirmScheduleHeader,
  END_DATE_REQUIRED,
  Next,
  PleaseAddSomeUsersToSchedule,
  PleaseProvideRequiredInformation,
  START_DATE_GREATER_ERROR,
  START_DATE_REQUIRED,
} from "../../../../../utils/Constant";
import {
  scheduleAssessmentStepperData,
  scrollToTop,
  showToast,
} from "../../../../../utils/helper/helper";

import ParticipantForm from "./forms/ParticipantsForm";
import ScheduleDetailsForm from "./forms/ScheduleDetailsForm";

const ScheduleAssessmentsCatlog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentId, scheduleId, type } = location.state || "";
  const { numberLevel, showFinish, scheduledDetails } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [userCardDetailIds, setUserCardDetailIds] = useState([]);
  const [scheduleDates, setScheduleDates] = useState({
    start_date: "",
    end_date: "",
  });
  const { usersList } = useAppSelector(state => state.user);
  const [errors, setErrors] = useState({});
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [enrolledUsersIds, setEnrolledUsersIds] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [userMap, setUserMap] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (scheduleId && assessmentId) {
      setUserCardDetailIds([]);
      setEnrolledUsers([]);
      setEnrolledUsersIds([]);
      setDeletedIds([]);
      getAllData();
    }
  }, [scheduleId, assessmentId]);

  const getAllData = async () => {
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(
      getScheduledDetailsByScheduleId({
        id: scheduleId,
        assessment_id: assessmentId,
      })
    );
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (scheduledDetails) {
      const dates = {
        start_date: scheduledDetails?.start_date?.split("T")[0] || "",
        end_date: scheduledDetails?.end_date?.split("T")[0] || "",
      };
      setScheduleDates(dates);

      const users = [];
      const ids = [];
      scheduledDetails?.participants?.forEach(item => {
        ids.push(item?.user_id);
        users.push({
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: userMap && userMap[item?.user_id]?.user_name,
          detail: userMap && userMap[item?.user_id]?.designation,
          id: item?.user_id,
          delete_id: item?.id,
        });
      });
      setEnrolledUsersIds(ids);
      setEnrolledUsers(users);
    }
  }, [scheduledDetails]);

  const handleDateChange = e => {
    const { name, value } = e.target;

    setScheduleDates(prevDates => {
      return {
        ...prevDates,
        [name]: value,
      };
    });

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleNextClick = async () => {
    const newErrors: any = {};

    if (scheduleDates?.start_date === "") {
      newErrors.start_date = START_DATE_REQUIRED;
    }

    if (scheduleDates?.end_date === "") {
      newErrors.end_date = END_DATE_REQUIRED;
    }

    if (scheduleDates?.start_date && scheduleDates?.end_date) {
      const startDate = new Date(scheduleDates.start_date);
      const endDate = new Date(scheduleDates.end_date);

      if (startDate >= endDate) {
        newErrors.start_date = START_DATE_GREATER_ERROR;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      showToast(PleaseProvideRequiredInformation, "error");
      return;
    }

    if (!showFinish) {
      dispatch(handleNextButton());
    }

    if (showFinish) {
      if (userCardDetailIds?.length === 0 && !scheduleId) {
        showToast(PleaseAddSomeUsersToSchedule, "error");
        return;
      }
      toggleModal();
    }
    scrollToTop();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="page-body">
      <CommonModal
        sizeTitle={ConfirmScheduleHeader}
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          if (deletedIds?.length > 0) {
            const participants = [];
            deletedIds?.forEach(item => {
              participants.push({ participant_id: item });
            });
            const payload = {
              id: scheduleId,
              assessment_id: assessmentId,
              participants: participants,
            };
            await dispatch(deleteScheduled({ data: payload }));
          }

          const users = [];
          userCardDetailIds?.forEach(item => {
            users.push({
              user_id: item,
            });
          });

          const payload: any = {
            assessment_id: assessmentId,
            start_date: scheduleDates?.start_date,
            end_date: scheduleDates?.end_date,
            participants: users,
          };
          let response = null;
          if (scheduleId) {
            payload.id = scheduleId;
            response = await dispatch(
              editScheduledAssessmentCatlog({ data: payload })
            );
          } else {
            response = await dispatch(
              scheduleAssessmentCatlog({ data: payload })
            );
          }
          if (
            response?.payload?.data?.status_code === STATUS_200 ||
            response?.payload?.data?.schedule_id
          )
            return navigate(
              `${process.env.PUBLIC_URL}/assessments/assessment-details`,
              {
                state: { assessmentId: assessmentId },
              }
            );
        }}
        primaryBtnText={Confirm}
      >
        {ConfirmScheduleBody}
      </CommonModal>
      <Card className="mb-5">
        <CardHeader>
          <H4 className="mt-2">{Assessment}</H4>
        </CardHeader>
        <CardBody>
          <div className="basic-wizard important-validation">
            <StepperHorizontal
              data={scheduleAssessmentStepperData}
              level={numberLevel}
            />
            <div>
              {numberLevel === 1 && (
                <ScheduleDetailsForm
                  start_date={scheduleDates?.start_date}
                  end_date={scheduleDates?.end_date}
                  errors={errors}
                  onDateChange={handleDateChange}
                  type={type}
                />
              )}
              {numberLevel === 2 && (
                <ParticipantForm
                  enrolledUsersIds={enrolledUsersIds}
                  enrolledUsers={enrolledUsers}
                  setEnrolledUsers={setEnrolledUsers}
                  userCardDetailIds={userCardDetailIds}
                  setUserCardDetailIds={setUserCardDetailIds}
                  setEnrolledUsersIds={setEnrolledUsersIds}
                  deletedIds={deletedIds}
                  setDeletedIds={setDeletedIds}
                  type={type}
                />
              )}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="wizard-footer d-flex gap-3 justify-content-end">
            <Btn
              outline
              className="alert-light-primary"
              color="primary"
              onClick={() => {
                scrollToTop();
                return navigate(
                  `${process.env.PUBLIC_URL}/assessments/assessment-details`,
                  {
                    state: { assessmentId: assessmentId },
                  }
                );
              }}
            >
              {Close}
            </Btn>
            {numberLevel > 1 && (
              <Btn
                outline
                className="alert-light-primary"
                color="primary"
                onClick={() => {
                  scrollToTop();
                  dispatch(handleBackButton());
                }}
              >
                {Back}
              </Btn>
            )}
            <Btn color="primary" onClick={handleNextClick}>
              {showFinish ? ConfirmSchedule : Next}
            </Btn>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ScheduleAssessmentsCatlog;
