import PropTypes from "prop-types";
import { useState } from "react";

import MUIIcons from "../MUIIcon/MUIIcons";

import MediaPlayer from "./MediaPlayer";
import PreviewModal from "./PreviewModal";

import "./style.scss";

const FileHandler = ({ mediaUrl, showIcon = false, showPreview = false }) => {
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

  const fileExtension = mediaUrl.split(".").pop().toLowerCase();

  const handleClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (["pdf", "docx", "doc"].includes(fileExtension)) {
      window.location.href = mediaUrl;
    } else if (["mp4", "png", "jpg", "jpeg", "mp3"].includes(fileExtension)) {
      setPreviewModalOpen(true);
    }
  };

  return (
    <div className="file-handler">
      {!showIcon && !showPreview && (
        <div className="mt-1">
          <a href={mediaUrl} onClick={handleClick}>
            {mediaUrl}
          </a>
        </div>
      )}
      {showIcon && (
        <MUIIcons
          size={24}
          className="pointer"
          iconName="ImageOutlined"
          onClick={handleClick}
        />
      )}

      {showPreview && (
        <div className="file-handler__preview-img">
          {["png", "jpg", "jpeg"].includes(fileExtension) ? (
            <img className="img-fluid" src={mediaUrl} alt="Preview" />
          ) : fileExtension === "mp4" ? (
            <video controls>
              <source src={mediaUrl} type="video/mp4" />
            </video>
          ) : fileExtension === "mp3" ? (
            <div>
              <MediaPlayer src={mediaUrl} />
            </div>
          ) : (
            <a href={mediaUrl} onClick={handleClick}>
              {mediaUrl}
            </a>
          )}
        </div>
      )}

      <PreviewModal
        isOpen={isPreviewModalOpen}
        toggle={() => setPreviewModalOpen(!isPreviewModalOpen)}
        mediaUrl={mediaUrl}
      />
    </div>
  );
};

FileHandler.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  showPreview: PropTypes.bool,
};

export default FileHandler;
