import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";

import { DefaultButton, Image, P } from "../../AbstractElements";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import { users } from "../../Data/LoginData/LoginData";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { setSideBarMenuItems } from "../../ReduxToolkit/Reducers/LayoutSlice";
import { dynamicImage } from "../../Service";
import {
  CreateAccount,
  DoNotAccount,
  UserName,
  ForgotPassword,
  Href,
  Password,
  LoginText,
  EnterUsername,
  EnterPassword,
  PleaseEnterValidEmailAndPassword,
} from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  saveToLocalStorage,
} from "../../utils/helper/localStorageutils";
import useIsMobile from "../../utils/helper/responsive";

const Login = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const SimpleLoginHandle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const user = users.find(
      user =>
        user.email.toLocaleLowerCase() === email.toLocaleLowerCase() &&
        user.password === password
    );
    if (user) {
      saveToLocalStorage(LOGGED_IN_USER, user);
      dispatch(setSideBarMenuItems());
      return navigate(`${process.env.PUBLIC_URL}/dashboard`);
    } else {
      showToast(PleaseEnterValidEmailAndPassword, "error");
    }
  };

  return (
    <Row className="min-vh-100">
      {!isMobile && (
        <Col className="bg-white d-flex align-items-center justify-content-center">
          <Image
            className="img-fluid for-light"
            src={dynamicImage("login/login_page.png")}
            alt="logo"
          />
        </Col>
      )}
      <Col lg="6" sm="12" xs="12" className="bg-white">
        <Container fluid className="p-0 min-vh-100 d-flex align-items-center">
          <Row className="m-0 w-100 justify-content-center">
            <Col>
              <div className="p-5">
                <div className="mb-2 text-center">
                  <Link to={Href}>
                    <Image
                      className="img-fluid for-light"
                      src={dynamicImage("logo/login_logo.png")}
                      alt="loginpage"
                    />
                  </Link>
                </div>
                <div className="login-main">
                  <Form
                    className="theme-form"
                    onSubmit={e => SimpleLoginHandle(e)}
                  >
                    <FormGroup>
                      <LabelTooltip
                        important={false}
                        label={UserName}
                        tooltipText={EnterUsername}
                      />
                      <Input
                        type="email"
                        required
                        placeholder={EnterUsername}
                        value={email}
                        name="email"
                        onChange={event => setEmail(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <LabelTooltip
                        important={false}
                        label={Password}
                        tooltipText={EnterPassword}
                      />
                      <div className="form-input position-relative mb-5">
                        <Input
                          type={show ? "text" : "password"}
                          placeholder={EnterPassword}
                          onChange={event => setPassword(event.target.value)}
                          value={password}
                          name="password"
                        />
                        <div
                          className="show-hide mt-2"
                          onClick={() => setShow(!show)}
                        >
                          <span className="show"> </span>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-0 form-sub-title">
                      <Link
                        className="d-none"
                        to={`${process.env.PUBLIC_URL}/authentication/forget_password`}
                      >
                        {ForgotPassword}
                      </Link>
                      <div className="mt-3">
                        <DefaultButton type="submit" color="primary" block>
                          {LoginText}
                        </DefaultButton>
                      </div>
                    </FormGroup>
                    <P className="mt-4 mb-0 d-none text-center">
                      {DoNotAccount}
                      <Link
                        className="ms-2"
                        to={`${process.env.PUBLIC_URL}/authentication/register_simple`}
                      >
                        {CreateAccount}
                      </Link>
                    </P>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default Login;
