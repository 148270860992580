import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Card, CardBody, Col, FormGroup, Row } from "reactstrap";

import {
  Badges,
  DefaultButton,
  MUIIcons,
  H3,
  H4,
} from "../../../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  EXPERTISE_LEVEL_STATUS,
} from "../../../../../Api/constants";
import FileHandler from "../../../../../CommonElements/FileHandler";
import FilterSliderModal from "../../../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import MultiFileUpload from "../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import MultiSelectDropdown from "../../../../../CommonElements/MultiSelectDropdown";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import { getCourseList } from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getRAByModuleId,
  updateRAForQB,
} from "../../../../../ReduxToolkit/Reducers/QuestionBankSlice";
import { uploadFileApi } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  ClickHereToAddReferenceFile,
  Confirm,
  CourseType,
  DeleteFileHeader,
  DeleteMaterialFile,
  Enter,
  MapCourse,
  MapCourseError,
  OtherRemedialAction,
  PdfFileSizeNote,
  RemedialActions,
  Save,
  Select,
  SomethingWentWrong,
} from "../../../../../utils/Constant";
import {
  courseFilterDropdownList,
  richTextSupportedItems,
  showToast,
} from "../../../../../utils/helper/helper";

const RemedialAction = ({ selectedModule }) => {
  const dispatch = useAppDispatch();
  const { courseList } = useAppSelector(state => state.course);
  const { raByModuleIdList } = useAppSelector(state => state.questionBank);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [courseListData, setCourseListData] = useState([]);
  const [preSelected, setPreSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [remedialFiles, setRemedialFiles] = useState([]);
  const [flushData, setFlushData] = useState(false);
  const [note, setNote] = useState("");
  const [raForModule, setRaForModule] = useState({
    course: [],
    media_url: [],
    note: "",
  });

  useEffect(() => {
    getRa();
  }, [selectedModule]);

  const getRa = async () => {
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
      })
    );
    if (selectedModule || selectedModule === 0) {
      const module_id = selectedModule;
      if (module_id) await dispatch(getRAByModuleId({ id: module_id }));
    }
  };

  useEffect(() => {
    setRemedialFiles([]);
    if (raByModuleIdList && courseList) {
      const courses = [];
      const urls = [];
      const courseMap = [];
      raByModuleIdList?.remedial_action?.forEach(item => {
        if (item.course_id) courses.push(item.course_id);
        if (item.media_url) urls.push(item.media_url);
      });
      courseList?.course_list?.forEach(item => {
        if (courses.includes(item?.id))
          courseMap.push({
            name: item?.id?.toString(),
            label: item?.course_name,
            value: item?.course_name,
          });
      });

      const updatedRemedialAction = {
        course: courseMap,
        media_url: urls,
        note: "",
      };
      setNote(raByModuleIdList?.note || "");
      setPreSelected(courseMap);
      setRaForModule(updatedRemedialAction);
    }
  }, [raByModuleIdList]);

  useEffect(() => {
    const publishedCourse = [];
    if (courseList) {
      courseList?.course_list?.forEach(item => {
        publishedCourse.push({
          name: item?.id?.toString(),
          label: item?.course_name,
          value: item?.course_name,
        });
      });
    }
    setCourseListData(publishedCourse);
  }, [courseList]);

  const onAddRemedialAction = (type, value) => {
    const updatedPayload = { ...raForModule };

    const currentMediaUrls =
      updatedPayload?.media_url?.length > 0 ? updatedPayload?.media_url : [];

    if (type === "file" && value) {
      currentMediaUrls?.push(value);
    }

    let courses = [];
    if (type === "course") {
      courses = value?.map(Number);
    } else {
      preSelected?.forEach(item => {
        if (item?.name) courses.push(parseInt(item?.name));
      });
    }
    const updatedRemedialAction = {
      course: courses,
      media_url: currentMediaUrls,
      note: updatedPayload?.note,
    };
    setRaForModule(updatedRemedialAction);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["course_type"]) {
      await dispatch(
        getCourseList({
          status: EXPERTISE_LEVEL_STATUS,
          course_type: values["course_type"] || ALL_COURSE_FILTER_STATUS,
        })
      );
    }
  };

  const handleClear = async () => {
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedFile(null);
    }
    setIsModalOpen(!isModalOpen);
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file[0]);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const updateRemedialActionToDb = async () => {
    if (raForModule?.course?.length === 0) {
      showToast(MapCourseError, "error");
      return;
    }

    setFlushData(true);
    const courses = [];
    preSelected?.forEach(item => {
      if (item?.name) courses.push(parseInt(item?.name));
    });

    const updatedPayload = { ...raForModule };
    const mediaUrl = updatedPayload?.media_url || [];
    const filesToUpload = remedialFiles;
    const files = mediaUrl.filter(item => item[0] instanceof File);
    const nonFileUrls = mediaUrl.filter(item => !(item[0] instanceof File));

    filesToUpload.push(...files);
    let uploadedFilesUrls = [];
    if (filesToUpload.length > 0) {
      try {
        uploadedFilesUrls = await uploadFile(filesToUpload);
      } catch (error) {
        showToast(SomethingWentWrong, "error");
        return;
      }
    }

    const module_id = selectedModule;
    const updatedRemedialAction = {
      module_id: module_id,
      remedial_action: {
        course: courses,
        media_url: [...nonFileUrls, ...uploadedFilesUrls],
        note: note,
      },
    };
    await dispatch(updateRAForQB({ remedial_actions: updatedRemedialAction }));
    getRa();
    setRemedialFiles([]);
    setFlushData(false);
  };

  return (
    <div className="mb-5">
      <CommonModal
        sizeTitle={DeleteFileHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          const deleteFile = JSON.parse(JSON.stringify(raForModule));
          const mediaUrlCopy = [...(deleteFile?.media_url || [])];

          if (
            mediaUrlCopy &&
            selectedFile >= 0 &&
            selectedFile < mediaUrlCopy.length
          ) {
            mediaUrlCopy.splice(selectedFile, 1);
            deleteFile.media_url = mediaUrlCopy;
            setRaForModule(deleteFile);
          }

          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteMaterialFile}
      </CommonModal>
      <Card>
        <CardBody className="m-2 mt-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-2">
              <div className="choose-option">
                <H4 className="title">{RemedialActions}</H4>
              </div>
            </div>
          </div>
          <Row>
            <Col>
              <FormGroup className="mt-2">
                <LabelTooltip
                  label={MapCourse}
                  tooltipText={`${MapCourse}`}
                  important
                />
                <MultiSelectDropdown
                  onChange={selected => {
                    const selectedValues = [];
                    const preselect = [];
                    const preSelectMap = [];
                    preSelected?.forEach(item => {
                      if (selected.includes(item?.name)) {
                        preselect.push(item?.name);
                        preSelectMap.push(item);
                      }
                    });
                    courseListData?.forEach(item => {
                      if (
                        selected.includes(item?.name) &&
                        !preselect.includes(item?.name)
                      ) {
                        selectedValues.push(item);
                      }
                    });
                    setPreSelected([...preSelectMap, ...selectedValues]);
                    onAddRemedialAction("course", selected);
                  }}
                  options={courseListData}
                  placeholder={`${MapCourse}`}
                  defaultSelected={preSelected}
                />
              </FormGroup>
            </Col>
            <Col lg={1} className="mt-5">
              <FilterSliderModal
                dropdowns={[
                  {
                    label: CourseType,
                    key: "course_type",
                    tooltipText: `${Select} ${CourseType}`,
                    options: courseFilterDropdownList,
                    isMultiSelect: true,
                  },
                ]}
                selectedFilterValues={selectedFilterValues}
                defaultFilterValues={defaultFilterValues}
                setSelectedFilterValues={setSelectedFilterValues}
                setDefaultFilterValues={setDefaultFilterValues}
                onDone={handleDone}
                onClear={handleClear}
              />
            </Col>
          </Row>
          {raForModule?.media_url?.map((item, index) => {
            if (
              item &&
              typeof item === "string" &&
              item !== null &&
              item !== ""
            ) {
              return (
                <H3 key={index} className="mt-2">
                  <Badges className="pointer" color="light text-dark">
                    <div className="d-flex gap-2">
                      <MUIIcons size={24} iconName="InsertDriveFileOutlined" />
                      <FileHandler mediaUrl={item} />
                      <MUIIcons
                        iconName="DeleteOutlineOutlined"
                        className="primary-icon-color pointer"
                        size={24}
                        onClick={() => {
                          toggleModal();
                          setSelectedFile(index);
                        }}
                      />
                    </div>
                  </Badges>
                </H3>
              );
            }
          })}
          <LabelTooltip
            label={ClickHereToAddReferenceFile}
            tooltipText={ClickHereToAddReferenceFile}
            important={false}
          />
          <div className="text-danger">{PdfFileSizeNote}</div>
          <MultiFileUpload
            accept=".pdf"
            flushData={flushData}
            onFileChange={(file, status) => {
              if (status === "removed") {
                setRemedialFiles(prevDocuments =>
                  prevDocuments.filter(item => item[0].name !== file.name)
                );
              } else if (status === "done" && file) {
                setRemedialFiles(prevDocuments => [
                  ...(Array.isArray(prevDocuments) ? prevDocuments : []),
                  [file],
                ]);
              }
            }}
          />
          <FormGroup className="mt-2">
            <LabelTooltip
              label={OtherRemedialAction}
              tooltipText={`${Enter} ${OtherRemedialAction}`}
              important={false}
            />
            <CKEditor
              onChange={(_event, editor) => {
                const data = editor.getData();
                setNote(data);
              }}
              config={{
                toolbar: {
                  items: richTextSupportedItems,
                },
              }}
              editor={ClassicEditor}
              data={note}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <div className="d-flex gap-3">
        <DefaultButton color="primary" onClick={updateRemedialActionToDb}>
          {Save}
        </DefaultButton>
      </div>
    </div>
  );
};

RemedialAction.propTypes = {
  questionBankPayloadData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(["draft", "published"]).isRequired,
    is_competency: PropTypes.bool.isRequired,
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        module_name: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(
          PropTypes.shape({
            question_text: PropTypes.string.isRequired,
            question_type: PropTypes.oneOf([
              "multiple_choice",
              "single_select",
              "true_false",
              "open_text",
              "match_the_following",
            ]).isRequired,
            media_url: PropTypes.string,
            options: PropTypes.arrayOf(
              PropTypes.shape({
                option_text: PropTypes.string.isRequired,
                is_correct: PropTypes.bool.isRequired,
              })
            ),
          })
        ),
        remedial_action: PropTypes.shape({
          course: PropTypes.arrayOf(PropTypes.number),
          media_url: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
  }).isRequired,
  selectedModule: PropTypes.number.isRequired,
};

export default RemedialAction;
