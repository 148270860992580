import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { Btn, MUIIcons, H3, P } from "../../../../../AbstractElements";
import {
  INSTRUCOR_ASSIGN_VALUE,
  PUBLISHED,
  TRAINER_STATUS,
} from "../../../../../Api/constants";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../../CommonElements/MultiSelectDropdown";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import { assignUserToExistingCourse } from "../../../../../ReduxToolkit/Reducers/AssignSlice";
import {
  getCourseContent,
  reviewCourse,
} from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import { getCurriculumList } from "../../../../../ReduxToolkit/Reducers/CurriculumSlice";
import { getProficiencyList } from "../../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddCurriculum,
  AddCurriculumButton,
  AddSecondaryTrainer,
  AllCurriculumRequired,
  AllExpertiseLevelRequired,
  BondDetails,
  BondRequired,
  BondRupees,
  BondRupeesRequired,
  BondYears,
  BondYearsRequired,
  CourseFee,
  Curriculum,
  CurriculumRequired,
  Dates,
  Enter,
  ExpertiseLevel,
  ExpertiseLevelRequired,
  FillAllTheFields,
  FutureDate,
  FutureDateRequired,
  PleaseProvideRequiredInformation,
  PrimaryTrainer,
  PublishCourseCatalog,
  PublishLater,
  PublishLaterCourseCatalog,
  PublishNow,
  SecondaryTrainer,
  Select,
  SelfEnrollment,
  SelfEnrollmentIsRequired,
  Update,
} from "../../../../../utils/Constant";
import {
  enrolmentOptions,
  formatDate,
  getCurrentDate,
  getTomorrowDate,
  proficiencyLevelKeyValue,
  scrollToTop,
  showToast,
} from "../../../../../utils/helper/helper";
import "./style.scss";

const TrainerBondDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showCurriculum, setShowCurriculum] = useState(false);
  const [editingData, setEditingData] = useState(false);
  const [curriculums, setCurriculums] = useState([{ id: "", value: "" }]);
  const { courseId } = location.state || "";
  const { courseContent } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);
  const { curriculumList } = useAppSelector(state => state.curriculum);
  const { proficiencyList } = useAppSelector(state => state.master);
  const [trainerListData, setTrainerListData] = useState(null);
  const [curriculumListData, setCurriculumListData] = useState(null);
  const [proficiencyListData, setProficiencyListData] = useState(null);
  const [selectedSecondaryTrainers, setSelectedSecondaryTrainers] = useState(
    []
  );
  const [formValues, setFormValues] = useState({
    primaryTrainer: "",
    secondaryTrainer: "",
    curriculum: [],
    courseFee: "",
    isBondRequired: false,
    bondRupees: "",
    bondYears: "",
    isPublishLater: false,
    futureDate: "",
    selfEnrollment: "",
    expertiseLevel: [],
  });
  const [errors, setErrors] = useState({
    primaryTrainer: "",
    secondaryTrainer: "",
    curriculum: "",
    courseFee: "",
    isBondRequired: "",
    bondRupees: "",
    bondYears: "",
    isPublishLater: "",
    futureDate: "",
    selfEnrollment: "",
    expertiseLevel: "",
  });
  useEffect(() => {
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    setEditingData(false);
    if (
      courseContent?.trainer_bond_detail &&
      courseContent?.course_assignees &&
      courseContent?.trainer_bond_detail?.published_at !== "None"
    ) {
      setCurriculums([]);
      let primary = "";
      let secondary = "";
      const secTrainers = [];

      const userMap = {};
      usersList?.users?.forEach(user => {
        userMap[user?.id?.toString()] = user?.user_name;
      });

      courseContent?.course_assignees?.forEach((item: any) => {
        if (
          item?.assignment_type === INSTRUCOR_ASSIGN_VALUE &&
          item?.user_priority === "primary"
        ) {
          primary = item?.user_id;
        }
        if (
          item?.assignment_type === INSTRUCOR_ASSIGN_VALUE &&
          item?.user_priority === "secondary"
        ) {
          secTrainers.push({
            name: item.user_id.toString(),
            value: userMap[item.user_id.toString()],
            label: userMap[item.user_id.toString()],
          });
          secondary = secondary + "," + item?.user_id;
        }
      });
      setSelectedSecondaryTrainers(secTrainers);
      const curriculums = [];
      const expertise = [];
      const curriculumMap = [];
      courseContent?.trainer_bond_detail?.curriculum?.forEach((item, index) => {
        curriculumMap.push({ id: index.toString(), value: "" });
        curriculums.push(item?.id);
        expertise.push(
          proficiencyLevelKeyValue.find(
            entry => entry.label === item?.expertise_level
          ).id
        );
      });
      setCurriculums(curriculumMap);
      if (curriculums.length > 0) {
        setShowCurriculum(true);
      }
      const bondData: any = {
        primaryTrainer: primary,
        secondaryTrainer: secondary,
        curriculum: curriculums || [],
        courseFee: courseContent?.trainer_bond_detail?.course_fee || "",
        isBondRequired:
          courseContent?.trainer_bond_detail?.bond_rupee &&
          courseContent?.trainer_bond_detail?.bond_year,
        bondRupees: courseContent?.trainer_bond_detail?.bond_rupee || "",
        bondYears: courseContent?.trainer_bond_detail?.bond_year || "",
        isPublishLater:
          courseContent?.trainer_bond_detail?.published_at !== ""
            ? true
            : false,
        futureDate:
          formatDate(courseContent?.trainer_bond_detail?.published_at) || "",
        selfEnrollment:
          courseContent?.trainer_bond_detail?.is_self_enrollment.toString() ===
          "False"
            ? "no"
            : "yes",
        expertiseLevel: expertise,
      };
      if (courseContent?.status === PUBLISHED) {
        bondData.isPublishLater = false;
      }
      setEditingData(true);
      setFormValues(bondData);
    }
  }, [courseContent, usersList]);

  useEffect(() => {
    dispatch(
      getCurriculumList({
        status: ["published"],
        curriculum_type: { specialCurriculum: false },
      })
    );
    dispatch(getProficiencyList());
    dispatch(getUsersList({ role: TRAINER_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    const curriculums = [];
    curriculums.push({ value: "", label: "Select" });
    curriculumList?.curriculum?.forEach(sites => {
      const userObj = { value: sites.id, label: sites.name };
      curriculums.push(userObj);
    });
    setCurriculumListData(curriculums);
  }, [curriculumList]);

  useEffect(() => {
    const levels = [];
    levels.push({ value: "", label: "Select" });
    proficiencyList?.proficiency?.forEach(sites => {
      const userObj = { value: sites.id, label: sites.level_name };
      levels.push(userObj);
    });
    setProficiencyListData(levels);
  }, [proficiencyList]);

  useEffect(() => {
    const trainers: { value: string; label: string }[] = [
      { label: "Select trainer", value: "" },
    ];
    usersList?.users?.forEach(user => {
      trainers.push({ value: user.id.toString(), label: user.user_name });
    });
    setTrainerListData(trainers);
  }, [usersList]);

  const getFilteredTrainerList = (excludeValue: string, type) => {
    if (type === "primary")
      return trainerListData?.filter(
        option => option.value === "" || option.value !== excludeValue
      );
    if (type === "secondary")
      return trainerListData?.filter(
        option =>
          option.value === "" ||
          !excludeValue?.split(",").includes(option.value)
      );
  };

  const addCurriculum = () => {
    const newId = curriculums.length.toString();
    setCurriculums([...curriculums, { id: newId, value: "" }]);
    setFormValues({
      ...formValues,
      curriculum: [...formValues.curriculum, ""],
    });
  };

  const removeCurriculum = (index: number) => {
    const updatedCurriculums = [...curriculums];
    updatedCurriculums.splice(index, 1);

    const updatedFormValuesCurriculum = [...formValues.curriculum];
    updatedFormValuesCurriculum.splice(index, 1);

    const updatedFormValuesExpertiseLevel = [...formValues.expertiseLevel];
    updatedFormValuesExpertiseLevel.splice(index, 1);

    setCurriculums(updatedCurriculums);
    setFormValues({
      ...formValues,
      curriculum: updatedFormValuesCurriculum,
      expertiseLevel: updatedFormValuesExpertiseLevel,
    });
  };

  const validateFormValues = values => {
    const newErrors = {
      primaryTrainer: "",
      secondaryTrainer: "",
      curriculum: "",
      courseFee: "",
      isBondRequired: "",
      bondRupees: "",
      bondYears: "",
      isPublishLater: "",
      futureDate: "",
      selfEnrollment: "",
      expertiseLevel: "",
    };
    let isValid = true;
    Object.keys(values).forEach(key => {
      if (
        !values[key] &&
        key !== "curriculum" &&
        key !== "secondaryTrainer" &&
        key !== "expertiseLevel" &&
        key !== "bondRupees" &&
        key !== "bondYears" &&
        key !== "futureDate" &&
        key !== "selfEnrollment" &&
        key !== "isBondRequired" &&
        key !== "isPublishLater"
      ) {
        newErrors[key] = `${key.split(/(?=[A-Z])/).join(" ")} is required.`;
        isValid = false;
      } else if (
        (key === "courseFee" || key === "bondRupees" || key === "bondYears") &&
        isNaN(Number(values[key]))
      ) {
        newErrors[key] =
          `${key.split(/(?=[A-Z])/).join(" ")} must be a number.`;
        isValid = false;
      }
    });
    const allNotEmptyCurriculum = values.curriculum.every(
      value => value !== ""
    );
    if (showCurriculum && values.curriculum.length === 0) {
      newErrors.curriculum = CurriculumRequired;
      isValid = false;
    }
    if (showCurriculum && !allNotEmptyCurriculum) {
      newErrors.curriculum = AllCurriculumRequired;
      isValid = false;
    }

    const allNotEmptyExperstise = values.expertiseLevel.every(
      value => value !== ""
    );
    if (showCurriculum && values.expertiseLevel.length === 0) {
      newErrors.expertiseLevel = ExpertiseLevelRequired;
      isValid = false;
    }
    if (showCurriculum && !allNotEmptyExperstise) {
      newErrors.expertiseLevel = AllExpertiseLevelRequired;
      isValid = false;
    }

    if (values.curriculum.length !== values.expertiseLevel.length) {
      newErrors.expertiseLevel = FillAllTheFields;
      isValid = false;
    }
    if (values.isBondRequired) {
      if (!values.bondRupees) {
        newErrors.bondRupees = BondRupeesRequired;
        isValid = false;
      }
      if (!values.bondYears) {
        newErrors.bondYears = BondYearsRequired;
        isValid = false;
      }
    }
    if (values.isPublishLater) {
      if (!values.futureDate) {
        newErrors.futureDate = FutureDateRequired;
        isValid = false;
      }
      if (!values.selfEnrollment) {
        newErrors.selfEnrollment = SelfEnrollmentIsRequired;
        isValid = false;
      }
    }
    setErrors(newErrors);
    if (!isValid) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
    }
    return isValid;
  };

  const handleFormSubmit = async () => {
    if (!validateFormValues(formValues)) {
      return;
    }
    const assignUserData = [];
    if (parseInt(formValues?.primaryTrainer)) {
      assignUserData.push({
        user_id: parseInt(formValues?.primaryTrainer) || null,
        user_priority: "primary",
        assignment_type: INSTRUCOR_ASSIGN_VALUE,
      });
    }
    formValues?.secondaryTrainer &&
      formValues?.secondaryTrainer
        ?.toString()
        ?.split(",")
        .map(item => {
          if (parseInt(item)) {
            assignUserData.push({
              user_id: parseInt(item) || null,
              user_priority: "secondary",
              assignment_type: INSTRUCOR_ASSIGN_VALUE,
            });
          }
        });

    const postObj: any = {
      course_id: courseId || null,
      instructor: assignUserData,
    };
    await dispatch(assignUserToExistingCourse(postObj));
    const formData = {
      course_id: courseId,
      status: "",
      reason: "",
      is_review: false,
      course_content: {
        is_self_enrollment:
          formValues?.isPublishLater &&
          formValues.selfEnrollment?.toString() === "yes"
            ? true
            : false,
        bond_amount: formValues.isBondRequired
          ? parseFloat(formValues.bondRupees)
          : 0,
        bond_year: formValues.isBondRequired
          ? parseInt(formValues.bondYears)
          : 0,
        course_publish_date:
          formValues.futureDate || formValues?.futureDate || getCurrentDate(),
        curriculum: showCurriculum
          ? curriculums
              ?.map((_curriculum, index) => ({
                id: parseInt(formValues.curriculum[index]) || "",
                name:
                  curriculumListData.find(
                    c => c.value.toString() === formValues.curriculum[index]
                  )?.label || "",
                expertise_level: formValues.expertiseLevel[index] || "",
              }))
              .filter(item => item.id !== "")
          : [],
        course_fee: parseFloat(formValues.courseFee) || 0,
        course_status: PUBLISHED,
        is_publish_now: !formValues?.isPublishLater,
      },
    };
    const wrapperObj = { courseContent: formData };
    await dispatch(reviewCourse(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details`,
      { state: { courseId: courseId } }
    );
  };

  const getButtonText = () => {
    if (editingData) {
      return Update;
    }
    return formValues?.isPublishLater ? PublishLater : PublishNow;
  };

  const getPublishInfo = () => {
    return formValues?.isPublishLater
      ? PublishLaterCourseCatalog
      : PublishCourseCatalog;
  };

  const getSecondaryTrainerList = () => {
    const allOptions = [];
    getFilteredTrainerList(
      formValues.primaryTrainer?.toString(),
      "primary"
    )?.map(option => {
      if (option?.value)
        allOptions.push({
          name: option?.value,
          label: option?.label,
          value: option?.label,
        });
    });
    return allOptions;
  };

  return (
    <div className="page-body">
      <Card className="mt-4">
        <CardHeader>
          <H3>{BondDetails}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="stepper-one g-3 needs-validation custom-input"
            noValidate
          >
            <Row>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={PrimaryTrainer}
                    tooltipText={`${Select} ${PrimaryTrainer}`}
                    important
                  />
                  <Input
                    name="primary_trainer"
                    type="select"
                    value={formValues.primaryTrainer}
                    onChange={e =>
                      setFormValues({
                        ...formValues,
                        primaryTrainer: e.target.value,
                      })
                    }
                    invalid={!!errors.primaryTrainer}
                    disabled={editingData}
                  >
                    {getFilteredTrainerList(
                      formValues.secondaryTrainer,
                      "secondary"
                    )?.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                  <div className="invalid-feedback">
                    {errors.primaryTrainer}
                  </div>
                </FormGroup>
              </Col>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={SecondaryTrainer}
                    tooltipText={`${Select} ${SecondaryTrainer}`}
                    important={false}
                  />

                  <MultiSelectDropdown
                    onChange={values => {
                      const trainerMap = [];
                      const trainerValues = [];
                      getSecondaryTrainerList()?.forEach((element: any) => {
                        if (values.includes(element.name)) {
                          trainerValues.push(element.name?.toString());
                          trainerMap.push({
                            name: element.name?.toString(),
                            value: element.label,
                            label: element.label,
                          });
                        }
                      });
                      setFormValues({
                        ...formValues,
                        secondaryTrainer: trainerValues?.toString(),
                      });
                      setSelectedSecondaryTrainers(trainerMap);
                    }}
                    disabled={editingData}
                    options={getSecondaryTrainerList()}
                    placeholder={AddSecondaryTrainer}
                    defaultSelected={selectedSecondaryTrainers}
                  />
                  <div className="invalid-feedback">
                    {errors.secondaryTrainer}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div className="mt-2">
              <div className="form-check text-dark">
                <Input
                  className=".border-primary"
                  id="flexCheckAddCurriculum"
                  type="checkbox"
                  checked={showCurriculum}
                  disabled={editingData}
                  onChange={() => {
                    setFormValues({
                      ...formValues,
                      curriculum: [],
                      expertiseLevel: [],
                    });
                    setShowCurriculum(!showCurriculum);
                  }}
                />
                <Label
                  className="text-dark"
                  htmlFor="flexCheckAddCurriculum"
                  check
                >
                  {AddCurriculum}
                </Label>
              </div>
            </div>
            {showCurriculum && (
              <div>
                {!editingData && (
                  <div className="d-flex justify-content-end">
                    <div
                      className="d-flex sm primary-text-color gap-2 pointer"
                      onClick={addCurriculum}
                    >
                      <div>{AddCurriculumButton}</div>
                      <div className="mt-1">
                        <MUIIcons
                          iconName="AddCircleOutlineOutlined"
                          size={16}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {curriculums?.map((curriculum, index) => {
                  return (
                    <Card key={curriculum.id} className="mt-3">
                      <CardBody>
                        <Row className="mt-1">
                          <Col sm="12" lg="6">
                            <FormGroup>
                              <LabelTooltip
                                label={Curriculum}
                                tooltipText={`${Select} ${Curriculum}`}
                                important
                              />
                              <Input
                                name={`curriculum-${index}`}
                                type="select"
                                disabled={editingData}
                                value={formValues.curriculum[index]}
                                onChange={e => {
                                  const updatedCurriculum = [
                                    ...formValues.curriculum,
                                  ];
                                  updatedCurriculum[index] = e.target.value;
                                  setFormValues({
                                    ...formValues,
                                    curriculum: updatedCurriculum,
                                  });
                                }}
                              >
                                {curriculumListData?.map(option => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="12" lg="6">
                            <FormGroup>
                              <LabelTooltip
                                label={ExpertiseLevel}
                                tooltipText={`${Select} ${ExpertiseLevel}`}
                                important
                              />
                              <Input
                                name={`expertise_level-${index}`}
                                type="select"
                                disabled={editingData}
                                value={formValues.expertiseLevel[index]}
                                onChange={e => {
                                  const updatedExpertiseLevel = [
                                    ...formValues.expertiseLevel,
                                  ];
                                  updatedExpertiseLevel[index] = e.target.value;
                                  setFormValues({
                                    ...formValues,
                                    expertiseLevel: updatedExpertiseLevel,
                                  });
                                }}
                              >
                                {proficiencyListData?.map(option => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        {index !== 0 && !editingData && (
                          <div
                            className="d-flex justify-content-end sm primary-text-color gap-2 pointer delete-curriculum-button"
                            onClick={() => removeCurriculum(index)}
                          >
                            <div className="mt-1">
                              <MUIIcons
                                iconName="DeleteOutlineOutlined"
                                size={18}
                              />
                            </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  );
                })}
                <div className="text-danger mb-3">
                  {errors.curriculum || errors.expertiseLevel}
                </div>
              </div>
            )}
            <Row>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={CourseFee}
                    tooltipText={`${Enter} ${CourseFee}`}
                    important
                  />
                  <Input
                    name="course_fee"
                    type="text"
                    placeholder={`${Enter} ${CourseFee}`}
                    value={formValues.courseFee}
                    maxLength={6}
                    disabled={editingData}
                    onChange={e => {
                      if (e.target.value === "") {
                        setFormValues({
                          ...formValues,
                          courseFee: "",
                        });
                      }

                      if (
                        /^[0-9+]{1,6}$/.test(e.target.value) &&
                        parseInt(e.target.value) > 0
                      ) {
                        setFormValues({
                          ...formValues,
                          courseFee: e.target.value,
                        });
                      }
                    }}
                    invalid={!!errors.courseFee}
                  />
                  <div className="invalid-feedback">{errors.courseFee}</div>
                </FormGroup>
              </Col>
            </Row>
            <div className="mt-4">
              <div className="form-check text-dark">
                <Input
                  className=".border-primary"
                  id="flexCheckBondRequired"
                  type="checkbox"
                  disabled={editingData}
                  checked={formValues.isBondRequired}
                  onChange={() => {
                    setFormValues({
                      ...formValues,
                      bondRupees: "",
                      bondYears: "",
                      isBondRequired: !formValues.isBondRequired,
                    });
                  }}
                />
                <Label
                  className="text-dark"
                  htmlFor="flexCheckBondRequired"
                  check
                >
                  {BondRequired}
                </Label>
              </div>
            </div>
            {formValues.isBondRequired && (
              <Row className="mt-1">
                <Col sm="12" lg="6">
                  <FormGroup>
                    <LabelTooltip
                      label={BondRupees}
                      tooltipText={`${Enter} ${BondRupees}`}
                      important
                    />
                    <Input
                      name="bond_rupees"
                      type="text"
                      disabled={editingData}
                      placeholder={`${Enter} ${BondRupees}`}
                      value={formValues.bondRupees}
                      onChange={e => {
                        if (e.target.value === "") {
                          setFormValues({
                            ...formValues,
                            bondRupees: "",
                          });
                        }

                        if (
                          /^[0-9+]{1,6}$/.test(e.target.value) &&
                          parseInt(e.target.value) > 0
                        ) {
                          setFormValues({
                            ...formValues,
                            bondRupees: e.target.value,
                          });
                        }
                      }}
                      invalid={!!errors.bondRupees}
                    />
                    <div className="invalid-feedback">{errors.bondRupees}</div>
                  </FormGroup>
                </Col>
                <Col sm="12" lg="6">
                  <FormGroup>
                    <LabelTooltip
                      label={BondYears}
                      tooltipText={`${Enter} ${BondYears}`}
                      important
                    />
                    <Input
                      name="bond_years"
                      type="text"
                      disabled={editingData}
                      placeholder={`${Enter} ${BondYears}`}
                      value={formValues.bondYears}
                      onChange={e => {
                        if (e.target.value === "") {
                          setFormValues({
                            ...formValues,
                            bondYears: "",
                          });
                        }

                        if (
                          /^[0-9+]{1,2}$/.test(e.target.value) &&
                          parseInt(e.target.value) > 0 &&
                          parseInt(e.target.value) <= 10
                        ) {
                          setFormValues({
                            ...formValues,
                            bondYears: e.target.value,
                          });
                        }
                      }}
                      invalid={!!errors.bondYears}
                    />
                    <div className="invalid-feedback">{errors.bondYears}</div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {courseContent?.status !== PUBLISHED && (
              <div className="mt-4">
                <div className="form-check text-dark">
                  <Input
                    className=".border-primary"
                    id="flexCheckPublishLater"
                    type="checkbox"
                    disabled={editingData}
                    checked={formValues.isPublishLater}
                    onChange={() =>
                      setFormValues({
                        ...formValues,
                        futureDate: "",
                        selfEnrollment: "",
                        isPublishLater: !formValues.isPublishLater,
                      })
                    }
                  />
                  <Label
                    className="text-dark"
                    htmlFor="flexCheckPublishLater"
                    check
                  >
                    {PublishLater}
                  </Label>
                </div>
              </div>
            )}
            {formValues.isPublishLater &&
              courseContent?.status !== PUBLISHED && (
                <Row className="mt-1">
                  <Col sm="12" lg="6">
                    <FormGroup>
                      <LabelTooltip
                        label={FutureDate}
                        tooltipText={`${Enter} ${Dates}`}
                        important
                      />
                      <Input
                        disabled={
                          courseContent?.status === PUBLISHED || editingData
                        }
                        name="future_date"
                        type="date"
                        placeholder={`${Enter} ${Dates}`}
                        value={formValues.futureDate}
                        min={getTomorrowDate()}
                        onChange={e =>
                          setFormValues({
                            ...formValues,
                            futureDate: e.target.value,
                          })
                        }
                        invalid={!!errors.futureDate}
                      />
                      <div className="invalid-feedback">
                        {errors.futureDate}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="6">
                    <FormGroup>
                      <LabelTooltip
                        label={SelfEnrollment}
                        tooltipText={`${Select} ${SelfEnrollment}`}
                        important
                      />
                      <Input
                        disabled={
                          courseContent?.status === PUBLISHED || editingData
                        }
                        name="self-enrollment"
                        type="select"
                        value={formValues.selfEnrollment}
                        onChange={e =>
                          setFormValues({
                            ...formValues,
                            selfEnrollment: e.target.value,
                          })
                        }
                        invalid={!!errors.selfEnrollment}
                      >
                        {enrolmentOptions?.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Input>
                      <div className="invalid-feedback">
                        {errors.selfEnrollment}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
          </Form>
        </CardBody>
        {!editingData && (
          <CardFooter className="d-flex justify-content-end">
            <Row>
              <Col xs="auto" className="d-flex gap-2">
                <P className="mt-2 text-muted">{getPublishInfo()}</P>
              </Col>
              <Col xs="auto">
                <Btn onClick={handleFormSubmit} color="primary">
                  {getButtonText()}
                </Btn>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};
export default TrainerBondDetails;
