import PropTypes from "prop-types";
import React from "react";

import { H4 } from "../../AbstractElements";
import QuestionNumberChooseButton from "../../CommonElements/QuestionNumberChoose";

const colorTypeMap: { [key: number]: string } = {
  1: "bg-light text-dark outline-lightblue",
  2: "bg-primary",
  3: "bg-dark",
};

interface QuestionNavigatorProps {
  moduleName: string;
  questions: {
    number: number;
    type: number;
  }[];
  handleClick?: (event: any, index?: number) => void;
  onCorrectResponse?: () => void;
}

const QuestionNavigator: React.FC<QuestionNavigatorProps> = ({
  moduleName,
  questions,
  handleClick,
  onCorrectResponse,
}) => {
  return (
    <div className="generic-module-card p-3 mt-3">
      <H4>{moduleName}</H4>

      <div className="d-flex gap-3 mt-3 flex-wrap">
        {questions?.map((question, index) => (
          <div
            onClick={() => handleClick(question, index)}
            className="pointer"
            key={index}
          >
            <QuestionNumberChooseButton
              number={question.number}
              colorName={colorTypeMap[question.type]}
              onCorrectResponse={onCorrectResponse}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

QuestionNavigator.propTypes = {
  moduleName: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  onCorrectResponse: PropTypes.func,
};

QuestionNavigator.propTypes = {
  moduleName: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  onCorrectResponse: PropTypes.func,
};

export default QuestionNavigator;
