import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { courseTypeMap, sourceTypeMap } from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import LearningPlanCourseCard, {
  LearningPlanCardData,
} from "../../../container/LearningPlanCourseCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getTrainingHistory } from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  CourseType,
  ILT,
  Select,
  VILT,
  WBT,
  Webinar,
} from "../../../utils/Constant";
import { courseFilterDropdownList } from "../../../utils/helper/helper";

const ILPTrainingHistory = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { trainingHistory } = useAppSelector(state => state.learningPlan);

  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<LearningPlanCardData[]>(
    []
  );
  const [noDataFound, setNoDataFound] = useState(false);

  useEffect(() => {
    (async () => {
      await dispatch(getTrainingHistory({ userId }));
    })();
  }, [dispatch, userId]);

  useEffect(() => {
    fetchCourses();
  }, [selectedItems, trainingHistory]);

  const mapTrainingHistoryToLearningPlanCardData = (
    trainingHistoryData: any
  ): LearningPlanCardData[] => {
    return trainingHistoryData.history.map(course => {
      const domainName =
        trainingHistoryData.domain[course.id]?.map(
          domainItem => domainItem.name
        ) || [];
      return {
        courseId: course.id.toString(),
        domainName: domainName,
        status: course.status,
        badgeTextColor: true,
        courseName: course.course_name,
        completedOn: course.created_at,
        assignedBy: sourceTypeMap[course.source_type],
        courseType: courseTypeMap[course.course_type],
      };
    });
  };

  const filterCourses = (courses, selectedItems) => {
    if (selectedItems.length === 0) {
      return courses;
    }
    return courses.filter(item =>
      selectedItems.includes(item.courseType.toUpperCase())
    );
  };

  const fetchCourses = () => {
    if (!trainingHistory || !trainingHistory.history) {
      setSearchResults([]);
      setNoDataFound(true);
      return;
    }

    const transformedCourses =
      mapTrainingHistoryToLearningPlanCardData(trainingHistory);
    const filteredCourses = filterCourses(transformedCourses, selectedItems);

    setSearchResults(filteredCourses);
    setNoDataFound(filteredCourses.length === 0);
  };

  const handleSearch = (text: string) => {
    const transformedCourses =
      mapTrainingHistoryToLearningPlanCardData(trainingHistory);

    const filteredCourses = transformedCourses.filter(course =>
      course.courseName.toLowerCase().includes(text.toLowerCase())
    );
    setSearchResults(filteredCourses);
    setNoDataFound(filteredCourses.length === 0);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["course_type"]) {
      setSelectedItems(values["course_type"].map(item => item.toUpperCase()));
    }

    fetchCourses();
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
    setSearchResults(mapTrainingHistoryToLearningPlanCardData(trainingHistory));
    setNoDataFound(false);
  };

  const renderCourses = data => {
    return data && data.length > 0 ? (
      <LearningPlanCourseCard data={data} />
    ) : (
      <NoDataFound />
    );
  };

  return (
    <>
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="7" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {ILT} :
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.ilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {VILT} :
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.vilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Webinar}:
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.webinar}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {WBT}:
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.wbt}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end "
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: CourseType,
                        key: "course_type",
                        tooltipText: `${Select} ${CourseType}`,
                        options: courseFilterDropdownList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
                <YearNavigator pastYears={20} futureYears={20} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {noDataFound ? <NoDataFound /> : renderCourses(searchResults)}
    </>
  );
};
ILPTrainingHistory.propTypes = {
  userId: PropTypes.number,
};

export default ILPTrainingHistory;
