import PropTypes from "prop-types";

import ProfileName from "./ProfileName";
import ProfileUserDetails from "./ProfileUserDetails";

import "./style.scss";

const ProfileDetail = ({ profileDetailData }) => {
  return (
    <div className="profile-container">
      <ProfileName
        name={profileDetailData.name}
        designation={profileDetailData.designation}
      />
      <ProfileUserDetails data={profileDetailData.userDetails} />
    </div>
  );
};

ProfileDetail.propTypes = {
  profileDetailData: PropTypes.exact({
    userProfileUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    userDetails: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        detail: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ProfileDetail;
