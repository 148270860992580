import {
  REPORTING_MANAGER,
  SME,
  TRAINER,
  LEARNER,
  TRAINING_MANAGER,
} from "../../Api/constants";

interface UsersData {
  id: number;
  name: string;
  role: string;
  email: string;
  password: string;
  accessToken: string;
}

export const users: UsersData[] = [
  {
    id: 1,
    name: "Prakash Raj",
    role: REPORTING_MANAGER,
    email: "prakash@divum.in",
    password: "qwerty@123",
    accessToken: "1",
  },
  {
    id: 2,
    name: "Ramesh",
    role: TRAINING_MANAGER,
    email: "ramesh@divum.in",
    password: "qwerty@123",
    accessToken: "2",
  },
  {
    id: 3,
    name: "Arun Karthik",
    role: SME,
    email: "arun@divum.in",
    password: "qwerty@123",
    accessToken: "3",
  },
  {
    id: 4,
    name: "Bhuvanesh",
    role: TRAINER,
    email: "bhuvanesh@divum.in",
    password: "qwerty@123",
    accessToken: "4",
  },
  {
    id: 5,
    name: "Murali Krishnan",
    role: TRAINER,
    email: "muralikrishnan@divum.in",
    password: "qwerty@123",
    accessToken: "5",
  },
  {
    id: 7,
    name: "Tilak",
    role: TRAINER,
    email: "hegde.tilak@divum.in",
    password: "qwerty@123",
    accessToken: "7",
  },
  {
    id: 6,
    name: "Swetha",
    role: SME,
    email: "swethababu@divum.in",
    password: "qwerty@123",
    accessToken: "6",
  },
  {
    id: 8,
    name: "Sabanayagam R",
    role: REPORTING_MANAGER,
    email: "sabanayagam.r@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "8",
  },
  {
    id: 10,
    name: "Sabanayagam 20792",
    role: TRAINING_MANAGER,
    email: "sabanayagam20792@gmail.com",
    password: "qwerty@123",
    accessToken: "10",
  },
  {
    id: 12,
    name: "Sabanayagamram 92",
    role: SME,
    email: "sabanayagamram92@gmail.com",
    password: "qwerty@123",
    accessToken: "12",
  },
  {
    id: 15,
    name: "Saba 4950",
    role: TRAINER,
    email: "saba4950@gmail.com",
    password: "qwerty@123",
    accessToken: "15",
  },
  {
    id: 9,
    name: "Ganesh S",
    role: REPORTING_MANAGER,
    email: "sganesh@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "9",
  },
  {
    id: 11,
    name: "Rajaganapathy A",
    role: TRAINING_MANAGER,
    email: "Rajaganapathy.a@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "11",
  },
  {
    id: 13,
    name: "Arvind V",
    role: SME,
    email: "varvind@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "13",
  },
  {
    id: 14,
    name: "Baskar M",
    role: SME,
    email: "mbaskar@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "14",
  },
  {
    id: 16,
    name: "Dhanasekar P",
    role: TRAINER,
    email: "pdhanasekar@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "16",
  },
  {
    id: 17,
    name: "Guhan K",
    role: TRAINER,
    email: "guhan.k@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "17",
  },
  {
    id: 18,
    name: "Dharmalingam K",
    role: TRAINER,
    email: "dharmalingam.k@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "18",
  },
  {
    id: 15,
    name: "Vignesh",
    role: LEARNER,
    email: "vignesh@divum.in",
    password: "qwerty@123",
    accessToken: "15",
  },
  {
    id: 16,
    name: "Naveen",
    role: LEARNER,
    email: "naveen@divum.in",
    password: "qwerty@123",
    accessToken: "16",
  },
  {
    id: 17,
    name: "Gopi",
    role: LEARNER,
    email: "gopi@divum.in",
    password: "qwerty@123",
    accessToken: "17",
  },
  {
    id: 18,
    name: "Gokul",
    role: LEARNER,
    email: "gokul@divum.in",
    password: "qwerty@123",
    accessToken: "18",
  },
  {
    id: 19,
    name: "Ajay",
    role: LEARNER,
    email: "ajay@divum.in",
    password: "qwerty@123",
    accessToken: "19",
  },
  {
    id: 20,
    name: "Divakar",
    role: LEARNER,
    email: "divakar@divum.in",
    password: "qwerty@123",
    accessToken: "20",
  },
  {
    id: 21,
    name: "Nickson",
    role: LEARNER,
    email: "nickson@divum.in",
    password: "qwerty@123",
    accessToken: "21",
  },
  {
    id: 25,
    name: "V Ravindran",
    role: LEARNER,
    email: "vravind@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "25",
  },
  {
    id: 26,
    name: "Tisha Thomas",
    role: LEARNER,
    email: "tishathomas@gmmcoindia.com",
    password: "qwerty@123",
    accessToken: "26",
  },
];
