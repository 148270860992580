import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
  Tooltip,
} from "reactstrap";

import {
  Badges,
  Btn,
  MUIIcons,
  H3,
  H4,
  H5,
  P,
  H2,
} from "../../../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../../../Api/constants";
import Divider from "../../../../../../CommonElements/Divider";
import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../../CommonElements/Modal";
import MultiFileUpload from "../../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import MultiSelectDropdown from "../../../../../../CommonElements/MultiSelectDropdown";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import AssessmentTable from "../../../../../../container/AssessmentTable/AssessmentTable";
import Comments from "../../../../../../container/Comments";
import Module from "../../../../../../container/Module";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  createAssessment,
  deleteAssessment,
  getAssessment,
  updateAssessment,
} from "../../../../../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  addCommentToStages,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import { updateLearningOutcome } from "../../../../../../ReduxToolkit/Reducers/CourseStagesSlice";
import {
  getModuleList,
  getPolicyList,
  getQuestionBankList,
} from "../../../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  getUsersList,
  uploadFileApi,
} from "../../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddAnyOfAssessments,
  AddDocument,
  AddLearnerDocument,
  AddNew,
  AddTrainerDocument,
  AddValidNumberInRange250,
  AddValidNumberInRange500,
  AssessmentDurationInMinutes,
  AssessmentName,
  AssessmentType,
  AssessmentTypeChange,
  Comment,
  Confirm,
  CreateAssessments,
  DeleteAssessment,
  DeleteAssessmentHeader,
  Document,
  Done,
  EditAssessment,
  Enter,
  EnterAssessmentName,
  EnterComments,
  EnterEstimatedTime,
  EnterOverallCount,
  FileSizeNote,
  Knowledge,
  KnowledgeAssessmentType,
  LearnerDocument,
  LearningOutcomes,
  MaximumCharacters,
  OverallCount,
  PleaseAddAssessmentName,
  PleaseFillWeightage,
  PleaseProvideRequiredInformation,
  PleaseSelectPolicy,
  PleaseSelectQuestionBank,
  PleaseSelectSkillType,
  Policy,
  QuestionBank,
  ReAttemptPolicy,
  Save,
  SaveAsDraft,
  Select,
  SelectAssessmentType,
  SelectLO,
  SelectPolicy,
  SelectQuestionBank,
  Skill,
  SkillType,
  SkillTypeError,
  SkillTypeHeader,
  TrainerDocument,
  Upload,
} from "../../../../../../utils/Constant";
import {
  assessmentTypesOptions,
  handleAllFilesDownload,
  knowledgeHeader,
  knowledgeOptions,
  sanitizeString,
  showToast,
  skillHeader,
  skillOptions,
  sortCommentsByDate,
} from "../../../../../../utils/helper/helper";
import useIsMobile from "../../../../../../utils/helper/responsive";

const CreateAssessment: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isComentRead, setIsComentRead] = useState(null);
  const { courseId } = location.state || "";
  const [sliderModal, setSliderModal] = useState(false);
  const [iconComments, setIconComments] = useState(null);
  const [modalContent, setModalContent] = useState<
    "comments" | "CreateAssessment" | "EditAssessment"
  >("comments");
  const [userMap, setUserMap] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const { questionBankList, policyList, moduleList } = useAppSelector(
    state => state.master
  );
  const [isSkillAssessmentavailable, setIsSkillAssessmentAvailable] =
    useState(false);
  const [learnerDocument, setLearnerDocument] = useState([]);
  const [trainerDocument, setTrainerDocument] = useState([]);
  const [ojtDocument, setOjtDocument] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const { courseContent } = useAppSelector(state => state.course);
  const { courseAssessments } = useAppSelector(state => state.assessment);
  const [selectedLOs, setSelectedLOs] = useState([]);
  const { usersList } = useAppSelector(state => state.user);
  const [questionListData, setQuestionListData] = useState(null);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [learningOutcomesMap, setLearningOutcomesMap] = useState([]);
  const [knowledgeAssessments, setKnowledgeAssessments] = useState([]);
  const [skillAssessments, setSkillAssessments] = useState([]);
  const [policyListData, setPolicyListData] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [selectedAssessmentType, setSelectedAssessmentType] = useState(null);
  const [formData, setFormData] = useState<any>({
    assessment_subtype: "knowledge",
    question_bank_id: "",
    modules: [],
    name: "",
    type: "field",
    overall_question_count: "",
    estimated_time: "",
    policy_id: "",
    skill_document: {},
  });
  const [errors, setErrors] = useState({
    assessment_subtype: "",
    question_bank_id: "",
    modules: "",
    name: "",
    type: "",
    overall_question_count: "",
    estimated_time: "",
    policy_id: "",
    skill_document: "",
    lo: "",
  });

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    setSelectedAssessment(null);
    setSelectedAssessmentType(null);
    dispatch(getCourseContent({ id: courseId }));
    dispatch(getQuestionBankList());
    dispatch(getPolicyList());
  }, [dispatch, courseId]);

  useEffect(() => {
    dispatch(getAssessment({ id: courseId }));
  }, [dispatch, courseId]);

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    let comment = [];
    const outcomes = [];
    const outcomesMap = [];
    if (courseContent?.comment?.assessment) {
      comment = sortCommentsByDate(courseContent.comment.assessment);
    }

    if (courseContent && courseContent?.learning_outcome) {
      courseContent?.learning_outcome.forEach((out: any) => {
        outcomes.push({
          name: out.id.toString(),
          value: out.outcome,
          label: out.outcome,
        });
        outcomesMap.push({
          name: out.id.toString(),
          value: out.outcome,
          label: out.outcome,
          competency_id: out?.competency_id,
          domain_id: out?.domain_id,
          function: out?.function,
          proficiency_level_id: out?.proficiency_level_id,
        });
      });
    }
    setLearningOutcomesMap(outcomesMap);
    setLearningOutcomes(outcomes);
    setCommentList(comment);
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "assessment"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
  }, [courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const knowledgeAssessments = [];
    const skillAssessments = [];
    const loMap = {};

    courseContent?.assessment_learning_outcome?.forEach((items: any) => {
      const assessmentId = items.assessment_id;
      if (loMap[assessmentId]) {
        loMap[assessmentId].push(items?.outcome);
      } else {
        loMap[assessmentId] = [items?.outcome];
      }
    });

    if (
      courseAssessments?.assessments?.knowledge &&
      courseContent?.assessment_learning_outcome
    ) {
      courseAssessments?.assessments?.knowledge?.forEach((item: any) => {
        knowledgeAssessments.push({
          course_assessment_id: item?.course_assessment_id,
          skillName: item?.name,
          lo: loMap[item?.course_assessment_id] || [],
          type: item?.type,
          questions: item?.overall_question_count,
          modules: item?.modules?.length,
          assessmentDuration: item?.estimated_time,
          actions: "",
        });
      });
    }

    if (
      courseAssessments?.assessments?.skill &&
      courseContent?.assessment_learning_outcome
    ) {
      setIsSkillAssessmentAvailable(true);
      courseAssessments?.assessments?.skill?.forEach((item: any) => {
        skillAssessments.push({
          course_assessment_id: item?.course_assessment_id,
          skillName: item?.name,
          lo: loMap[item?.course_assessment_id] || [],
          type: item?.type,
          learnerDocs:
            item?.skill_document?.ojt_skill?.length > 0
              ? item?.skill_document?.ojt_skill || []
              : item?.skill_document?.field_skill?.learner_url || [],
          trainerDocs:
            item?.skill_document?.ojt_skill?.length > 0
              ? []
              : item?.skill_document?.field_skill?.trainer_url || [],
          actions: "",
        });
      });
    }
    setSkillAssessments(skillAssessments);
    setKnowledgeAssessments(knowledgeAssessments);
  }, [courseAssessments, courseContent]);

  useEffect(() => {
    if (questionBankList) {
      const questions = [];
      questions.push({ label: "Select", value: "" });
      questionBankList?.question_banks?.forEach(element => {
        questions.push({ value: element.id, label: element.name });
      });
      setQuestionListData(questions);
    }
  }, [questionBankList]);

  useEffect(() => {
    if (moduleList) {
      const modules = [];
      moduleList?.modules?.forEach(element => {
        modules.push({ value: element.id, label: element.module_name });
      });
      setFormData({ ...formData, modules });
    }
  }, [moduleList]);

  useEffect(() => {
    if (policyList) {
      const policies = [];
      policies.push({ label: "Select", value: "" });
      policyList?.policies?.forEach(element => {
        policies.push({ value: element.id, label: element.name });
      });
      setPolicyListData(policies);
    }
  }, [policyList]);

  const sliderToggle = async () => {
    if (sliderModal) {
      setSelectedAssessment(null);
      setSelectedAssessmentType(null);
      setDefaultData();
      setModalContent("comments");
    }
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "assessment",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const setDefaultData = () => {
    setLearnerDocument([]);
    setTrainerDocument([]);
    setOjtDocument([]);
    setSelectedLOs([]);
    setFormData({
      assessment_subtype: "knowledge",
      question_bank_id: "",
      modules: [],
      name: "",
      type: "field",
      overall_question_count: "",
      estimated_time: "",
      policy_id: "",
      skill_document: {},
    });
    setErrors({
      assessment_subtype: "",
      question_bank_id: "",
      modules: "",
      name: "",
      type: "",
      overall_question_count: "",
      estimated_time: "",
      policy_id: "",
      skill_document: "",
      lo: "",
    });
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "assessment",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const handleWeightageChange = (moduleId: string, weightage: number) => {
    setFormData(prevFormData => {
      const updatedModules = prevFormData.modules.map((module: any) => {
        if (module.value === moduleId) {
          return { ...module, weightage };
        }
        return module;
      });

      if (
        !prevFormData.modules.find((module: any) => module.value === moduleId)
      ) {
        updatedModules.push({ module_id: moduleId, weightage });
      }

      return {
        ...prevFormData,
        modules: updatedModules,
      };
    });
  };

  const validateField = (name: string, value: any) => {
    let error = "";

    switch (name) {
      case "name":
        if (value === "") {
          error = PleaseAddAssessmentName;
        } else if (value.length > 100) {
          error = MaximumCharacters;
        }
        break;

      case "overall_question_count":
        if (
          !/^\d*$/.test(value) ||
          (value && (parseInt(value) < 1 || parseInt(value) > 250))
        ) {
          error = AddValidNumberInRange250;
        }
        break;

      case "estimated_time":
        if (
          !/^\d*$/.test(value) ||
          (value && (parseInt(value) < 1 || parseInt(value) > 500))
        ) {
          error = AddValidNumberInRange500;
        }
        break;

      case "question_bank_id":
        if (!value) {
          error = PleaseSelectQuestionBank;
        }
        break;

      case "policy_id":
        if (!value) {
          error = PleaseSelectPolicy;
        }
        break;

      case "skill_type":
        if (!value) {
          error = PleaseSelectSkillType;
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setErrors({
      assessment_subtype: "",
      question_bank_id: "",
      modules: "",
      name: "",
      type: "",
      overall_question_count: "",
      estimated_time: "",
      policy_id: "",
      skill_document: "",
      lo: "",
    });
    const { name, value } = e.target;

    const error = validateField(name, value);

    if ((name === "name" && value === "") || error === "") {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    let isError = false;
    const error = {
      assessment_subtype: "",
      question_bank_id: "",
      modules: "",
      name: "",
      type: "",
      overall_question_count: "",
      estimated_time: "",
      policy_id: "",
      skill_document: "",
      lo: "",
    };

    let requiredFields = [];
    if (formData?.assessment_subtype === "knowledge") {
      requiredFields = [
        {
          field: "name",
          value: formData?.name,
          message: EnterAssessmentName,
        },
        {
          field: "type",
          value: formData?.type,
          message: SelectAssessmentType,
        },
        {
          field: "question_bank_id",
          value: formData?.question_bank_id,
          message: SelectQuestionBank,
        },
        {
          field: "overall_question_count",
          value: formData?.overall_question_count,
          message: EnterOverallCount,
        },
        {
          field: "estimated_time",
          value: formData?.estimated_time,
          message: EnterEstimatedTime,
        },
        {
          field: "policy_id",
          value: formData?.policy_id,
          message: SelectPolicy,
        },
      ];
    } else {
      requiredFields = [
        {
          field: "name",
          value: formData?.name,
          message: EnterAssessmentName,
        },
        {
          field: "type",
          value: formData?.type,
          message: SelectAssessmentType,
        },
        {
          field: "skill_document",
          value: formData?.skill_document,
          message: AddTrainerDocument,
        },
      ];
    }

    requiredFields.forEach(field => {
      if (!field.value || field.value === null) {
        isError = true;
        error[field.field] = field.message;
      }
      if (selectedLOs.length === 0) {
        isError = true;
        error["lo"] = SelectLO;
      }
    });

    if (
      formData?.assessment_subtype === "knowledge" &&
      formData?.modules.some((item: any) => !item.value || !item.weightage)
    ) {
      showToast(PleaseFillWeightage, "error");
      return;
    }
    if (
      formData?.assessment_subtype === "knowledge" &&
      formData?.type === "field"
    ) {
      isError = true;
      error["type"] = SelectAssessmentType;
    }

    if (isError) {
      showToast(PleaseProvideRequiredInformation, "error");
      setErrors(error);
      return;
    }

    if (formData?.assessment_subtype === "skill") {
      if (formData?.type === null) {
        showToast(SkillTypeError, "error");
        return;
      }
      if (
        formData?.type !== "ojt" &&
        learnerDocument.length === 0 &&
        (!formData?.skill_document?.field_skill?.learner_url ||
          formData?.skill_document?.field_skill?.learner_url?.length === 0)
      ) {
        showToast(AddLearnerDocument, "error");
        return;
      }
      if (
        formData?.type !== "ojt" &&
        trainerDocument.length === 0 &&
        (!formData?.skill_document?.field_skill?.trainer_url ||
          formData?.skill_document?.field_skill?.trainer_url?.length === 0)
      ) {
        showToast(AddTrainerDocument, "error");
        return;
      }
      if (
        formData?.type === "ojt" &&
        ojtDocument.length === 0 &&
        (!formData?.skill_document?.ojt_skill ||
          formData?.skill_document?.ojt_skill?.length === 0)
      ) {
        showToast(AddDocument, "error");
        return;
      }
      let allfiles = [];
      if (learnerDocument && learnerDocument.length) {
        allfiles = [...allfiles, ...learnerDocument];
      }
      if (trainerDocument && trainerDocument.length) {
        allfiles = [...allfiles, ...trainerDocument];
      }
      if (ojtDocument && ojtDocument.length) {
        allfiles = [...allfiles, ...ojtDocument];
      }

      let files = [];
      if (allfiles?.length > 0) files = await uploadFile(allfiles);
      const trainer = [];
      const learner = [];
      const ojt = [];
      if (files && files.length && formData?.type === "ojt") {
        files.forEach(fileUrl => {
          const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          const fileExtension = fileName.substring(
            fileName.lastIndexOf(".") + 1
          );
          ojt.push({
            file_name: fileName,
            file_type: fileExtension,
            file_url: fileUrl,
            file_category: "learner_document",
          });
        });
      }
      if (files && files.length && formData?.type !== "ojt") {
        files.forEach((fileUrl, index) => {
          if (index < learnerDocument.length) {
            const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
            const fileExtension = fileName.substring(
              fileName.lastIndexOf(".") + 1
            );
            learner.push({
              file_name: fileName,
              file_type: fileExtension,
              file_url: fileUrl,
              file_category: "learner_document",
            });
          }
          if (index >= learnerDocument.length && index < files.length) {
            const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
            const fileExtension = fileName.substring(
              fileName.lastIndexOf(".") + 1
            );
            trainer.push({
              file_name: fileName,
              file_type: fileExtension,
              file_url: fileUrl,
              file_category: "trainer_document",
            });
          }
        });
      }

      if (formData?.type === "ojt") {
        const defaultJsonDocument = {
          ojt_skill: ojt,
        };
        if (isSkillAssessmentavailable && formData?.skill_document) {
          const ojtSkillFromFormData = Array.isArray(
            formData.skill_document?.ojt_skill
          )
            ? formData.skill_document.ojt_skill
            : [];

          const ojtSkillFromDefaultJson = Array.isArray(
            defaultJsonDocument?.ojt_skill
          )
            ? defaultJsonDocument.ojt_skill
            : [];

          const mergedJsonDocument = {
            ojt_skill: [...ojtSkillFromFormData, ...ojtSkillFromDefaultJson],
          };

          formData.skill_document = mergedJsonDocument;
        } else {
          formData.skill_document = defaultJsonDocument;
        }
      } else {
        const defaultJsonDocument = {
          field_skill: { learner_url: learner, trainer_url: trainer },
        };
        if (formData?.skill_document && isSkillAssessmentavailable) {
          const mergedJsonDocument = {
            field_skill: {
              learner_url: [
                ...(formData?.skill_document?.field_skill?.learner_url || []),
                ...(defaultJsonDocument.field_skill.learner_url || []),
              ],
              trainer_url: [
                ...(formData?.skill_document?.field_skill?.trainer_url || []),
                ...(defaultJsonDocument?.field_skill.trainer_url || []),
              ],
            },
          };
          formData.skill_document = mergedJsonDocument;
        } else {
          formData.skill_document = defaultJsonDocument;
        }
      }
    }

    const modulesData = formData?.modules?.map(item => ({
      module_id: item.value,
      weightage: item.weightage,
    }));

    let existingId = null;
    if (formData?.assessment_subtype === "knowledge") {
      existingId =
        courseAssessments?.assessments &&
        courseAssessments.assessments.knowledge &&
        courseAssessments.assessments.knowledge[selectedAssessment]
          ? courseAssessments.assessments.knowledge[selectedAssessment]
              .course_assessment_id
          : null;
    } else {
      existingId =
        courseAssessments?.assessments &&
        courseAssessments.assessments.skill &&
        courseAssessments.assessments.skill[selectedAssessment]
          ? courseAssessments.assessments.skill[selectedAssessment]
              .course_assessment_id
          : null;
    }

    let assessmentData: any = {};
    if (formData?.assessment_subtype === "knowledge") {
      assessmentData = {
        course_id: courseId,
        course_assessment_id: existingId || null,
        status: "draft",
        assessment_subtype: formData?.assessment_subtype,
        question_bank_id: formData?.question_bank_id,
        modules: modulesData,
        name: formData?.name,
        type: formData?.type,
        is_document_upload: false,
        overall_question_count: formData?.overall_question_count,
        estimated_time: formData?.estimated_time,
        policy_id: formData?.policy_id,
        skill_document: {},
      };
    }
    if (formData?.assessment_subtype === "skill") {
      assessmentData = {
        course_id: courseId,
        course_assessment_id: existingId || null,
        status: "draft",
        assessment_subtype: formData?.assessment_subtype,
        question_bank_id: null,
        modules: null,
        name: formData?.name,
        type: formData?.type,
        is_document_upload: true,
        overall_question_count: null,
        estimated_time: null,
        policy_id: null,
        skill_document: formData.skill_document,
      };
    }

    let data: any = {};
    if (selectedAssessment !== "" && selectedAssessment !== null) {
      data = await dispatch(updateAssessment({ assessmentData }));
    } else {
      data = await dispatch(createAssessment({ assessmentData }));
    }

    const selected = [];
    selectedLOs?.forEach(item => {
      selected.push(item?.name);
    });

    const loData = [];
    const added = {};
    const updatedId = data?.payload?.id || existingId;
    courseContent?.assessment_learning_outcome?.forEach((item: any) => {
      const assessmentId = item?.assessment_id;

      if (updatedId !== assessmentId) {
        if (!added[assessmentId] || added[assessmentId].length === 0) {
          added[assessmentId] = [item?.id?.toString()];
        } else {
          added[assessmentId].push(item?.id?.toString());
        }

        loData.push({
          assessment_id: assessmentId,
          outcome: item?.outcome,
          function: item?.function,
          learning_outcome_id: item?.id,
        });
      }
    });

    learningOutcomesMap?.forEach(item => {
      if (
        selected.includes(item?.name) &&
        !added[updatedId]?.includes(item?.name) &&
        updatedId
      ) {
        loData.push({
          assessment_id: updatedId,
          outcome: item?.label,
          function: item?.function,
          learning_outcome_id: item?.name,
        });
      }
    });

    const loMap = {
      course_id: courseId,
      is_assessment: true,
      facilitator_information: "",
      learning_outcome: loData,
    };
    await dispatch(updateLearningOutcome({ learningOutcome: loMap }));
    sliderToggle();
    setDefaultData();
    await dispatch(getAssessment({ id: courseId }));
    await dispatch(getCourseContent({ id: courseId }));
  };

  const getKnowledgeAssessments = () => {
    return (
      <>
        <FormGroup>
          <LabelTooltip
            label={KnowledgeAssessmentType}
            tooltipText={`${Select} ${KnowledgeAssessmentType}`}
            important
          />
          <Input
            onChange={handleChange}
            name="type"
            type="select"
            value={formData?.type}
            invalid={!!errors.type}
          >
            {knowledgeOptions?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          {errors.type && <div className="invalid-feedback">{errors.type}</div>}
        </FormGroup>
        <Row className="mt-3">
          <Col sm="12" lg="6">
            <FormGroup>
              <LabelTooltip
                label={OverallCount}
                tooltipText={`${Enter} ${OverallCount}`}
                important
              />
              <Input
                name="overall_question_count"
                type="text"
                placeholder={`${Enter} ${OverallCount}`}
                value={formData?.overall_question_count}
                onChange={handleChange}
                invalid={!!errors.overall_question_count}
              />
              {errors.overall_question_count && (
                <div className="invalid-feedback">
                  {errors.overall_question_count}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col sm="12" lg="6">
            <FormGroup>
              <LabelTooltip
                label={AssessmentDurationInMinutes}
                tooltipText={`${Enter} ${AssessmentDurationInMinutes}`}
                important
              />
              <Input
                name="estimated_time"
                type="text"
                placeholder={`${Enter} ${AssessmentDurationInMinutes}`}
                value={formData?.estimated_time}
                onChange={handleChange}
                invalid={!!errors.estimated_time}
              />
              {errors.estimated_time && (
                <div className="invalid-feedback">{errors.estimated_time}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Col>
          <FormGroup>
            <LabelTooltip
              label={QuestionBank}
              tooltipText={`${Select} ${QuestionBank}`}
              important
            />
            <Input
              name="question_bank_id"
              type="select"
              value={formData?.question_bank_id}
              onChange={e => {
                if (e.target.value)
                  dispatch(getModuleList({ id: e.target.value }));
                handleChange(e);
              }}
              invalid={!!errors.question_bank_id}
            >
              {questionListData &&
                questionListData.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Input>
            {errors.question_bank_id && (
              <div className="invalid-feedback">{errors.question_bank_id}</div>
            )}
          </FormGroup>
        </Col>
        {formData?.modules &&
          formData?.modules?.map((module: any) => (
            <Module
              key={module.value}
              moduleId={module.value}
              moduleTitle={module.label}
              questionsCount={100}
              weightage={module.weightage}
              onWeightageChange={handleWeightageChange}
            />
          ))}
        <Col className="mt-3">
          <FormGroup>
            <LabelTooltip
              label={Policy}
              tooltipText={`${Select} ${Policy}`}
              important
            />
            <Input
              name="policy_id"
              type="select"
              value={formData?.policy_id}
              onChange={handleChange}
              invalid={!!errors.policy_id}
            >
              {policyListData &&
                policyListData.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Input>
            {errors.policy_id && (
              <div className="invalid-feedback">{errors.policy_id}</div>
            )}
          </FormGroup>
        </Col>
        <div className="mt-5 d-flex gap-2">
          <MUIIcons size={24} iconName="InfoOutlined" />
          <P className="text-gray">{ReAttemptPolicy}</P>
        </div>
      </>
    );
  };

  const getSkillAssessments = () => {
    return (
      <>
        <FormGroup>
          <LabelTooltip
            label={SkillType}
            tooltipText={`${Select} ${SkillType}`}
            important
          />
          <Input
            value={formData?.type}
            name="type"
            type="select"
            invalid={!!errors.type}
            onChange={() => {
              toggleTypeModal();
            }}
          >
            {skillOptions?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          {errors.type && <div className="invalid-feedback">{errors.type}</div>}
        </FormGroup>

        {formData?.type === "ojt" && (
          <>
            <H4>{`${Upload} ${Document}`}</H4>
            {formData?.skill_document?.ojt_skill?.map((item, index) => {
              const uniqueIds = `${index}-${index + index}-${index + index + index}`;
              return (
                <H3 key={index} className="mt-2">
                  <Badges color="light text-dark">
                    <MUIIcons size={24} iconName="InsertDriveFileOutlined" />
                    <span
                      className="m-1"
                      id={sanitizeString(`tooltip-course-name${uniqueIds}`)}
                    >
                      {item?.file_name}
                    </span>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[uniqueIds] || false}
                      target={sanitizeString(`tooltip-course-name${uniqueIds}`)}
                      toggle={() => toggleTooltip(uniqueIds)}
                    >
                      {item?.file_name}
                    </Tooltip>
                    <MUIIcons
                      iconName="DeleteOutlineOutlined"
                      className="primary-icon-color pointer"
                      size={24}
                      onClick={() => {
                        const data = {
                          ...formData,
                          skill_document: {
                            ...formData.skill_document,
                            ojt_skill: [...formData.skill_document.ojt_skill],
                          },
                        };
                        data.skill_document.ojt_skill.splice(index, 1);
                        setFormData(data);
                      }}
                    />
                  </Badges>
                </H3>
              );
            })}
            <MultiFileUpload
              note={FileSizeNote}
              onFileChange={(file, status) => {
                if (status === "removed") {
                  setOjtDocument(prevDocuments =>
                    prevDocuments.filter(item => item.name !== file.name)
                  );
                } else if (status === "done" && file) {
                  setOjtDocument(prevDocuments => [...prevDocuments, file]);
                }
              }}
            />
          </>
        )}

        {formData?.type !== "ojt" && (
          <div>
            <div className="mb-3">
              <H4>{`${Upload} ${LearnerDocument}`}</H4>
              <H5 className="mt-3">{LearnerDocument}</H5>
              {formData?.skill_document?.field_skill?.learner_url?.map(
                (item, index) => {
                  const uniqueId = `${index}-${index + index}-${index + index + index}`;
                  return (
                    <H3 key={index} className="mt-2">
                      <Badges color="light text-dark">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span
                          className="m-1"
                          id={sanitizeString(`tooltip-course-name${uniqueId}`)}
                        >
                          {item?.file_name}
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[uniqueId] || false}
                          target={sanitizeString(
                            `tooltip-course-name${uniqueId}`
                          )}
                          toggle={() => toggleTooltip(uniqueId)}
                        >
                          {item?.file_name}
                        </Tooltip>
                        <MUIIcons
                          iconName="DeleteOutlineOutlined"
                          className="primary-icon-color pointer"
                          size={24}
                          onClick={() => {
                            const data = {
                              ...formData,
                              skill_document: {
                                ...formData.skill_document,
                                field_skill: {
                                  ...formData.skill_document.field_skill,
                                  learner_url: [
                                    ...(formData.skill_document.field_skill
                                      ?.learner_url || []),
                                  ],
                                },
                              },
                            };
                            data.skill_document.field_skill.learner_url.splice(
                              index,
                              1
                            );
                            setFormData(data);
                          }}
                        />
                      </Badges>
                    </H3>
                  );
                }
              )}
              <MultiFileUpload
                note={FileSizeNote}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    setLearnerDocument(prevDocuments =>
                      prevDocuments.filter(item => item.name !== file.name)
                    );
                  } else if (status === "done" && file) {
                    setLearnerDocument(prevDocuments => [
                      ...prevDocuments,
                      file,
                    ]);
                  }
                }}
              />
            </div>
            <Divider />
            <div className="mt-4 mb-2">
              <H4>{`${Upload} ${TrainerDocument}`}</H4>
              <H5 className="mt-3">{TrainerDocument}</H5>
              {formData?.skill_document?.field_skill?.trainer_url?.map(
                (item, index) => {
                  const uniqueId2 = `${index + index}-${index}-${index + index + index}`;
                  return (
                    <H3 key={index} className="mt-2">
                      <Badges color="light text-dark">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span
                          className="m-1"
                          id={sanitizeString(`tooltip-course-name${uniqueId2}`)}
                        >
                          {item?.file_name}
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[uniqueId2] || false}
                          target={sanitizeString(
                            `tooltip-course-name${uniqueId2}`
                          )}
                          toggle={() => toggleTooltip(uniqueId2)}
                        >
                          {item?.file_name}
                        </Tooltip>
                        <MUIIcons
                          iconName="DeleteOutlineOutlined"
                          className="primary-icon-color pointer"
                          size={24}
                          onClick={() => {
                            const data = {
                              ...formData,
                              skill_document: {
                                ...formData.skill_document,
                                field_skill: {
                                  ...formData.skill_document.field_skill,
                                  trainer_url: [
                                    ...(formData.skill_document.field_skill
                                      ?.trainer_url || []),
                                  ],
                                },
                              },
                            };
                            data.skill_document.field_skill.trainer_url.splice(
                              index,
                              1
                            );
                            setFormData(data);
                          }}
                        />
                      </Badges>
                    </H3>
                  );
                }
              )}
              <MultiFileUpload
                note={FileSizeNote}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    setTrainerDocument(prevDocuments =>
                      prevDocuments.filter(item => item.name !== file.name)
                    );
                  } else if (status === "done" && file) {
                    setTrainerDocument(prevDocuments => [
                      ...prevDocuments,
                      file,
                    ]);
                  }
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const onClickItem = async (value, type) => {
    setSelectedAssessmentType(type);
    if (value === "item3") {
      const docs = [];
      const data = { ...courseAssessments };
      if (
        data?.assessments?.skill[selectedAssessment]?.skill_document?.ojt_skill
      )
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.ojt_skill?.map((item: any) => {
          docs.push(item?.file_url);
        });

      if (
        data?.assessments?.skill[selectedAssessment]?.skill_document
          ?.field_skill
      ) {
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.field_skill?.learner_url?.map((item: any) => {
          docs.push(item?.file_url);
        });
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.field_skill?.trainer_url?.map((item: any) => {
          docs.push(item?.file_url);
        });
      }
      handleAllFilesDownload(docs);
    }
    if (value === "item2") {
      toggleModal();
    }
    if (value === "item1" && type === "Knowledge") {
      const modules = [];
      courseAssessments?.assessments?.knowledge[
        selectedAssessment
      ]?.modules?.forEach(module => {
        modules.push({
          value: module?.module_id,
          label: module?.module_name,
          weightage: module?.weightage,
        });
      });

      const editData: any = {
        assessment_subtype:
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            ?.assessment_subtype,
        question_bank_id:
          courseAssessments?.assessments?.knowledge[
            selectedAssessment
          ]?.question_bank?.question_bank_id?.toString(),
        modules: modules,
        name: courseAssessments?.assessments?.knowledge[selectedAssessment]
          ?.name,
        type: courseAssessments?.assessments?.knowledge[selectedAssessment]
          ?.type,
        overall_question_count:
          courseAssessments?.assessments?.knowledge[
            selectedAssessment
          ]?.overall_question_count?.toString(),
        estimated_time:
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            ?.estimated_time,
        policy_id:
          courseAssessments?.assessments?.knowledge[
            selectedAssessment
          ]?.policy?.policy_id?.toString(),
        skill_document: {},
      };
      const outcomes = [];
      courseContent?.assessment_learning_outcome?.forEach((out: any) => {
        if (
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            .course_assessment_id === out?.assessment_id
        ) {
          outcomes.push({
            name: out.id.toString(),
            value: out.outcome,
            label: out.outcome,
          });
        }
      });
      setSelectedLOs(outcomes);
      setFormData(editData);
      setModalContent("EditAssessment");
      sliderToggle();
    } else if (value === "item1" && type === "Skill") {
      const editData: any = {
        assessment_subtype:
          courseAssessments?.assessments?.skill[selectedAssessment]
            ?.assessment_subtype,
        question_bank_id: null,
        modules: null,
        name: courseAssessments?.assessments?.skill[selectedAssessment]?.name,
        type: courseAssessments?.assessments?.skill[selectedAssessment]?.type,
        overall_question_count: null,
        estimated_time: null,
        policy_id: null,
        skill_document:
          courseAssessments?.assessments?.skill[selectedAssessment]
            ?.skill_document,
      };
      const outcomes = [];
      courseContent?.assessment_learning_outcome?.forEach((out: any) => {
        if (
          courseAssessments?.assessments?.skill[selectedAssessment]
            .course_assessment_id === out?.assessment_id
        ) {
          outcomes.push({
            name: out.id.toString(),
            value: out.outcome,
            label: out.outcome,
          });
        }
      });
      setSelectedLOs(outcomes);
      setFormData(editData);
      setModalContent("EditAssessment");
      sliderToggle();
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleTypeModal = () => {
    setIsTypeModalOpen(!isTypeModalOpen);
  };

  const updateAssessmentStatus = async type => {
    if (type === "completed") {
      if (
        (!courseAssessments?.assessments?.knowledge ||
          courseAssessments?.assessments?.knowledge?.length === 0) &&
        (!courseAssessments?.assessments?.skill ||
          courseAssessments?.assessments?.skill?.length === 0)
      ) {
        showToast(AddAnyOfAssessments, "error");
        return;
      }

      const assessmentData = {
        course_id: courseId,
        is_completed: true,
      };
      await dispatch(updateAssessment({ assessmentData }));
    }
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details`,
      { state: { courseId: courseId } }
    );
  };
  const getModalHeading = () => {
    switch (modalContent) {
      case "comments":
      default:
        return Comment;
      case "CreateAssessment":
        return CreateAssessments;
      case "EditAssessment":
        return EditAssessment;
    }
  };
  return (
    <div className={!isMobile && "page-body"}>
      <CommonModal
        sizeTitle={SkillTypeHeader}
        modalBodyClassName=""
        isOpen={isTypeModalOpen}
        toggle={toggleTypeModal}
        backdrop="static"
        size="lg"
        showFooter
        primaryBtnText={Confirm}
        onPrimaryBtnClick={async () => {
          const data = { ...formData };
          if (data.type === "field") {
            data.type = "ojt";
          } else if (data.type === "ojt") {
            data.type = "field";
          }
          data.skill_document = {};
          setFormData(data);
          toggleTypeModal();
        }}
      >
        {AssessmentTypeChange}
      </CommonModal>
      <CommonModal
        sizeTitle={DeleteAssessmentHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        primaryBtnText={Confirm}
        onPrimaryBtnClick={async () => {
          const deleteObj = {
            deleteAssessmentData: {
              course_assessment_id:
                selectedAssessmentType === "Knowledge"
                  ? knowledgeAssessments[selectedAssessment]
                      ?.course_assessment_id
                  : skillAssessments[selectedAssessment]?.course_assessment_id,
            },
          };
          await dispatch(deleteAssessment(deleteObj));
          await dispatch(getAssessment({ id: courseId }));
          setSelectedAssessment(null);
          setSelectedAssessmentType(null);
          toggleModal();
        }}
      >
        {DeleteAssessment}
      </CommonModal>
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{getModalHeading()}</H2>
        <Divider />
        {modalContent === "comments" ? (
          <Comments
            userMap={userMap}
            text={iconComments}
            comments={commentList}
            send
            onSendComments={onSendComments}
            onCommentsChange={onCommentsChange}
          />
        ) : (
          <div style={{ height: "80vh", overflowY: "auto" }}>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={AssessmentType}
                  tooltipText={`${Select} ${AssessmentType}`}
                  important
                />
                <Input
                  disabled={selectedAssessment !== null}
                  name="assessment_subtype"
                  type="select"
                  value={formData?.assessment_subtype}
                  onChange={handleChange}
                >
                  {assessmentTypesOptions?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={AssessmentName}
                  tooltipText={`${Enter} ${AssessmentName}`}
                  important
                />
                <Input
                  name="name"
                  type="text"
                  placeholder={`${Enter} ${AssessmentName}`}
                  value={formData?.name}
                  onChange={handleChange}
                  invalid={!!errors.name}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={LearningOutcomes}
                  tooltipText={`${Select} ${LearningOutcomes}`}
                  important
                />
                <MultiSelectDropdown
                  onChange={values => {
                    const loS = learningOutcomes
                      .filter((element: any) => values.includes(element.name))
                      .map((element: any) => ({
                        name: element.name?.toString(),
                        value: element.label,
                        label: element.label,
                      }));

                    setSelectedLOs(loS);
                  }}
                  options={learningOutcomes}
                  placeholder={`${Select} ${LearningOutcomes}`}
                  defaultSelected={selectedLOs}
                />
                {errors.lo && (
                  <div className="mt-1 text-danger">{errors.lo}</div>
                )}
              </FormGroup>
            </Col>
            {formData?.assessment_subtype === "knowledge" &&
              getKnowledgeAssessments()}
            {formData?.assessment_subtype === "skill" && getSkillAssessments()}
          </div>
        )}
        <div
          onClick={e => {
            handleSubmit(e);
          }}
          className="d-flex justify-content-end mt-3"
        >
          <Btn color="primary">{Done}</Btn>
        </div>
      </SliderModal>
      <Card className={isMobile && "custom-mobile-card"}>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="d-flex gap-4">
              <div className="comment-icon-container">
                <MUIIcons
                  size={24}
                  onClick={() => {
                    setModalContent("comments");
                    sliderToggle();
                  }}
                  className="primary-icon-color mt-2 pointer"
                  iconName="ChatBubbleOutlineOutlined"
                />
                {isComentRead != null && isComentRead && (
                  <span className="red-dot"></span>
                )}
              </div>
              <Btn
                onClick={() => {
                  setSelectedAssessment(null);
                  setSelectedAssessmentType(null);
                  setModalContent("CreateAssessment");
                  setSliderModal(true);
                }}
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>
      <div>
        <AssessmentTable
          title={Knowledge}
          data={knowledgeAssessments}
          headers={knowledgeHeader}
          onClickItem={onClickItem}
          setSelectedAssessment={setSelectedAssessment}
        />
      </div>
      <div>
        <AssessmentTable
          title={Skill}
          data={skillAssessments}
          headers={skillHeader}
          onClickItem={onClickItem}
          setSelectedAssessment={setSelectedAssessment}
        />
      </div>
      <div className="d-flex gap-3 justify-content-end">
        <div
          onClick={() => updateAssessmentStatus("draft")}
          className="edit-details-button"
          color="primary"
        >
          {SaveAsDraft}
        </div>
        <Row>
          <Col xs="auto">
            <Btn
              color="primary"
              onClick={() => updateAssessmentStatus("completed")}
            >
              {Save}
            </Btn>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CreateAssessment;
