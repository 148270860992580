import DataTable from "react-data-table-component";

import { DummyColumn, DummyData } from "../../utils/helper/tableData";

const DataTables = () => {
  return (
    <DataTable
      columns={DummyColumn}
      data={DummyData}
      striped
      persistTableHead
    />
  );
};

export default DataTables;
