import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Image, P } from "../../../../AbstractElements";
import {
  REPORTING_MANAGER,
  TRAINING_MANAGER,
  TRAINER,
  SME,
} from "../../../../Api/constants";
import { dynamicImage } from "../../../../Service";
import {
  RM_PROFILE_TEXT,
  SME_PROFILE_TEXT,
  TM_PROFILE_TEXT,
  TRAINER_PROFILE_TEXT,
} from "../../../../utils/Constant";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../../utils/helper/localStorageutils";

import ProfileBox from "./ProfileBox";
import "./style.scss";

interface UserProfileProps {
  isHideLogoutBtn?: boolean;
}

const UserProfile: React.FC<UserProfileProps> = ({ isHideLogoutBtn }) => {
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    const storedUserName = loginData.name;
    let storedUserRole = "";
    switch (loginData.role) {
      case REPORTING_MANAGER:
        storedUserRole = RM_PROFILE_TEXT;
        break;
      case TRAINING_MANAGER:
        storedUserRole = TM_PROFILE_TEXT;
        break;
      case TRAINER:
        storedUserRole = TRAINER_PROFILE_TEXT;
        break;
      case SME:
        storedUserRole = SME_PROFILE_TEXT;
        break;
      default:
        storedUserRole = loginData.role;
        break;
    }

    if (storedUserName && storedUserRole) {
      setUserName(storedUserName);
      setUserRole(storedUserRole);
    }
  }, []);

  const handleToggle = () => {
    if (isHideLogoutBtn) {
      return;
    }
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={handleToggle}>
        <DropdownToggle
          tag="div"
          className="d-flex profile-media align-items-center gap-4 pointer"
          id="Popover"
        >
          <Image
            className="img-30"
            src={dynamicImage("dashboard/profile.png")}
            alt="user"
          />
          <div className="flex-grow-1">
            <span className="text-white">{userName}</span>
            <P className="mb-0 font-outfit text-white">{userRole}</P>
          </div>
        </DropdownToggle>
        <DropdownMenu className="custom-dropdown-menu">
          <DropdownItem className="custom-dropdown-item">
            <ProfileBox />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

UserProfile.propTypes = {
  isHideLogoutBtn: PropTypes.bool,
};

export default UserProfile;
