import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { Btn, MUIIcons, H4, H5, H2 } from "../../../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  LO_SUBMITTED,
  LOFileKey,
  rmTabsStatusMap,
} from "../../../../../Api/constants";
import SimpleAccordion from "../../../../../CommonElements/Accordion";
import Divider from "../../../../../CommonElements/Divider";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import SliderModal from "../../../../../CommonElements/SliderModal";
import Comments from "../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  downloadFile,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import { reviewLearningOutcome } from "../../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddComment,
  Approve,
  Comment,
  Enter,
  EnterComments,
  FacilitatorRequest,
  LearningOutcomes,
  LOFile,
  PleaseAddComment,
  Reject,
  RejectReason,
  RejectStatus,
  Submit,
} from "../../../../../utils/Constant";
import {
  showToast,
  sortCommentsByDate,
} from "../../../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../../../utils/helper/permission";

const LODetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { courseId } = location.state || "";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedModal, setClickedModal] = useState("");
  const [sliderModal, setSliderModal] = useState(false);
  const [iconComments, setIconComments] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [isComentRead, setIsComentRead] = useState(null);
  const [comments, setComments] = useState("");
  const { courseContent } = useAppSelector(state => state.course);
  const [userMap, setUserMap] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [outcomeListData, setOutcomeListData] = useState([]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    const outcomes = [];

    let comment = [];
    if (courseContent?.comment?.learning_outcome) {
      comment = sortCommentsByDate(courseContent.comment.learning_outcome);
    }
    if (courseContent) {
      courseContent?.learning_outcome?.forEach(item => {
        outcomes.push({
          id: item?.id,
          accordionItemClass: "item-class",
          accordionHeaderClass: "header-class",
          iconWithTitle: <span>Icon with title</span>,
          spanClass: "span-class",
          accordionHeading: item?.outcome,
          icon: true,
          bodyText: item?.function,
        });
      });
      if (courseContent?.comment?.comment_status) {
        try {
          const infoStage = courseContent?.comment?.comment_status?.find(
            (stage: { stages: string }) => stage.stages === "learning_outcome"
          );
          setIsComentRead(infoStage ? !infoStage.is_read : false);
        } catch (error) {
          setIsComentRead(false);
        }
      }
    }
    setOutcomeListData(outcomes);
    setCommentList(comment);
  }, [courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "learning_outcome",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const handleApproveReview = async () => {
    const reasonObj: ApproveLearningOutcome = {
      course_id: courseId,
      status: rmTabsStatusMap["approved"] as "rejected" | "approved",
      reason: "",
    };
    const wrapperObj = {
      approveLo: reasonObj,
    };
    await dispatch(reviewLearningOutcome(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details`,
      { state: { courseId: courseId } }
    );
  };

  const handleRejectReview = async () => {
    if (comments === "" || comments === null) {
      showToast(PleaseAddComment, "error");
      return;
    }
    const reasonObj: ApproveLearningOutcome = {
      course_id: courseId,
      status: rmTabsStatusMap["reject"] as "rejected" | "approved",
      reason: comments,
    };
    const wrapperObj = {
      approveLo: reasonObj,
    };
    await dispatch(reviewLearningOutcome(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details`,
      { state: { courseId: courseId } }
    );
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "learning_outcome",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const getModalHeader = () => {
    if (clickedModal === RejectStatus) {
      return RejectReason;
    }
  };

  const getModalBody = () => {
    if (clickedModal === RejectStatus) {
      return (
        <FormGroup>
          <LabelTooltip
            label={AddComment}
            tooltipText={`${Enter} ${Comment}`}
            important
          />
          <Input
            rows={4}
            name="objective"
            type="textarea"
            onChange={e => {
              if (
                (e.target.value && e.target.value?.length <= 255) ||
                e.target.value === ""
              )
                setComments(e.target.value);
            }}
            placeholder={`${Enter} ${Comment}`}
          />
        </FormGroup>
      );
    }
  };

  return (
    <div className="page-body">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}
        sizeTitle={getModalHeader()}
        showFooter={true}
        primaryBtnText={Submit}
        onPrimaryBtnClick={handleRejectReview}
      >
        {getModalBody()}
      </CommonModal>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <H4 className="mt-2">{LearningOutcomes}</H4>
          <div className="d-flex gap-4">
            <MUIIcons
              size={24}
              className="primary-icon-color mt-2 pointer"
              iconName="FileDownloadOutlined"
              onClick={async () => {
                downloadFile(
                  courseId,
                  LOFileKey,
                  `${LOFile}_${courseContent.course_name}_${courseContent.course_id}`
                );
              }}
            />
            <div className="comment-icon-container">
              <MUIIcons
                size={24}
                onClick={sliderToggle}
                className="primary-icon-color mt-2 pointer"
                iconName="ChatBubbleOutlineOutlined"
              />
              {isComentRead != null && isComentRead && (
                <span className="red-dot"></span>
              )}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <SimpleAccordion accordionList={outcomeListData} />
          <H5 className="fw-bold">{FacilitatorRequest}</H5>
          <div
            dangerouslySetInnerHTML={{
              __html: courseContent?.facilitator_information,
            }}
          />
        </CardBody>
        {hasPermissionToComponent("APPROVE_STAGES") &&
          courseContent?.status === LO_SUBMITTED && (
            <CardFooter className="d-flex justify-content-end">
              <Row>
                <Col xs="auto">
                  <Btn
                    onClick={() => {
                      setClickedModal(RejectStatus);
                      toggleModal();
                    }}
                    outline
                    className="alert-light-primary"
                    color="primary"
                  >
                    {Reject}
                  </Btn>
                </Col>
                <Col xs="auto">
                  <Btn onClick={handleApproveReview} color="primary">
                    {Approve}
                  </Btn>
                </Col>
              </Row>
            </CardFooter>
          )}
      </Card>
    </div>
  );
};

export default LODetails;
