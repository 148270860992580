import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  ALL_USERS_STATUS,
  tmTabsStatusMap,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import CourseCard from "../../../container/CourseCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getCourseList,
  setCourseContentToNull,
  setCourseListToNull,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import { setCourseBasicInputFormValueToInitials } from "../../../ReduxToolkit/Reducers/CourseWizardSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { AddCourse, CourseType, Select } from "../../../utils/Constant";
import {
  formatCustomDate,
  courseListTabs,
  courseFilterDropdownList,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import useIsMobile from "../../../utils/helper/responsive";

const CourseList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>(
    statusParam ? statusParam : "new_request"
  );
  const { courseList } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);
  const [userNameMap, setUsernameMap] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    await dispatch(setCourseListToNull());
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    await dispatch(setCourseContentToNull());
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUsernameMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    fetchCourses();
  }, [activeTab, selectedItems]);

  const fetchCourses = () => {
    const courseType =
      selectedItems?.length > 0 ? selectedItems : ALL_COURSE_FILTER_STATUS;
    dispatch(
      getCourseList({
        status: tmTabsStatusMap[activeTab],
        course_type: courseType,
      })
    ).then(response => {
      if (response?.payload?.course_list?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    navigate(`/course-management/course-list?status=${tabId}`);
  };

  const handleClick = () => {
    dispatch(setCourseContentToNull());
    dispatch(setCourseBasicInputFormValueToInitials());
  };

  const handleSearch = (query: string) => {
    const filteredCourses = courseList?.course_list?.filter(course =>
      course.course_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCourses || []);
    setNoDataFound(!filteredCourses || filteredCourses?.length === 0);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["request_type"]) {
      setSelectedItems(values["request_type"]);
    }
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
  };
  const renderCourseCard = (course: CourseList, index) => {
    return (
      <Col key={index} sm={12} lg={3}>
        <Link
          to={"/course-management/course-details"}
          state={{ courseId: course.id }}
        >
          <CourseCard
            userNameMap={userNameMap}
            course_id={course?.id?.toString()}
            title={course?.domain_names}
            header={course?.course_name}
            rm={course?.description}
            requestedOn={formatCustomDate(course?.created_at)}
            status={course?.status}
            levels={
              course?.expertise_levels
                ? course?.expertise_levels.filter(item => item !== null)
                : []
            }
            type={course?.course_type}
            range={`${course?.min_attendance}-${course?.max_attendance}`}
            assignments={course?.assignments}
          />
        </Link>
      </Col>
    );
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={courseListTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: CourseType,
                      key: "request_type",
                      tooltipText: `${Select} ${CourseType}`,
                      options: courseFilterDropdownList,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              {hasPermissionToComponent("ADD_COURSE") && (
                <Link
                  to={"/course-management/add-course"}
                  state={{ courseRequestId: "" }}
                >
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                    onClick={handleClick}
                  >
                    {AddCourse}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">
        {noDataFound ? (
          <NoDataFound />
        ) : searchResults?.length > 0 ? (
          searchResults?.map((course, index) => renderCourseCard(course, index))
        ) : (
          courseList?.course_list?.map((course, index) =>
            renderCourseCard(course, index)
          )
        )}
      </Row>
    </div>
  );
};

export default CourseList;
