import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import {
  ALL_COURSE_FILTER_STATUS,
  EXPERTISE_LEVEL_STATUS,
} from "../../../../../../../Api/constants";
import FilterSliderModal from "../../../../../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../../../../CommonElements/MultiSelectDropdown";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../ReduxToolkit/Hooks";
import { getCourseList } from "../../../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  Enter,
  Prerequisite,
  VersionNumber,
  Condition,
  Select,
  OthersPrerequisiteRequired,
  OthersPrerequisites,
  CourseType,
} from "../../../../../../../utils/Constant";
import {
  courseFilterDropdownList,
  richTextSupportedItems,
} from "../../../../../../../utils/helper/helper";

interface BasicInputFormValueInterface {
  prerequisite: any[];
  other_prerequisites: string;
  version_number: string;
  condition: string;
}

interface BasicInputFormValuePropsType {
  getMultiDropdownDataData: (val) => void;
  preSelectedPrerequisite: any[];
  getUserData: (event: ChangeEvent<HTMLInputElement>) => void;
  basicInputFormValue: BasicInputFormValueInterface;
  level?: number;
  getRichTextUserData: any;
  prerequisiteListData: any;
  setShowOtherPrerequisite: any;
  showOtherPrerequisite: boolean;
  errors: {
    prerequisite: string;
    version_number: string;
    condition: string;
    other_prerequisites: string;
  };
}

const CompetencyFormOne: React.FC<BasicInputFormValuePropsType> = ({
  basicInputFormValue,
  getUserData,
  getRichTextUserData,
  getMultiDropdownDataData,
  errors,
  preSelectedPrerequisite,
  showOtherPrerequisite,
  setShowOtherPrerequisite,
}) => {
  const dispatch = useAppDispatch();
  const { courseList } = useAppSelector(state => state.course);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const { version_number, condition, other_prerequisites } =
    basicInputFormValue;
  const [courseListData, setCourseListData] = useState([]);

  useEffect(() => {
    dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const publishedCourse = [];
    if (courseList) {
      courseList?.course_list?.forEach(item => {
        publishedCourse.push({
          name: item?.id?.toString(),
          label: item?.course_name,
          value: item?.course_name,
        });
      });
    }
    setCourseListData(publishedCourse);
  }, [courseList]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (name === "version_number") {
      getUserData(event);
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["course_type"]) {
      await dispatch(
        getCourseList({
          status: EXPERTISE_LEVEL_STATUS,
          course_type: values["course_type"] || ALL_COURSE_FILTER_STATUS,
        })
      );
    }
  };

  const handleClear = async () => {
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  return (
    <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
      <Row className="mt-4">
        <Col sm="12" lg="5">
          <FormGroup>
            <LabelTooltip
              label={Prerequisite}
              tooltipText={`${Select} ${Prerequisite}`}
              important
            />
            <div className={errors.prerequisite ? "border border-danger" : ""}>
              <MultiSelectDropdown
                onChange={values => {
                  getMultiDropdownDataData(values);
                }}
                options={courseListData}
                placeholder={`${Select} ${Prerequisite}`}
                defaultSelected={preSelectedPrerequisite}
              />
            </div>
            <div className="mt-1 text-sm text-danger">
              {errors.prerequisite}
            </div>
          </FormGroup>
        </Col>
        <Col className="filter-margin">
          <FilterSliderModal
            dropdowns={[
              {
                label: CourseType,
                key: "course_type",
                tooltipText: `${Select} ${CourseType}`,
                options: courseFilterDropdownList,
                isMultiSelect: true,
              },
            ]}
            selectedFilterValues={selectedFilterValues}
            defaultFilterValues={defaultFilterValues}
            setSelectedFilterValues={setSelectedFilterValues}
            setDefaultFilterValues={setDefaultFilterValues}
            onDone={handleDone}
            onClear={handleClear}
          />
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={VersionNumber}
              tooltipText={`${Enter} ${VersionNumber}`}
              important
            />
            <Input
              onChange={handleInputChange}
              value={version_number}
              name="version_number"
              type="text"
              maxLength={10}
              placeholder={`${Enter} ${VersionNumber}`}
              invalid={!!errors.version_number}
            />
            <div className="invalid-feedback">{errors.version_number}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <div className="mt-2">
          <div className="form-check text-dark">
            <Input
              className=".border-primary"
              id="flexCheckAddCurriculum"
              type="checkbox"
              checked={showOtherPrerequisite}
              onChange={() => {
                setShowOtherPrerequisite(!showOtherPrerequisite);
              }}
            />
            <Label className="text-dark" htmlFor="flexCheckAddCurriculum" check>
              {OthersPrerequisiteRequired}
            </Label>
          </div>
        </div>
        {showOtherPrerequisite && (
          <Col sm="12" xs="12">
            <FormGroup>
              <LabelTooltip
                label={OthersPrerequisites}
                tooltipText={`${Enter} ${OthersPrerequisites}`}
                important
              />
              <div
                className={
                  errors.other_prerequisites ? "border border-danger" : ""
                }
              >
                <CKEditor
                  onChange={(_event, editor) => {
                    const data = editor.getData();
                    getRichTextUserData("other_prerequisites", data);
                  }}
                  config={{
                    toolbar: {
                      items: richTextSupportedItems,
                    },
                  }}
                  editor={ClassicEditor}
                  data={other_prerequisites}
                />
              </div>
              <div className="mt-1 text-sm text-danger">
                {errors.other_prerequisites}
              </div>
            </FormGroup>
          </Col>
        )}
      </Row>
      <Col className="mt-3">
        <FormGroup>
          <LabelTooltip
            label={Condition}
            tooltipText={`${Enter} ${Condition}`}
            important
          />
          <div className={errors.condition ? "border border-danger" : ""}>
            <CKEditor
              onChange={(_event, editor) => {
                const data = editor.getData();
                getRichTextUserData("condition", data);
              }}
              config={{
                toolbar: {
                  items: richTextSupportedItems,
                },
              }}
              editor={ClassicEditor}
              data={condition}
            />
          </div>
          <div className="mt-1 text-sm text-danger">{errors.condition}</div>
        </FormGroup>
      </Col>
    </Form>
  );
};

export default CompetencyFormOne;
