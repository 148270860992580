import React, { useState } from "react";

import { MUIIcons, H5, Image, P, Badges } from "../../AbstractElements";
import {
  Confirm,
  DeleteUserHeader,
  DeleteUserMessage,
  Owner,
} from "../../utils/Constant";
import { UserCardWithExtraDetailsProps } from "../../utils/helper/propTypes";
import CommonModal from "../Modal";

const UserCardDetails: React.FC<UserCardWithExtraDetailsProps> = ({
  id,
  imagePath,
  name,
  detail,
  showDelete = false,
  onDelete = () => {
    return;
  },
  showOwner = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <CommonModal
        sizeTitle={DeleteUserHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          onDelete(id);
          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteUserMessage}
      </CommonModal>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2 align-items-center">
          <Image
            className="img-30 rounded-circle"
            src={imagePath}
            alt="users"
          />
          <div className="d-flex flex-column justify-content-center flex-grow-1">
            <div className="d-flex gap-3">
              <H5 className={`mb-0 ${detail ? "fw-bold" : ""}`}>{name}</H5>
              {showOwner && <Badges status="draft">{Owner}</Badges>}
            </div>

            {detail && <P className="mb-0">{detail}</P>}
          </div>
        </div>
        {showDelete && (
          <MUIIcons
            iconName="DeleteOutlineOutlined"
            className="primary-icon-color pointer"
            size={24}
            onClick={toggleModal}
          />
        )}
      </div>
    </>
  );
};

export default UserCardDetails;
