import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import MediaPlayer from "../MediaPlayer";

const PreviewModal = ({ isOpen, toggle, mediaUrl }) => {
  const fileExtension = mediaUrl.split(".").pop().toLowerCase();

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" center>
      <ModalHeader toggle={toggle}>File Preview</ModalHeader>
      <ModalBody>
        {["png", "jpg", "jpeg"].includes(fileExtension) ? (
          <img src={mediaUrl} alt="Preview" style={{ width: "100%" }} />
        ) : fileExtension === "mp4" ? (
          <video controls style={{ width: "100%" }}>
            <source src={mediaUrl} type="video/mp4" />
          </video>
        ) : fileExtension === "mp3" ? (
          <MediaPlayer src={mediaUrl} />
        ) : null}
      </ModalBody>
    </Modal>
  );
};

PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  mediaUrl: PropTypes.string.isRequired,
};

export default PreviewModal;
