import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, CardHeader, Table, Tooltip } from "reactstrap";

import { Badges, MUIIcons, H3, H4, H5 } from "../../AbstractElements";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import "./style.scss";
import CommonModal from "../../CommonElements/Modal";
import { LearningOutcomes } from "../../utils/Constant";
import {
  capitalizeFirstLetter,
  getEllipsedFileName,
  sanitizeString,
} from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

interface AssessmentData {
  skillName: string;
  lo: string[];
  type: string;
  questions?: number;
  modules?: string;
  assessmentDuration?: number;
  learnerDocs?: string;
  trainerDocs?: string;
  actions: string;
}

interface AssessmentTableProps {
  data: AssessmentData[];
  headers: string[];
  title: string;
  onClickItem?: (value, type) => void;
  setSelectedAssessment?: (value) => void;
  isEdit?: boolean;
}

const AssessmentTable: React.FC<AssessmentTableProps> = ({
  data,
  headers,
  title,
  onClickItem,
  setSelectedAssessment,
  isEdit = true,
}) => {
  const isMobile = useIsMobile();
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [loData, setLoData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    if (isModalOpen) {
      setLoData([]);
    }
    setIsModalOpen(!isModalOpen);
  };

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <CommonModal
        sizeTitle={LearningOutcomes}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
      >
        {loData?.map((item, index) => <div key={index}>• {item.trim()}</div>)}
      </CommonModal>
      <Card>
        <CardHeader>
          <H4>{title}</H4>
        </CardHeader>
        <CardBody className="pt-0 projects px-0">
          <div className="dataTables_wrapper">
            <div
              className={
                isMobile
                  ? "table-responsive theme-scrollbar"
                  : "theme-scrollbar"
              }
            >
              <Table
                className="table display dataTable no-footer"
                id="selling-product"
              >
                <thead className="light-background">
                  <tr>
                    {headers.map((header, index) => (
                      <th className=".b-b-secondary" key={index}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: any, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.skillName}</td>
                        <td className="lo-cell">
                          <div>
                            <H5 className="mt-3 d-flex gap-2">
                              {item?.lo[0]}{" "}
                              <Badges
                                onClick={() => {
                                  {
                                    setLoData(item.lo);
                                  }
                                  toggleModal();
                                }}
                                className="pointer"
                              >
                                {item.lo.length > 1 && `${item.lo.length - 1}+`}
                              </Badges>
                            </H5>
                          </div>
                        </td>
                        <td>
                          {item?.type === "ojt"
                            ? item?.type?.toString()?.toUpperCase()
                            : capitalizeFirstLetter(item?.type)}
                        </td>
                        {item.questions && <td>{item.questions}</td>}
                        {item.modules && (
                          <td className="modules-cell">{item.modules}</td>
                        )}
                        {item.assessmentDuration && (
                          <td className="assessment-duration-cell">
                            {item.assessmentDuration}
                          </td>
                        )}
                        {item.learnerDocs && (
                          <td>
                            {item.learnerDocs?.map((doc, docIndex) => {
                              const uniqueId = `${i}-${docIndex}`;
                              return (
                                <H3 className="mt-2" key={docIndex}>
                                  <Badges color="light text-dark">
                                    <MUIIcons
                                      size={24}
                                      iconName="InsertDriveFileOutlined"
                                    />
                                    <span
                                      className="m-1"
                                      id={sanitizeString(
                                        `tooltip-course1-name-${uniqueId}`
                                      )}
                                    >
                                      {getEllipsedFileName(doc?.file_name)}
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      isOpen={tooltipOpen[uniqueId] || false}
                                      target={sanitizeString(
                                        `tooltip-course1-name-${uniqueId}`
                                      )}
                                      toggle={() => toggleTooltip(uniqueId)}
                                    >
                                      {doc?.file_name}
                                    </Tooltip>
                                  </Badges>
                                </H3>
                              );
                            })}
                          </td>
                        )}
                        {item.trainerDocs && (
                          <td>
                            {item.trainerDocs?.map((doc, docIndex) => {
                              const uniqueId2 = `${i}-${docIndex}-${docIndex + i}`;
                              return (
                                <H3 className="mt-2" key={docIndex}>
                                  <Badges color="light text-dark">
                                    <MUIIcons
                                      size={24}
                                      iconName="InsertDriveFileOutlined"
                                    />
                                    <span
                                      className="m-1"
                                      id={sanitizeString(
                                        `tooltip-course2-name-${uniqueId2}`
                                      )}
                                    >
                                      {getEllipsedFileName(doc?.file_name)}
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      isOpen={tooltipOpen[uniqueId2] || false}
                                      target={sanitizeString(
                                        `tooltip-course2-name-${uniqueId2}`
                                      )}
                                      toggle={() => toggleTooltip(uniqueId2)}
                                    >
                                      {doc?.file_name}
                                    </Tooltip>
                                  </Badges>
                                </H3>
                              );
                            })}
                          </td>
                        )}
                        <td onClick={() => setSelectedAssessment(i)}>
                          <CardHeaderDropDown
                            mainTitle={isEdit || (title === "Skill" && !isEdit)}
                            firstItem={isEdit ? "Edit" : ""}
                            secondItem={isEdit ? "Delete" : ""}
                            thirdItem={
                              title === "Skill" || !isEdit ? "Download" : ""
                            }
                            onClickItem={value => onClickItem(value, title)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

AssessmentTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AssessmentTable;
