import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";

import { H4, Btn, P } from "../../../AbstractElements";
import { convertPolicyDetailsPayload } from "../../../Api/parser/policy";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CommonModal from "../../../CommonElements/Modal";
import CheckboxButton from "../../../CommonElements/SwitchButton";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createReAttemptPolicy,
  setBasicInputFormValue,
  setPolicyInputFormValueToInitials,
  updateReAttemptPolicyList,
} from "../../../ReduxToolkit/Reducers/PolicySilce";
import {
  AddReAttemptPolicy,
  AddValidNumberInRange10,
  AddValidNumberInRange100,
  AddValidNumberInRange99,
  ConfirmPublish,
  CoolingPeriod,
  CreatePolicy,
  CreateReAttemptPolicyPublishConfirmation,
  Enter,
  EnterScoreReAttempt,
  InDays,
  IsReAttemptPolicy,
  MaxScore,
  MinScore,
  ModifyPolicy,
  ModifyReAttemptPolicyPublishConfirmation,
  NoOfAttempt,
  PassingPercentage,
  PolicyCoolingRequired,
  PolicyMaxRequired,
  PolicyMinRequired,
  PolicyName,
  PolicyNameIsRequired,
  PolicyNumberOfAttemptsRequired,
  PolicyPassingPercentageIsRequired,
  Submit,
} from "../../../utils/Constant";
import { handleKeyDownOnNumericWithoutDecimal } from "../../../utils/helper/helper";

const AddPolicy: React.FC = () => {
  const dispatch = useAppDispatch();
  const { basicInputFormValue } = useAppSelector(state => state.policy);
  const location = useLocation();
  const navigate = useNavigate();
  const { policyDetail, edit } = location.state || {};
  const [errors, setErrors] = useState({
    name: "",
    no_of_attempt: "",
    cooling_period: "",
    pass_percentage: "",
    max_score: "",
    min_score: "",
  });
  const [isPublishConfirmModalOpen, setIsPublishConfirmModalOpen] =
    useState(false);

  useEffect(() => {
    if (edit && policyDetail) {
      const editObj = {
        id: policyDetail.id,
        name: policyDetail.name,
        no_of_attempt: policyDetail.no_of_attempt,
        cooling_period: policyDetail.cooling_period,
        pass_percentage: policyDetail.pass_percentage,
        is_reattempt: policyDetail.is_reattempt,
        max_score: policyDetail.max_score,
        min_score: policyDetail.min_score,
      };
      dispatch(setBasicInputFormValue(editObj));
    }
    return () => {
      dispatch(setPolicyInputFormValueToInitials());
    };
  }, [edit, location, dispatch, policyDetail]);

  const handleCheckboxChange = (e: { target: { checked: boolean } }) => {
    dispatch(
      setBasicInputFormValue({
        ...basicInputFormValue,
        is_reattempt: e.target.checked,
      })
    );
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (
      (name === "pass_percentage" ||
        name === "min_score" ||
        name === "max_score") &&
      (Number(value) > 100 || value.length > 3)
    ) {
      dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: "" }));
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: AddValidNumberInRange100,
      }));
      return;
    }

    if (name === "no_of_attempt" && (Number(value) > 10 || value.length > 2)) {
      dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: "" }));
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: AddValidNumberInRange10,
      }));
      return;
    }

    if (name === "cooling_period" && (Number(value) > 99 || value.length > 2)) {
      dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: "" }));
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: AddValidNumberInRange99,
      }));
      return;
    }

    dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {
      name: "",
      no_of_attempt: "",
      cooling_period: "",
      pass_percentage: "",
      max_score: "",
      min_score: "",
    };

    let isError = false;
    if (!basicInputFormValue.name) {
      newErrors.name = PolicyNameIsRequired;
      isError = true;
    }
    if (
      !basicInputFormValue.pass_percentage ||
      basicInputFormValue.pass_percentage <= 0
    ) {
      newErrors.pass_percentage = PolicyPassingPercentageIsRequired;
      isError = true;
    }

    if (basicInputFormValue.is_reattempt) {
      if (
        !basicInputFormValue.min_score ||
        basicInputFormValue.min_score <= 0
      ) {
        newErrors.min_score = PolicyMinRequired;
        isError = true;
      }
      if (
        !basicInputFormValue.max_score ||
        basicInputFormValue.max_score <= 0
      ) {
        newErrors.max_score = PolicyMaxRequired;
        isError = true;
      }
      if (
        !basicInputFormValue.no_of_attempt ||
        basicInputFormValue.no_of_attempt <= 0
      ) {
        newErrors.no_of_attempt = PolicyNumberOfAttemptsRequired;
        isError = true;
      }
      if (
        !basicInputFormValue.cooling_period ||
        basicInputFormValue.cooling_period <= 0
      ) {
        newErrors.cooling_period = PolicyCoolingRequired;
        isError = true;
      }
    }

    setErrors(newErrors);
    return isError;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }
    const parser = convertPolicyDetailsPayload(basicInputFormValue);
    if (edit) {
      parser.id = policyDetail.id;
      await dispatch(updateReAttemptPolicyList({ policyContent: parser }));
    } else {
      await dispatch(createReAttemptPolicy({ policyContent: parser }));
    }
    return navigate(`${process.env.PUBLIC_URL}/master-data/policy-list`);
  };

  const togglePublishConfirmModal = () => {
    if (validateForm()) {
      return;
    }
    setIsPublishConfirmModalOpen(!isPublishConfirmModalOpen);
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="mt-4">
        <CardHeader>
          <H4>{AddReAttemptPolicy}</H4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={6}>
              <LabelTooltip
                important
                label={PolicyName}
                tooltipText={`${Enter} ${PolicyName}`}
              />
              <Input
                type="text"
                name="name"
                value={basicInputFormValue.name}
                onChange={handleChange}
                placeholder={`${Enter} ${PolicyName}`}
                invalid={!!errors.name}
                maxLength={100}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </Col>
            <Col lg={6}>
              <LabelTooltip
                important
                label={PassingPercentage}
                tooltipText={`${Enter} ${PassingPercentage}`}
              />
              <Input
                type="number"
                name="pass_percentage"
                inputMode="numeric"
                value={basicInputFormValue.pass_percentage}
                onChange={handleChange}
                placeholder={`${Enter} ${PassingPercentage}`}
                invalid={!!errors.pass_percentage}
                min={1}
                max={100}
                onKeyDown={handleKeyDownOnNumericWithoutDecimal}
              />
              {errors.pass_percentage && (
                <div className="invalid-feedback">{errors.pass_percentage}</div>
              )}
            </Col>
          </Row>
          <div className="mt-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <LabelTooltip
                  label={IsReAttemptPolicy}
                  tooltipText={IsReAttemptPolicy}
                  important={false}
                  placement={"bottom"}
                />
              </Col>
              <Col xs="auto">
                <CheckboxButton
                  onChange={handleCheckboxChange}
                  checked={basicInputFormValue.is_reattempt}
                />
              </Col>
            </Row>
          </div>
          {basicInputFormValue.is_reattempt && (
            <Row>
              <h3 className="text-black">
                <LabelTooltip
                  important={false}
                  label={EnterScoreReAttempt}
                  tooltipText={EnterScoreReAttempt}
                />
              </h3>
              <Col lg={4}>
                <Row>
                  <Col>
                    <LabelTooltip
                      important
                      label={MinScore}
                      tooltipText={`${Enter} ${MinScore}`}
                    />
                    <Input
                      type="number"
                      name="min_score"
                      inputMode="numeric"
                      value={basicInputFormValue.min_score}
                      onChange={handleChange}
                      placeholder={`${Enter} ${MinScore}`}
                      invalid={!!errors.min_score}
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      min={0}
                      max={100}
                    />
                    {errors.min_score && (
                      <div className="invalid-feedback">{errors.min_score}</div>
                    )}
                  </Col>
                  <Col>
                    <LabelTooltip
                      important
                      label={MaxScore}
                      tooltipText={`${Enter} ${MaxScore}`}
                    />
                    <Input
                      type="number"
                      name="max_score"
                      inputMode="numeric"
                      value={basicInputFormValue.max_score}
                      onChange={handleChange}
                      placeholder={`${Enter} ${MaxScore}`}
                      invalid={!!errors.max_score}
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      min={0}
                      max={100}
                    />
                    {errors.max_score && (
                      <div className="invalid-feedback">{errors.max_score}</div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <LabelTooltip
                  important
                  label={NoOfAttempt}
                  tooltipText={`${Enter} ${NoOfAttempt}`}
                />
                <Input
                  type="number"
                  name="no_of_attempt"
                  inputMode="numeric"
                  value={basicInputFormValue.no_of_attempt}
                  onChange={handleChange}
                  placeholder={`${Enter} ${NoOfAttempt}`}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  invalid={!!errors.no_of_attempt}
                  min={0}
                />
                {errors.no_of_attempt && (
                  <div className="invalid-feedback">{errors.no_of_attempt}</div>
                )}
              </Col>
              <Col lg={4}>
                <LabelTooltip
                  important
                  label={CoolingPeriod}
                  tooltipText={`${Enter} ${CoolingPeriod} ${InDays}`}
                />
                <Input
                  type="number"
                  name="cooling_period"
                  inputMode="numeric"
                  value={basicInputFormValue.cooling_period}
                  onChange={handleChange}
                  placeholder={`${Enter} ${CoolingPeriod} ${InDays}`}
                  invalid={!!errors.cooling_period}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  min={0}
                />
                {errors.cooling_period && (
                  <div className="invalid-feedback">
                    {errors.cooling_period}
                  </div>
                )}
              </Col>
            </Row>
          )}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <Row>
            <Col xs="auto">
              <CommonModal
                backdrop="static"
                size="lg"
                isOpen={isPublishConfirmModalOpen}
                toggle={togglePublishConfirmModal}
                sizeTitle={ConfirmPublish}
                showFooter={true}
                primaryBtnText={Submit}
                onPrimaryBtnClick={() => {
                  togglePublishConfirmModal();
                  handleSubmit();
                }}
              >
                <P>
                  {edit
                    ? ModifyReAttemptPolicyPublishConfirmation
                    : CreateReAttemptPolicyPublishConfirmation}
                </P>
              </CommonModal>
              <Btn onClick={togglePublishConfirmModal} color="primary">
                {edit ? ModifyPolicy : CreatePolicy}
              </Btn>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddPolicy;
