import axios from "axios";

import {
  decrementActiveRequests,
  hideLoader,
  showLoader,
  incrementActiveRequests,
} from "../../ReduxToolkit/Reducers/LoaderSlice";
import Store from "../../ReduxToolkit/Store";
import { NoResponseFromServer } from "../../utils/Constant";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../utils/helper/localStorageutils";
import { NO_RESPONSE, REQUEST_SETUP_ERROR } from "../constants";

const apiRequest = async (method: string, url: string, data?: any) => {
  Store.dispatch(incrementActiveRequests());

  try {
    Store.dispatch(showLoader());
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    const headers = loginData
      ? { "x-access-token": loginData.accessToken }
      : {};

    const config = {
      method,
      url,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;
      throw new Error(
        JSON.stringify({ status, message: data?.message || error.message })
      );
    } else if (error.request) {
      throw new Error(
        JSON.stringify({
          status: NO_RESPONSE,
          message: NoResponseFromServer,
        })
      );
    } else {
      throw new Error(
        JSON.stringify({
          status: REQUEST_SETUP_ERROR,
          message: error.message,
        })
      );
    }
  } finally {
    Store.dispatch(hideLoader());
    Store.dispatch(decrementActiveRequests());
  }
};

export default apiRequest;
