import PropTypes from "prop-types";

import "./style.scss";
import { UL } from "../../AbstractElements";
import AnnualFunctionHoveringTimeline, {
  TimelineItem,
} from "../../CommonElements/AnnualFunctionHoveringTimeline";
import { By, RejectedFor } from "../../utils/Constant";
import { formatCustomDate } from "../../utils/helper/helper";
import NoDataFound from "../NoDataFound";

const CourseTimeLine = ({ timeLines = [], userMap = {} }) => {
  const parseTimeLineList = (data: any[]): TimelineItem[] => {
    return (data || []).map((item: any) => {
      const milestoneInfo =
        item.user_id && userMap && userMap[item.user_id]
          ? `${item.milestone} ${By} ${userMap[item.user_id]?.user_name}`
          : item.milestone;

      const description =
        item.status === "rejected"
          ? `${RejectedFor} : ${item.feedback}`
          : item.feedback;

      return {
        date: formatCustomDate(item.date),
        heading: milestoneInfo,
        description,
      };
    });
  };

  return (
    <div className="timeline-wrapper">
      <div className="timeline-section">
        {timeLines?.length > 0 ? (
          <UL className="mt-3 square-timeline simple-list">
            <AnnualFunctionHoveringTimeline
              data={timeLines ?? []}
              parseTimeLineList={parseTimeLineList}
            />
          </UL>
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};

CourseTimeLine.propTypes = {
  timeLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    })
  ),
  userMap: PropTypes.object,
};

export default CourseTimeLine;
