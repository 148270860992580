import React from "react";
import { Button } from "reactstrap";
import { CSSModule } from "reactstrap/types/lib/utils";

import SVG from "../SVG";

interface StyleProp {
  width: number;
  fontSize: number;
  padding: number;
}

interface Props {
  children?: React.ReactNode;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  id?: string;
  type?: "submit" | "reset" | "button" | undefined;
  outline?: boolean | undefined;
  size?: string;
  style?: CSSModule | StyleProp;
  active?: boolean;
  disabled?: boolean;
  tag?: React.ElementType;
  href?: string;
  value?: string;
  as?: string;
  name?: string;
  cssModule?: CSSModule;
  target?: string;
  title?: string;
  close?: boolean;
  block?: boolean;
  svg?: string;
  icon?: React.ReactNode;
}

const Btn = ({ children, svg, icon, ...rest }: Props) => {
  return (
    <Button
      color={rest.color || "transparent"}
      {...rest}
      className="d-flex align-items-center justify-content-conter gap-2"
    >
      {children && <span>{children}</span>}
      {svg && <SVG iconId={svg} />}
      {icon && <span className="mt-2">{icon}</span>}
    </Button>
  );
};

export default Btn;
