import PropTypes from "prop-types";
import { Col, Nav, NavItem, NavLink } from "reactstrap";

import Badges from "../Badge";

import "./style.scss";

const JustifyTabs = ({ tabs, activeTab, onTabClick }) => {
  return (
    <Nav
      pills
      className="gap-3 nav-justified nav-warning flex-column flex-md-row"
    >
      {tabs?.map((tab: any, index: number) => (
        <Col key={index}>
          <NavItem>
            <NavLink
              className={activeTab === tab.id ? "active" : ""}
              onClick={() => onTabClick(tab.id)}
            >
              {tab.label}
              {tab.count && (
                <Badges className="notification-badge bg-primary text-white">
                  {tab.count}
                </Badges>
              )}
            </NavLink>
          </NavItem>
        </Col>
      ))}
    </Nav>
  );
};

JustifyTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default JustifyTabs;
