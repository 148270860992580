import PropTypes from "prop-types";
import "./style.scss";

const FixedFooter = ({ children }) => {
  return (
    <footer className="footer-gmmco bg-white text-white mt-3 px-2">
      <div className="footer-container">
        <div className="py-3 d-flex gap-3">{children}</div>
      </div>
    </footer>
  );
};

FixedFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FixedFooter;
