import SamplePageContainer from "../../../Componant/Dashboard/Pages";

const SamplePage = () => {
  return (
    <div className="page-body">
      <SamplePageContainer />
    </div>
  );
};

export default SamplePage;
