import PropTypes from "prop-types";
import { useState } from "react";
import { Input } from "reactstrap";

import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { mapIlpSga } from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import { Select, Submit, MapSGA, SGA } from "../../utils/Constant";
import CommonModal from "../Modal";

interface MapSgaModalProps {
  isOpen: boolean;
  toggle: (isMapped?: boolean) => void;
  sga: { label: string; id: number }[];
  userId?: number;
}

const MapSgaModal: React.FC<MapSgaModalProps> = ({
  isOpen,
  toggle,
  sga = [],
  userId,
}) => {
  const dispatch = useAppDispatch();
  const [selectedSga, setSelectedSga] = useState<string>("");

  const handleSgaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSga(event.target.value);
  };

  const getMapSgaModalBody = () => {
    return (
      <>
        <div className="mb-3">
          <span className="text-black d-block mb-1">{SGA}</span>
          <Input type="select" value={selectedSga} onChange={handleSgaChange}>
            <option value="" disabled>
              {Select} {SGA}
            </option>
            {sga.map((item, index) => (
              <option key={index} value={item.id}>
                {item.label}
              </option>
            ))}
          </Input>
        </div>
      </>
    );
  };

  const handleSubmit = () => {
    dispatch(
      mapIlpSga({
        mapPayload: {
          type: "sga",
          user_id: userId,
          id: Number(selectedSga),
        },
      })
    ).then(res => {
      if (res?.payload) {
        setSelectedSga("");
        toggle(true);
      }
    });
  };

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={MapSGA}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getMapSgaModalBody()}
    </CommonModal>
  );
};

MapSgaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  sga: PropTypes.array.isRequired,
  userId: PropTypes.number.isRequired,
};

export default MapSgaModal;
