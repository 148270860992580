import React from "react";

import { DefaultButton, Progressbar } from "../../AbstractElements";

import DynamicProgressWithNumberSteps, {
  ProgressStep,
} from "./DynamicProgressWithNumberSteps";

interface StepIndicatorProps {
  progressNumberList: ProgressStep[];
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  progressNumberList,
}) => {
  const latestStepNumber = progressNumberList.length;

  const progressValue =
    ((latestStepNumber - 1) / (progressNumberList.length - 1)) * 100;

  return (
    <div className="position-relative m-3 progress-number">
      <Progressbar value={`${progressValue}`} className="progress-wrapper" />
      <DefaultButton
        size="sm"
        color="primary"
        className="position-absolute top-0 start-0 p-0 translate-middle rounded-circle txt-light"
      >
        1
      </DefaultButton>
      <DynamicProgressWithNumberSteps progressNumberList={progressNumberList} />
    </div>
  );
};

export default StepIndicator;
