import React, { useState } from "react";
import { Col, Row, Tooltip } from "reactstrap";

import { H5, Image, MUIIcons, P } from "../../AbstractElements";
import { Copied, RM } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import { UserProfileCardDetailsProps } from "../../utils/helper/propTypes";

const UserProfileCardDetails: React.FC<UserProfileCardDetailsProps> = ({
  userProfileUrl,
  name,
  detail,
  expertiseLevel,
  location,
  rmName,
  phoneNumber,
  mailId,
  cwsAndCatRecId,
  uniqueKey,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(null);

  const toggleTooltip = (id: string) => {
    setTooltipOpen(tooltipOpen === id ? null : id);
  };
  const handleCopy = (text: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    showToast(Copied, "success");
  };

  return (
    <Row className="d-flex align-items-center">
      <Col
        sm={4}
        xs={12}
        className="d-flex align-items-center justify-content-center justify-content-lg-start p-1 mb-2 mb-lg-0"
      >
        <Image
          className="img-48 rounded-circle p-0"
          src={userProfileUrl}
          alt="users"
        />
        <div className="text-start ms-2">
          <H5 className={`mb-1 ${detail ? "fw-bold" : ""}`}>{name}</H5>
          {detail && expertiseLevel && (
            <P className="mb-0 text-muted small mt-2">
              {detail} | <span>{expertiseLevel}</span>
            </P>
          )}
        </div>
      </Col>
      <Col
        sm={7}
        xs={12}
        className="d-flex flex-column flex-sm-row align-items-center gap-1 gap-lg-3 text-gray mb-2 mb-lg-0"
      >
        {location && (
          <div className="d-flex align-items-center gap-1 mb-2 mb-sm-0">
            <MUIIcons iconName="LocationOnOutlined" size={20} />
            <span>{location}</span>
          </div>
        )}
        {rmName && (
          <div className="d-flex align-items-center gap-1">
            <MUIIcons iconName="PersonOutline" size={20} />
            <span>
              {RM} {rmName}
            </span>
          </div>
        )}
      </Col>
      <Col
        sm={1}
        xs={12}
        className="d-flex justify-content-center  align-items-center  gap-2 text-gray"
      >
        <span
          id={`tooltipIcon-${uniqueKey}-mailTooltip`}
          className="pointer"
          onClick={event => handleCopy(mailId, event)}
        >
          <MUIIcons iconName="MailOutline" size={20} />
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen === `tooltipIcon-${uniqueKey}-mailTooltip`}
          toggle={() => toggleTooltip(`tooltipIcon-${uniqueKey}-mailTooltip`)}
          target={`tooltipIcon-${uniqueKey}-mailTooltip`}
        >
          {mailId}
        </Tooltip>

        <span
          id={`tooltipIcon-${uniqueKey}-phoneTooltip`}
          className="pointer"
          onClick={event => handleCopy(phoneNumber.toString(), event)}
        >
          <MUIIcons iconName="PhoneOutlined" size={20} />
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen === `tooltipIcon-${uniqueKey}-phoneTooltip`}
          toggle={() => toggleTooltip(`tooltipIcon-${uniqueKey}-phoneTooltip`)}
          target={`tooltipIcon-${uniqueKey}-phoneTooltip`}
        >
          {"From Darwin Box"}
        </Tooltip>

        <span
          id={`tooltipIcon-${uniqueKey}-badgeTooltip`}
          className="pointer"
          onClick={event => handleCopy(cwsAndCatRecId.toString(), event)}
        >
          <MUIIcons iconName="BadgeOutlined" size={20} />
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen === `tooltipIcon-${uniqueKey}-badgeTooltip`}
          toggle={() => toggleTooltip(`tooltipIcon-${uniqueKey}-badgeTooltip`)}
          target={`tooltipIcon-${uniqueKey}-badgeTooltip`}
        >
          {"From Darwin Box"}
        </Tooltip>
      </Col>
    </Row>
  );
};

export default UserProfileCardDetails;
