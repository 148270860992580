export const Href = "#javascript";

export const DoNotAccount = "Don't have an account?";
export const CreateAccount = "Create Account";
export const SignInAccount = "Sign in to account";
export const NewPassword = "New Password";
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const SignInWith = "Or Sign In With";
export const Pinned = "Pinned";
export const Back = "Back";
export const SaveAsDraft = "Save as Draft";
export const Search = "Search";
export const CopyText = "Copy text";
export const Configuration = "Configuration";
export const Cancel = "Cancel";
export const Notification = "Notification";
export const CheckAll = "Check All";
export const FacebookHeading = "facebook";
export const TwitterHeading = "twitter";
export const LinkedInHeading = "LinkedIn";
export const SignIn = "Sign In";
export const LoginText = "Login";
export const Password = "Password";
export const UserName = "Username";
export const SelectIntendedAudience = "Select Intended Audience";

//Redux reducer
export const FillAllFields = "Please fill all the fields";
export const PleaseAddComment = "Please add comment";
export const NoResponseFromServer = "No response from server";
export const PleaseProvideRequiredInformation =
  "Please provide all the required information";

//Pages
export const NewRequest = "New Request";
export const AddCourse = "Add Course";
export const Welcome = "Welcome";
export const Next = "Next";
export const Finish = "Finish";
export const Other = "Other";
export const Notes = "Notes";
export const Active = "Active";
export const Upcoming = "Upcoming";
export const Past = "Past";
export const BasicDetails = "Basic Details";
export const CustomerName = "Customer Name";
export const Enter = "Enter";
export const FilledBy = "Filled By";
export const Designation = "Designation";
export const Region = "Region";
export const Dates = "Date";
export const PointOfContact = "Your point of contact with GMMCO";
export const NatureOfBusiness = "Nature Of Business";
export const BusinessGroup = "Business Group";
export const KeyContact = "Key Contact";
export const PrimaryContact = "Primary Contact";
export const PhoneNumber = "Phone Number";
export const EmailId = "Email ID";
export const Extension = "Extension";
export const SecondaryContact = "Secondary Contact";
export const SecondaryPhoneNumber = "Secondary Phone Number";
export const SecondaryEmailId = "Secondary Email ID";
export const SecondaryExtension = "Secondary Extension";
export const ContactDetails = "Contact Details";
export const ContentTopic = "Content Topic";
export const Summary = "Summary";
export const Accomplishment = "Accomplishment";
export const TargetAudience = "Target Audience";
export const CountOfTargetAudience = "Count of Target Audience";
export const SuggestedDuration = "Suggested Duration";
export const SuggestedDays = "Suggested Duration";
export const SuggestedHours = "Suggested Hours";
export const Objective = "Objective";
export const RequestedDetails = "Request details";
export const RejectedByTM = "Rejected by TM";
export const RejectedFor = "Rejected For";
export const Modify = "Modify";
export const UsersEnrolled = "Users Enrolled";
export const BasicInfo = "Basic Info";
export const ContentDetails = "Content Details";
export const RejectReason = "Reject Reason";
export const DeclineReason = "Decline extension request";
export const ApproveReason = "Approve extension request";
export const RevokeReason = "Revoke Reason";
export const Submit = "Submit";
export const AddComment = "Add comment";
export const Comment = "Comment";
export const ConfirmPublish = "Confirm Publish";
export const PleaseAddDayPlans = "Please Add all the Day plans";
export const AddBasicDetails = "Add Basic Details";
export const CourseName = "Course Name";
export const CourseType = "Course Type";
export const FrequencyType = "Frequency Type";
export const FrequencyCount = "Frequency Count";
export const RecurEvery = "Recur every";
export const Occurrence = "Occurrence (No. of times will be assigned)";
export const EndAfter = "End after";
export const Occurrences = "Occurrences";
export const ModuleList = "Module List";
export const ModificationAccessRequest = "Modification Access Request";
export const NoOfQuestions = "No Of Questions";
export const CourseOwner = "Course Owner";
export const AssignSME = "Assign SME";
export const ClickingManagerCTA =
  "By clicking on create course Trainer & SME will get notified";
export const Reject = "Reject";
export const Decline = "Decline";
export const RejectStatus = "reject";
export const Add = "Add";
export const CreateCourse = "Create a course";
export const ModifyCourse = "Modify course";
export const CreatePolicy = "Create a Policy";
export const ModifyPolicy = "Modify Policy";
export const CourseStructure = "Course Structure";
export const AddCourseStructure = "Add Course Structure";
export const Approve = "Approve";
export const CompetencyStatements = "Competency statement";
export const MaterialsHeader = "Materials";
export const LessonPlans = "Lesson Plan";
export const FacilitatorRequest =
  "Facilitators are to ensure that the tasks are completed:";
export const LearningOutcomes = "Learning Outcome";
export const AddLearningOutcomes = "Add Learning Outcome";
export const BondDetails = "Add Trainer & Bond details";
export const PrimaryTrainer = "Primary Trainer";
export const SecondaryTrainer = "Secondary Trainer";
export const AddCurriculum = "Do you want to add curriculum?";
export const Others = "Other References";
export const OtherRemedialAction = "Others";
export const OthersRequired = "Do you want to add Other References";
export const OthersPrerequisiteRequired =
  "Do you want to add Other Prerequisites";
export const OthersPrerequisites = "Other Prerequisites";
export const AddCurriculumButton = "Add Curriculum";
export const DeleteCurriculumButton = "Delete Curriculum";
export const Curriculum = "Curriculum";
export const ExpertiseLevel = "Expertise Level";
export const CourseFee = "Course Fee";
export const BondRequired = "Bond Required";
export const PublishLater = "Publish Later";
export const BondRupees = "Bond Rupees";
export const BondYears = "Bond Years";
export const PublishConfirmation =
  "Are you sure you want to publish this course?";
export const PublishCourseCatalog =
  "By clicking 'Publish now,' your course will be published immediately in the catalog.";
export const PublishLaterCourseCatalog =
  "By clicking 'Publish Later,' your course will be published in the catalog on the selected date.";
export const PublishNow = "Publish Now";
export const Publish = "Publish";
export const Update = "Update";
export const ViewDetails = "View Details";
export const ViewReport = "View Report";
export const Revoke = "Revoke";
export const StartNow = "Start Now";
export const RM = "RM: ";
export const RequestedOn = "Requested on ";
export const Send = "Send";
export const Name = "Name";
export const Description = "Description";
export const AddReason = "Add Reason";
export const AddReasonForDecline = "Add Reason for decline";
export const AddReasonForApprove = "Add Reason for approve";
export const Reason = "Reason";
export const AccessRequestedOn = "Access Requested On : ";
export const ApprovedOn = "Approved On : ";
export const AddProperReason = "Please add Reason for Modification request";
export const RecommendedDelivery = "Recommended Delivery";
export const Text = "Text";
export const New = "New";
export const Save = "Save";
export const SaveQuestion = "Save Question";
export const SaveRemedialAction = "Save Remedial Action";
export const Complete = "Complete";
export const Domain = "Domain";
export const Competency = "Competency";
export const ProficiencyLevel = "Proficiency Level";
export const SelectAll = "Select All";
export const Select = "Select";
export const Participant = "Participant";
export const Users = "Users";
export const ParticipantDetails = "Participant Details";
export const FacilitatorInformation = "Add More Facilitator information";
export const EnterFacilitatorInformation = "More Facilitator information";
export const AutoSavedOn = "Auto Saved on";
export const Prerequisite = "Prerequisite";
export const OtherPrerequisite = "OtherPrerequisites";
export const VersionNumber = "Version Number";
export const Condition = "Condition";
export const PersonalStatement =
  "As a minimum, personnel must meet the following requirements";
export const MediaDemonstration = "Media, Demonstration and practical given";
export const ClassroomEnvironment =
  "Classroom and laboratory/workshop environment";
export const EstimatedDelivery = "Estimated days";
export const Classroom = "Classroom";
export const Practical = "Practical";
export const Resources = "Resources";
export const Tools = "Tools";
export const TrainingAids = "Training Aids";
export const Reference = "References";
export const OtherReference = "Other References";
export const Review = "Review";
export const Assessment = "Assessment";
export const StartDate = "Start Date";
export const EndDate = "End Date";
export const ReviewAssessment = "Review & Assessment";
export const AddNew = "Add New";
export const Done = "Done";
export const Apply = "Apply";
export const Filter = "Filter";
export const DayWiseTopic = "Topic";
export const DayWiseLearning = "Learning activities";
export const DayWiseDelivery = "Delivery";
export const DayWiseTime = "Estimated Duration (in minutes)";
export const IntendedAudience = "Intended Audience";
export const MinimumAttendance = "Minimum Attendance";
export const MaximumAttendance = "Maximum Attendance";
export const Literature = "Literature/Suggestion";
export const Qualification =
  "Any Qualification or Certification that this training leads";
export const AssessmentCriteria = "Assessment Criteria";
export const TheoryRatio = "Theory Ratio";
export const PracticalRatio = "Practical Ratio";
export const PracticalActivityDetails = "Practical Activity Details";
export const ToolsAids = "Tools & Aids Required";
export const RegistrationRequirement = "Registration Requirement";
export const PreCourseWork = "Pre-course work and materials";
export const AddMaterial = "Add Material";
export const AddedMaterials = "Added Materials";
export const ClickToUpload = "Click To Upload";
export const Upload = "Upload";
export const LoadMore = "Load More";
export const Document = "Document";
export const PDF = "PDF";
export const DOCX = "DOCX";
export const TXT = "TXT";
export const MB = "MB";
export const Course = "Course";
export const ModifyExistingCourse =
  "Do you want to modify the existing course?";
export const ViewRMDetails = "Request Details";
export const CreateAssessments = "Create Assessment";
export const EditAssessment = "Edit Assessment";
export const AssessmentType = "Assessment Type";
export const MappedQuestionBank = "Mapped Question Bank";
export const MappedModule = "Mapped Module";
export const QuestionBank = "Question Bank";
export const ModuleDetails = "Module Details";
export const QuestionBankName = "Question Bank Name";
export const Tag = "Tag";
export const PleaseAddTag = "Please add Tags";
export const PleaseAddModule = "Please add minimum one Module";
export const PleaseAddQuestion = "Please add minimum one Question";
export const IsSelfAssessment = "Is this a Self Assessment?";
export const SelfAssessment = "Self Assessment";
export const ModuleName = "Module Name";
export const OverallCount = "Overall Question Count";
export const AssessmentName = "Assessment Name";
export const EstimatedTime = "Estimated Time";
export const AssessmentDurationInMinutes = "Assessment Duration (in minutes)";
export const Policy = "Policy";
export const Weightage = "Weightage";
export const Questions = "Questions";
export const RequestType = "Request Type";
export const TrainerBondDetails = "Trainer & Bond Details";
export const SomethingWentWrong = "Something went wrong";
export const FutureDate = "Future date";
export const SelfEnrollment = "Do you want to Self Enrollment";
export const LearnerDocument = "Learner Document";
export const TrainerDocument = "Trainer Document";
export const FileSizeNote =
  "Note: Supported file formats are .pdf .docx .txt .pptx (<10MB)";
export const FileSizeError = "File size should not exceed 10MB";
export const zipFileSizeNote = "Note: Supported file formats are .zip";
export const PdfFileSizeNote = "Note: Supported file formats are .pdf";
export const QuestionFileSizeNote =
  "Note: Supported file formats are .pdf, .docx, .mp4, .mp3, .png, .jpg, .jpeg";
export const Completed = "Completed";
export const Internal = "internal";
export const SkillType = "Skill Type";
export const KnowledgeAssessmentType = "Knowledge Assessment Type";
export const Knowledge = "Knowledge";
export const Skill = "Skill";
export const SkillAssessment = "Skill Assessment";
export const PrePostAssessment = "Pre-Post Assessment";
export const NoOfModules = "No of Modules";
export const NoOfAttempt = "No of Attempt";
export const FieldAssessment = "Field Assessment";
export const OJTAssessment = "OJT Assessment";
export const RM_PROFILE_TEXT = "Reporting Manager";
export const TM_PROFILE_TEXT = "Training Manager";
export const TRAINER_PROFILE_TEXT = "Trainer";
export const SME_PROFILE_TEXT = "SME";
export const UPLOAD_SCORM_FILE = "Upload Scorm File";
export const PleaseDeleteTheUploadedScorm =
  "Please delete the uploaded SCORM file to add new SCORM file";
export const SkillTypeError = "Select Skill type";
export const ScormToUpload = "Please Add Scorm document to upload";
export const AddTrainerDocument = "Please add Trainer document to upload";
export const AddLearnerDocument = "Please add Learner document to upload";
export const AddDocument = "Please Add document to upload";
export const Scorm = "Scorm";
export const InDraft = "In Draft";
export const YetToStart = "Yet to Start";
export const Locked = "Locked";
export const Inactive = "Inactive";
export const DeleteLessonPlan = "Are you sure you want to delete lesson plan?";
export const DeleteUserMessage = "Are you sure you want to delete user?";
export const DeleteUserHeader = "Delete user";
export const DeleteCourse = "Are you sure you want to delete course?";
export const DeleteModuleFromQB = "Are you sure you want to delete Module?";
export const DeleteQuestionFromModule =
  "Are you sure you want to delete Question?";
export const DeleteQB = "Are you sure you want to delete Question Bank?";
export const DeleteAssessment = "Are you sure you want to delete assessment?";
export const AssessmentTypeChange =
  "Are you sure you want to change skill type? (Uploaded files will be overridden)";
export const Confirmation = "Confirmation";
export const Confirm = "Confirm";
export const DeleteLessonPlanHeader = "Delete Lesson plan";
export const DeleteModuleHeader = "Delete Module";
export const RenameModuleName = "Rename module name";
export const DeleteQuestionHeader = "Delete Question";
export const PleaseEnterQuestion = "Please enter Question";
export const ErrorInTheFile =
  "There are errors in the file data. Please Correct the data and upload again";
export const DeleteQuestionBankHeader = "Delete Question Bank";
export const DeleteAssessmentHeader = "Delete Assessment";
export const SkillTypeHeader = "Skill Type";
export const DeleteFileHeader = "Delete File";
export const InactiveCourseMessage =
  " Are you sure you want to set the status to inactive?";
export const DeleteMaterialFile = " Are you sure you want to delete the file";
export const SelectQuestionBank = "Please select Question Bank";
export const EnterOverallCount = "Please enter Overall Count";
export const EnterAssessmentName = "Please enter Assessment name";
export const SelectAssessmentType = "Please select type";
export const EnterEstimatedTime = "Please enter Assessment duration";
export const SelectPolicy = "Please select policy";
export const SelectLO = "Please select LO";
export const EnterUsername = "Enter Username";
export const EnterPassword = "Enter Password";
export const AdditionalInfoFile = "Additional_Information";
export const CourseStructureFile = "Course_Structure";
export const CompetencyFile = "Competency_Statement";
export const LessonPlanFile = "Lesson_Plan";
export const LOFile = "Learning_Outcome";
export const NoDataText = "No Data Found";
export const EnterAnswerChoice = "Enter answer choice";
export const AnswerChoice = "Answer choice";
export const Choice = "Choices";
export const AddResponses = "Add Responses";
export const DeleteOption = "Delete";
export const RenameOption = "Rename";
export const AddQuestions = "Add Questions";
export const SingleSelection = "Single Selection";
export const MultiSelection = "Multi Selection";
export const OpenField = "Open Field";
export const ListEmployee = "Employee List";
export const TimeRemaining = "Time Remaining";
export const MCQScore = "MCQ Score";
export const AttemptOn = "Attempt on: ";
export const FirstAttemptOn = "First attempt on: ";
export const YourScore = "Your Score";
export const ReattemptDays = "Re-attempt can be taken after ";
export const Reattempt = "Re-attempt";
export const Role = "Role";
export const SBU = "SBU";
export const Level = "Level";
export const OneTimeTraining = "One Time Training";
export const PleaseEnterPositiveError =
  "Please enter a positive number not exceeding 99";
export const SumOfClassRoomAndPracticalError =
  "The sum of classroom and practical must not exceed 100";
export const SumOfTheoryAndPracticalError =
  "The sum of Theory and practical must not exceed 100";
export const EnterComments = "Please enter comments to send";
export const EnterCorrectPhoneNumber = "Enter proper 10 digit Phone Number";
export const EnterCorrectSecondaryPhoneNumber =
  "Enter proper 10 digit correct Secondary Phone Number";
export const ValidEmailId = "Enter valid Email Id";
export const ValidExtension = "Enter valid Extension";
export const ValidSecondaryEmailId = "Enter valid Secondary Email Id";
export const CurriculumDetails = "Curriculum Details";
export const CurriculumName = "Curriculum Name";
export const Courses = "Courses";
export const MinimumAttendanceRequired =
  "Minimum attendance should not exceed 100";
export const MaximumAttendanceRequired =
  "Maximum attendance should not exceed 500";
export const FrequencyRequired = "Frequency is required";
export const FrequencyGreaterThanZero = "Frequency should be more than 0";
export const Frequency = "Frequency";
export const RecurseEvery = "Recur every";
export const End = "End";
export const SelectFrequency = "Select the Frequency";
export const MaximumOverallQuestions = "The value must be between 1 and 250";
export const MaximumMinutesForAssessment =
  "The value must be between 1 and 500 minutes.";
export const SME = "SME";
export const IsRecurringTraining = "Is this a Recurring Training?";
export const OthersReferenceRequired = "Other References is required";
export const OthersPrerequisiteIsRequired = "Other Prerequisite is required";
export const ResourceRequired = "Resource is required";
export const CourseNameRequired = "Course name is required";
export const CourseTypeRequired = "Course type is required";
export const CourseOwnerNeeded = "At least one Course owner must be assigned";
export const ObjectiveRequired = "Objective is required";
export const SuggestedHourRequired = "Suggested Hours is required";
export const SuggestedMinutesRequired = "Suggested Minutes is required";
export const CurriculumRequired = "At least one curriculum must be selected.";
export const AllCurriculumRequired = "All the curriculum must be selected.";
export const FillAllTheFields = "Please Fill all the fields.";
export const ExpertiseLevelRequired =
  "At least one expertise level must be selected.";
export const AllExpertiseLevelRequired =
  "All the expertise level must be selected.";
export const BondRupeesRequired = "Bond Rupees is required.";
export const BondYearsRequired = "Bond Years is required.";
export const FutureDateRequired = "Future Date is required.";
export const SelfEnrollmentIsRequired = "Self Enrollment is required.";
export const Module = "Module";
export const Question = "Question";
export const ClickHereToAddReferenceFile = "Click here to add a reference file";
export const MapCourse = "Map Course";
export const RemedialActions = "Remedial Action";
export const UploadFile = "Upload File";
export const UploadedSuccessfully = "Uploaded Successfully";
export const PleaseUploadFile = "Please Upload a File";
export const UseThisTemplate = "Use This Template";
export const Download = "Download";
export const MapCourseError = "Please Map the courses";
export const BulkUploadNote = `
  Note: Please adhere to the following guidelines when uploading file:<br />
  - Supported file type: .csv<br />
  - Maximum file size: 10MB<br />
  - Do not delete or modify the header or field names in the template file.<br />
  - Ensure that all required fields are filled in before uploading.<br />
  - If you want to upload the media URL later for the question, add the key "WILL_BE_UPLOADED".
`;
export const Draft = "draft";
export const CorrectResponse = "Correct Answer";
export const Manual = "manual";
export const Auto = "auto";
export const QuestionsTab = "questions";
export const AddModule = "Add Module";
export const ViewAll = "View All";
export const ReferenceImage = "Do you want to add reference image?";
export const DummyQuestion = "Test the module question";
export const QuestionFillData =
  "Please fill in all fields and ensure at least one option is marked as correct.";
export const QuestionFillDataForMultiple =
  "Please fill in all fields and ensure at least two option is marked as correct.";
export const PassingPercentage = "Passing %";
export const Enable = "enable";
export const ReAttempt = "Re-attempt";
export const ExportPDF = "Export PDF";
export const Count = "count";
export const CoolingPeriod = "Cooling period";
export const PolicyName = "Policy Name";
export const PolicyNameIsRequired = "Policy Name is required.";
export const PolicyPassingPercentageIsRequired =
  "Policy passing percentage is required.";
export const PolicyMinRequired = "Min  Score is required.";
export const PolicyMaxRequired = "Max Score is required.";
export const MinScore = "Min  Score";
export const MaxScore = "Max Score";
export const PolicyNumberOfAttemptsRequired =
  "Policy number of attempts is required.";
export const PolicyCoolingRequired = "Policy cooling period is required.";
export const AddReAttemptPolicy = "Add Re-Attempt Policy";
export const EnterScoreReAttempt = "Enter Score re-attempt";
export const InDays = "in days";
export const TotalPolicyScoreCannotExceedError =
  "Total score cannot exceed 100";
export const NoOfAssessment = "No Of Assessment";
export const NoOfCourse = "No Of Course";
export const Grade = "Grade";
export const Grades = "Grades";
export const Duration = "Duration";
export const EffectiveFrom = "Effective From";
export const ReAttemptPolicy = "Re-attempt Policy";
export const SelfRatingAssessment = "Self Rating Assessment";
export const DurationType = "Duration type";
export const EffectiveFromDate = "Effective from Date";
export const MapSGA = "Map SGA";
export const AuditLogs = "Audit Logs";
export const Type = "Type";
export const CreatedOn = "Created On ";
export const ModificationRequest = "Modification Request";
export const Time = "Time";
export const TargetLevel = "Target Level";
export const CurrentLevel = "Current Level";
export const WriteHere = "Write here";
export const Days = "days";
export const YouPassed = "Congratulations!! You Passed 👍";
export const PassDescription =
  " This is an independent assessment, which is not related to any curriculum";
export const YouFailed = "Better luck next time, You Failed";
export const RemedialActionHeader = "Remedial Action";
export const RemedialActionDescription =
  "To participate in the test you have to go through following courses";
export const Location = "Location";
export const Mail = "Mail";
export const Contact = "Contact";
export const CWSAndCatRecId = "CWS & Cat rec ID";
export const SUBNo = "SUB No";
export const RMName = "RM Name";
export const RMEmail = "RM Email";
export const RMContactNumber = "RM Contact Number";
export const DeskNo = "Desk No";
export const ActivityLogs = "Activity logs";
export const DownloadCertificates = "Download Certificates";
export const CertifiedOn = "Certified On";
export const ExpiredOn = "Expired On";
export const RenewCertification = "Renew certification";
export const ClearAll = "ClearAll";
export const By = "by";
export const ContentTopicNotePlaceholder =
  "What is to be trained, eg. EMCP Controls, MWL, HEX, UPS.";
export const ContentSummaryNotePlaceholder =
  "Summarize what the training is about and the purpose of the training.";
export const WhoIsExpectedInTraining =
  "Who is expected to receive this training?";
export const HowLongShouldTrainingBe = "How long should the training be?";
export const TaskForTrainingProgram =
  "Provide 3-6 tasks or concepts that learners should receive from the Training program.";
export const PleaseFillWeightage = "Please fill out all the weightage";
export const DomainRequired = "Domain is required";
export const CompetencyRequired = "Competency is required";
export const LevelRequired = "Level is required";
export const FacilitatorInformationRequired =
  "Facilitator Information is required";
export const SelectLearningOutcome = "Please select Learning Outcome";
export const AddAnyOfAssessments = "Please add any one of the assessments";
export const AddAssessment = "Add Assessment";
export const ModifyAssessment = "Modify Assessment";
export const SubmitContentHeader = "Submit Content";
export const SubmitContentConfirmation =
  "Are you sure you want to submit content to SME/TM approval?";
export const AccomplishmentToolTip =
  "What do the learners need to accomplish after training?";
export const ExpectedTargetAudienceToolTip =
  "How many people are expected to receive this training?";
export const CompletedOn = "Completed On";
export const ScheduledOn = "Scheduled On";
export const CreateSchedule = "Create Schedule";
export const RequestForModification = "Request Modification Access";
export const FY = "FY";
export const EnterTags = "Enter tags here";
export const QuestionsWronglyAnswered = "Questions wrongly answered";
export const LearningAssessment = "Learning Assessment";
export const YourResponsesSubmitted = "Your Responses submitted for review";
export const ExamResponseText =
  " Your responses are submitted for TM Validation. Once the TM updates the score, we will notify you.";
export const TotalDuration = "Total duration";
export const ILT = "ILT";
export const VILT = "VILT";
export const Webinar = "Webinar";
export const WBT = "WBT";
export const OverallStatistics = "Overall statistics";
export const DownloadMaterialFile = "Download Material File";
export const RecurringTrainingToolTip =
  "Is this a reoccurring training or one time training? (Use beside toggle to switch between reoccurring or not)";
export const Milestone = "Milestone";
export const PleaseAddAssessmentName = "Please add an assessment name.";
export const MaximumCharacters = "Maximum 100 characters.";

export const AddValidNumberInRange10 =
  "Please enter a valid number between 1 and 10.";
export const AddValidNumberInRange250 =
  "Please enter a valid number between 1 and 250.";
export const AddValidNumberInRange99 =
  "Please enter a valid number between 1 and 99.";
export const AddValidNumberInRange100 =
  "Please enter a valid number between 1 and 100.";
export const AddValidNumberInRange30 =
  "Please enter a valid number between 1 and 30.";
export const AddValidNumberInRange500 =
  "Please enter a valid number between 1 and 500.";
export const PleaseSelectQuestionBank = "Please select a question bank.";
export const PleaseSelectPolicy = "Please select a policy.";
export const PleaseSelectSkillType = "Please select a skill type.";
export const IsSpecialCurriculum = "Is Special Curriculum";
export const Special = "Special";
export const CurriculumNameIsRequired = "Curriculum Name is required.";
export const RoleIsRequired = "Role is required.";
export const CurriculumIsRequired = "Curriculum is required.";
export const DomainIsRequired = "Domain is required.";
export const FutureDateIsRequired = "Future date is required.";
export const CourseIsRequired = "Course is required.";
export const GradeIsRequired = "Grade is required.";
export const DurationTypeIsRequired = "Duration type is required.";
export const DurationIsRequired = "Duration is required.";
export const EffectiveFromDateIsRequired = "Effective from Date is required.";
export const ReAttemptPolicyIsRequired = "Re-attempt Policy is required.";
export const AddSecondaryTrainer = "Select Secondary Trainer";
export const DurationCount = "Duration Count";
export const Source = "Source";
export const UploadOrDragFiles = "Drop a file here or Click to upload";
export const MapQb = "Map QB";
export const MapModule = "Map Module";
export const PleaseMapModule = "Please Map Module";
export const ShowWronglyAnsweredQuestion = "Show Wrongly Answered Question?";
export const DoYouWantToSetThisAsRecurringAssessment =
  "Do you want to setup this as recurring assessment?";
export const TermAndCondition = "Term & Condition";
export const Close = "Close";
export const ConfirmSchedule = "Confirm Schedule";
export const NameValidationMessage = "Please enter the proper name";
export const AssessmentTypeValidationMessage =
  "Please select an assessment type.";
export const OverallQuestionCountValidationMessage =
  "Overall question count must be a number between 1 and 200.";
export const AlreadyReachedLimitForTheDay = "Already reached limit for the day";
export const EstimatedTimeValidationMessage =
  "Estimated time must be a number between 1 and 1440 minutes.";
export const PolicyIdValidationMessage = "Please select a policy.";
export const FrequencyCountValidationMessage =
  "Frequency count must be a number between 1 and 10.";
export const OccurrenceValidationMessage =
  "Occurrence must be a number between 1 and 10.";
export const TermAndConditionValidationMessage =
  "Terms and conditions cannot be empty.";
export const START_DATE_REQUIRED = "Start date is required.";
export const END_DATE_REQUIRED = "End date is required.";
export const START_DATE_GREATER_ERROR =
  "Start date cannot be more than or equal to end date.";
export const PleaseAddSomeUsersToSchedule = "Please add some users to schedule";
export const Editor = "Editor";
export const UserDetails = "User Details";
export const Status = "Status";
export const Score = "Score";
export const AddUsers = "+ Add Users";
export const NotStarted = "Not Started";
export const ReviewPending = "Review Pending";
export const Play = "Play";
export const IsReAttemptPolicy = "Is this a re-attempt policy?";
export const CreateReAttemptPolicyPublishConfirmation =
  "Are you sure you want to publish this Re-attempt Policy?";
export const ModifyReAttemptPolicyPublishConfirmation =
  "Are you sure you want to modify the published Re-attempt Policy?";
export const DeleteReAttemptPolicy =
  "Are you sure you want to delete Re-attempt Policy?";
export const DeleteReAttemptPolicyHeader = "Delete Re-Attempt Policy";
export const VersionControlText = "Version Control";
export const CurriculumScheduleConfirmation =
  "Are you sure you want to schedule this for publishing?";
export const CurriculumPublishConfirmation =
  "This action will impact all users currently assigned to this curriculum. Are you sure you want to proceed with these changes?";
export const CurriculumDraftConfirmation =
  "Are you sure you want to draft this now?";
export const Scheduled = "Scheduled";
export const Archive = "Archive";
export const DraftStatus = "Draft";
export const Note = "Note";
export const NoOfCoursesMappedForSelectedYear =
  "course have been mapped for the selected year";
export const AssignYear = "Assign a Year";
export const SelectYear = "Select Year";
export const Year = "Year";
export const MapAssessment = "Map Assessment";
export const Start = "Start";
export const Modules = "Modules";
export const Resume = "Resume";
export const RequestExtension = "Request Extension";
export const RequestExtensionRequest = "Request Extension Request";
export const NumberOfDays = "No of days";
export const NumberOfDaysRequired = "No of days is required";
export const AddReasonRequired = "Add reason is required";
export const StartNote = "Start button will be enabled on start date";
export const SubmittedOn = "Submitted On";
export const FinalScore = "Final Score";
export const Retake = "Retake";
export const EstimatedTimer = "Estimated Timer";
export const TotalQuestion = "Total Question";
export const YourReason = "Your Reason";
export const ReviewerReason = "Reviewer Reason";
export const TMReason = "TM Reason";
export const LearnerReason = "Learner Reason";
export const Competent = "Competent";
export const NotCompetent = "Not Competent";
export const PartialCompetent = "Partial Competent";
export const LearnerResponses = "Learner Responses";
export const RMResponses = "RM Responses";
export const PleaseAnswerAllQuestions =
  "Please answer all the questions before submit";
export const PleaseReviewAll = "Please review all the questions";
export const SelfAssessmentSummary = "Self Assessment Summary";
export const Tags = "Tags";
export const ModuleAddedSuccessfully = "Module added successfully";
export const QuestionBankCreatedSuccessful =
  "Question bank created successfully";
export const QuestionBankUpdatedSuccessful =
  "Question bank updated successfully";
export const ILPYear = "ILP Year";
export const SearchForUsersOrGroups = "Search for Users / groups";
export const Or = "Or";
export const Owner = "Owner";
export const Date = "Date:";
export const VersionText = "Version";
export const Current = "Current";
export const SGASubmitAlert = "Are you sure you want to modify this SGA?";
export const Answered = "Answered";
export const InProgress = "In progress";
export const Previous = "Previous";
export const Participants = "Participants";
export const LiteratureReference = "Literature Reference";
export const AreYouSureDeleteSchedule =
  "Are you sure you want to delete schedule?";
export const DeleteSchedule = "Delete Schedule";
export const AnswerGiven = "Answer Given";
export const Correct = "Correct";
export const Wrong = "Wrong";
export const ComingSoon = "Coming Soon";
export const AddProperData = "Add proper data";
export const SubmitAssessmentAlert = "Are you sure you want to submit?";
export const PleaseEnterValidEmailAndPassword =
  "Please Enter valid email or password...!";
export const AssigneesUpdated = "Assignees updated";
export const Copied = "Copied!";
export const SGA = "SGA";
export const Total = "Total";
export const Pending = "Pending";
export const Approved = "approved";
export const PendingForReview = "Pending for Review";
export const Rejected = "rejected";
export const NotYetStarted = "Not Yet Started";
export const PleaseAddReason = "Please add reason";
export const ConfirmScheduleHeader = "Schedule assessment";
export const ConfirmScheduleBody =
  "Are you sure you want to schedule this assessment?";
export const ExtensionRequestLimit = "Days cannot be more that 30 days";
export const ExtensionsAlreadyRaised = "Extension request already raised.";
export const DownloadCertificate = "Download Certificate";
export const Mins = " Mins";
export const AssessmentTypeRequired = "Assessment Type is required";
export const AssessmentRequired = "Assessment is required";
export const StartDateRequired = "Start Date is required";
export const EndDateRequired = "End Date is required";
export const ApproveSkillFile = "Are you sure you want to approve?";
export const RejectSkillFile = "Are you sure you want to decline?";
export const ApproveFile = "Approve";
export const DeclineFile = "Decline";
export const PleaseSelectCourseType = "Please select Course type";
export const PleaseSelectCourse = "Please select Course";
export const PleaseSelectYear = "Please select year";
export const ModifyYear = "Modify Year";
export const ApproveYear = "Approve Year";
export const DeclineYear = "Decline Year";
export const ApproveYearBody = "Are you sure you want to approve request";
export const DeclineYearBody = "Are you sure you want to decline request";
export const CourseMandatoryLevel =
  "Course is mandatory for at least one of the level.";
