import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, H2, MUIIcons } from "../../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../../Api/constants";
import Divider from "../../../../../CommonElements/Divider";
import SliderModal from "../../../../../CommonElements/SliderModal";
import AssessmentTable from "../../../../../container/AssessmentTable/AssessmentTable";
import Comments from "../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import { getAssessment } from "../../../../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  addCommentToStages,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Back,
  Comment,
  EnterComments,
  Knowledge,
  Next,
  Skill,
} from "../../../../../utils/Constant";
import {
  handleAllFilesDownload,
  knowledgeHeader,
  showToast,
  skillHeader,
  sortCommentsByDate,
} from "../../../../../utils/helper/helper";

const Assessment: React.FC = () => {
  const location = useLocation();
  const { courseId } = location.state || "";
  const dispatch = useAppDispatch();
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [sliderModal, setSliderModal] = useState(false);
  const [iconComments, setIconComments] = useState(null);
  const [userMap, setUserMap] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [isComentRead, setIsComentRead] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const { courseContent } = useAppSelector(state => state.course);
  const { courseAssessments } = useAppSelector(state => state.assessment);
  const [knowledgeAssessments, setKnowledgeAssessments] = useState([]);
  const [skillAssessments, setSkillAssessments] = useState([]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    const knowledgeAssessments = [];
    const skillAssessments = [];
    const loMap = {};

    courseContent?.assessment_learning_outcome?.forEach((items: any) => {
      const assessmentId = items.assessment_id;
      if (loMap[assessmentId]) {
        loMap[assessmentId].push(items?.outcome);
      } else {
        loMap[assessmentId] = [items?.outcome];
      }
    });

    if (
      courseAssessments?.assessments?.knowledge &&
      courseContent?.assessment_learning_outcome
    ) {
      courseAssessments?.assessments?.knowledge?.forEach((item: any) => {
        knowledgeAssessments.push({
          course_assessment_id: item?.course_assessment_id,
          skillName: item?.name,
          lo: loMap[item?.course_assessment_id] || [],
          type: item?.type,
          questions: item?.overall_question_count,
          modules: item?.modules?.length,
          assessmentDuration: item?.estimated_time,
          actions: "",
        });
      });
    }

    if (
      courseAssessments?.assessments?.skill &&
      courseContent?.assessment_learning_outcome
    ) {
      courseAssessments?.assessments?.skill?.forEach((item: any) => {
        skillAssessments.push({
          course_assessment_id: item?.course_assessment_id,
          skillName: item?.name,
          lo: loMap[item?.course_assessment_id] || [],
          type: item?.type,
          learnerDocs:
            item?.skill_document?.ojt_skill?.length > 0
              ? item?.skill_document?.ojt_skill || []
              : item?.skill_document?.field_skill?.learner_url || [],
          trainerDocs:
            item?.skill_document?.ojt_skill?.length > 0
              ? []
              : item?.skill_document?.field_skill?.trainer_url || [],
          actions: "",
        });
      });
    }
    setSkillAssessments(skillAssessments);
    setKnowledgeAssessments(knowledgeAssessments);
  }, [courseAssessments, courseContent]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.assessment) {
      comment = sortCommentsByDate(courseContent.comment.assessment);
    }
    setCommentList(comment);
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "assessment"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
  }, [courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "assessment",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  useEffect(() => {
    dispatch(getAssessment({ id: courseContent?.id }));
  }, [dispatch, courseContent]);

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "assessment",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const onClickItem = async value => {
    if (value === "item3") {
      const docs = [];
      const data = { ...courseAssessments };
      if (
        data?.assessments?.skill[selectedAssessment]?.skill_document?.ojt_skill
      )
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.ojt_skill?.map((item: any) => {
          docs.push(item?.file_url);
        });

      if (
        data?.assessments?.skill[selectedAssessment]?.skill_document
          ?.field_skill
      ) {
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.field_skill?.learner_url?.map((item: any) => {
          docs.push(item?.file_url);
        });
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.field_skill?.trainer_url?.map((item: any) => {
          docs.push(item?.file_url);
        });
      }
      handleAllFilesDownload(docs);
    }
  };

  return (
    <div className="page-body">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card className="p-2">
        <CardBody>
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="d-flex gap-4">
              <div className="comment-icon-container">
                <MUIIcons
                  size={24}
                  onClick={sliderToggle}
                  className="primary-icon-color mt-2 pointer"
                  iconName="ChatBubbleOutlineOutlined"
                />
                {isComentRead != null && isComentRead && (
                  <span className="red-dot"></span>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="mt-4">
        <div>
          <AssessmentTable
            title={Knowledge}
            isEdit={false}
            data={knowledgeAssessments}
            headers={knowledgeHeader}
            onClickItem={onClickItem}
            setSelectedAssessment={setSelectedAssessment}
          />
        </div>
        <div>
          <AssessmentTable
            title={Skill}
            isEdit={false}
            data={skillAssessments}
            headers={skillHeader}
            onClickItem={onClickItem}
            setSelectedAssessment={setSelectedAssessment}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Row>
          <Col xs="auto">
            <Link
              to="/course-management/course-details/course-structure"
              state={{ courseId: courseId }}
            >
              <Btn outline className="alert-light-primary" color="primary">
                {Back}
              </Btn>
            </Link>
          </Col>
          <Col xs="auto">
            <Link
              to="/course-management/course-details/course-material"
              state={{ courseId: courseId }}
            >
              <Btn color="primary">{Next}</Btn>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Assessment;
