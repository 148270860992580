import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import Store from "./ReduxToolkit/Store";
import RouterData from "./Routes";

const App = () => {
  return (
    <>
      <Provider store={Store}>
        <RouterData />
        <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
