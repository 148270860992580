import { Card, CardBody } from "reactstrap";

import { H3 } from "../../AbstractElements";

const ComingSoon = () => {
  return (
    <div className="page-body">
      <Card>
        <CardBody>
          <H3 className="text-center mt-2">Coming Soon</H3>
        </CardBody>
      </Card>
    </div>
  );
};

export default ComingSoon;
