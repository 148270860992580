import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody } from "reactstrap";

import { H4, P } from "../../AbstractElements";

interface WronglyAnsweredCardProps {
  questionNumber: number;
  questionText: string;
}

const WronglyAnsweredCard: React.FC<WronglyAnsweredCardProps> = ({
  questionNumber,
  questionText,
}) => {
  return (
    <Card className="my-3">
      <CardBody className="m-2">
        <P>Question {questionNumber}</P>
        <H4>{questionText}</H4>
      </CardBody>
    </Card>
  );
};

WronglyAnsweredCard.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
};

export default WronglyAnsweredCard;
