import { useState } from "react";
import { Container } from "reactstrap";

import { Badges, Btn, H2, H4, MUIIcons, P } from "../../../AbstractElements";
import SimpleAccordion from "../../../CommonElements/Accordion";
import DataTables from "../../../CommonElements/DataTable";
import DetailHeader from "../../../CommonElements/DetailHeader";
import Divider from "../../../CommonElements/Divider";
import FixedFooter from "../../../CommonElements/FixedFooter";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import CommonModal from "../../../CommonElements/Modal";
import SliderModal from "../../../CommonElements/SliderModal";
import StepIndicator from "../../../CommonElements/StepIndicator";
import Comments from "../../../container/Comments";
import CourseDetails from "../../../container/CourseDetails";
import MaterialDetails from "../../../container/MaterialDetails";

const SamplePageContainer = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [count, setCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sliderModal, setSliderModal] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const sliderToggle = () => {
    setSliderModal(!sliderModal);
  };

  const tabs = [
    { id: "1", label: "New" },
    { id: "2", label: "Published" },
    { id: "3", label: "Pending" },
  ];

  const courseDetailData = [
    {
      details: [
        { assign: "primary Contact", name: "Name 1" },
        { assign: "Email", name: "name@gmail.com" },
        { assign: "Phone", name: "7632844888" },
      ],
    },
    {
      details: [
        {
          assign: "Dummy Text 1",
          name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        { assign: "Dummy Text 2", name: "Name 4" },
      ],
    },
  ];

  const progressNumberList = [
    {
      color: "primary",
      number: "2",
      className: "start-50 txt-light",
    },
    {
      color: "transparent",
      number: "3",
      className: "progress-btn start-100 txt-dark",
    },
  ];

  const accordionList = [
    {
      id: "1",
      accordionItemClass: "item-class",
      accordionHeaderClass: "header-class",
      courseTime: "",
      spanClass: "span-class",
      accordionHeading: "Accordion Heading 1",
      icon: true,
      bodyText: <div>Body Text 1</div>,
    },
  ];

  const materialData = [
    {
      documents: [
        "Document 1-1",
        "Document 1-2",
        "Document 1-3",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
        "Document 1-1",
      ],
      greeting: "Welcome to Course 1!",
    },
    {
      documents: ["Document 2-1", "Document 2-2"],
      greeting: "Welcome to Course 2!",
    },
  ];

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <>
      <Container fluid className="mb-5">
        <H4 className="mb-2">Filter Tabs</H4>
        <JustifyTabs
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />

        <H4 className="mb-2 mt-5">Normal Button</H4>
        <Btn color="primary" onClick={handleClick} className="mb-3">
          Click Me
        </Btn>

        <H4 className="mb-2 mt-5">Icon Button</H4>
        <Btn
          icon={<MUIIcons iconName="AddCircleOutlineOutlined" size={16} />}
          color="primary"
          onClick={handleClick}
        >
          Click Me
        </Btn>

        <H4 className="mb-2 mt-5">Outline Button</H4>
        <Btn outline color="primary" onClick={handleClick} className="mb-3">
          Click Me
        </Btn>

        <H4 className="mb-2 mt-5">Badge</H4>
        <Badges color="primary" className="mr-2">
          Completed
        </Badges>

        <H4 className="mb-2 mt-5">Detail Header</H4>
        <DetailHeader assign={"Customer Name"} name={"Customer Value"} />

        <H4 className="mb-2 mt-5">Course Details</H4>
        <CourseDetails
          level={2}
          data={courseDetailData}
          headerText="Basic Info"
        />

        <H4 className="mb-2 mt-5">Step Indicator</H4>
        <StepIndicator progressNumberList={progressNumberList} />

        <H4 className="mb-2 mt-5">Modal</H4>
        <Btn color="primary" onClick={toggleModal} className="mb-3">
          Open Modal
        </Btn>
        <CommonModal
          backdrop="static"
          size="lg"
          isOpen={isModalOpen}
          toggle={toggleModal}
          title="Modal Title"
          showFooter={true}
          primaryBtnText="Save Changes"
          secondaryBtnText="Close"
          onPrimaryBtnClick={() => {
            toggleModal();
          }}
          onSecondaryBtnClick={() => {
            toggleModal();
          }}
        >
          <P>Modal Body Content</P>
        </CommonModal>

        <H4 className="mb-2 mt-5">Icon Header</H4>
        {/* <IconHeader
          assign="status"
          name={<ImageList data={imageData}></ImageList>}
        /> */}
        {/* 
        <H4 className="mb-2 mt-5">Basic Course Details</H4>
        <BasicCourseDetails
          imageData={imageData}
          statusBadge={<Badges>Completed</Badges>}
          header={courseData.header}
          rm={
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
          }
        /> */}

        <H4 className="mb-2 mt-5">Accordion</H4>
        <SimpleAccordion accordionList={accordionList} />

        <H4 className="mb-2 mt-5">Table</H4>
        <DataTables />

        <H4 className="mb-2 mt-5">Materials</H4>
        <MaterialDetails title="Materials" data={materialData} />

        {/* <H4 className="mb-2 mt-5">Multiselect Dropdown</H4>
        <MultiSelectDropdown
          options={multiSelectOption}
          placeholder={"Please select the Teams"}
        /> */}

        <H4 className="mb-2 mt-5">React Slider Modal</H4>
        <Btn onClick={sliderToggle}>Open Slider Modal</Btn>
        <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
          <H2>Comment</H2>
          <Divider />
          <Comments />
        </SliderModal>

        <H4 className="mb-2 mt-5">Fixed Footer</H4>
        <FixedFooter>
          <Btn color="primary">Send</Btn>
        </FixedFooter>
      </Container>
    </>
  );
};

export default SamplePageContainer;
