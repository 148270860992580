import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CommonModal from "../../CommonElements/Modal";
import AssessmentModuleList from "../../container/AssessmentModuleList";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  deleteReAttemptPolicy,
  getReAttemptPolicyList,
} from "../../ReduxToolkit/Reducers/PolicySilce";
import {
  AddReAttemptPolicy,
  Confirm,
  CoolingPeriod,
  Count,
  DeleteReAttemptPolicy,
  DeleteReAttemptPolicyHeader,
  Enable,
  Name,
  PassingPercentage,
  ReAttempt,
} from "../../utils/Constant";

const PolicyList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { policyList } = useAppSelector(state => state.policy);
  const [policyTabularData, setPolicyTabularData] = useState([]);
  const [policyIdToBeDeleted, setPolicyIdToBeDeleted] = useState(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);

  useEffect(() => {
    fetchPolicy();
  }, []);

  useEffect(() => {
    if (policyList?.policy?.length > 0) {
      const constructColumn = (assign: string, name: string, key: number) => ({
        assign,
        name,
        key,
      });

      const constructedData = policyList.policy.map(policy => {
        const minScore = policy.min_score ?? 0;
        const maxScore = policy.max_score ?? 0;

        return {
          columns: [
            constructColumn(Name, policy.name, policy.id),
            constructColumn(
              PassingPercentage,
              policy.pass_percentage != null
                ? policy.pass_percentage.toString()
                : "-",
              policy.id
            ),
            constructColumn(
              `${ReAttempt} ${Enable}`,
              policy.is_reattempt ? "Yes" : "No",
              policy.id
            ),
            constructColumn(
              `${ReAttempt} ${Count}`,
              policy.no_of_attempt != null
                ? policy.no_of_attempt.toString()
                : "-",
              policy.id
            ),
            constructColumn(ReAttempt, `${minScore}-${maxScore}`, policy.id),
            constructColumn(
              CoolingPeriod,
              policy.cooling_period != null
                ? policy.cooling_period.toString()
                : "-",
              policy.id
            ),
          ],
        };
      });

      setPolicyTabularData(constructedData);
    }
  }, [policyList]);

  const fetchPolicy = async () => {
    dispatch(getReAttemptPolicyList());
  };

  const deletePolicy = (key: number) => {
    if (policyIdToBeDeleted) {
      dispatch(deleteReAttemptPolicy({ id: key })).then(() => {
        setPolicyIdToBeDeleted(null);
        fetchPolicy();
      });
    }
  };

  const editPolicy = (key: number) => {
    const policyToBeEdited = policyList.policy.find(
      policy => policy.id === key
    );
    navigate("/master-data/add-policy", {
      replace: false,
      state: { policyDetail: policyToBeEdited, edit: true },
    });
  };

  const toggleDeleteConfirmModal = () => {
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);
  };

  const createNewPolicy = () => {
    navigate("/master-data/add-policy", {
      replace: false,
      state: { policyDetail: {}, edit: false },
    });
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteReAttemptPolicyHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={() => {
          deletePolicy(policyIdToBeDeleted);
          toggleDeleteConfirmModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteReAttemptPolicy}
      </CommonModal>
      {policyTabularData && policyTabularData.length > 0 && (
        <AssessmentModuleList
          heading={AddReAttemptPolicy}
          onEdit={key => {
            editPolicy(key);
          }}
          onDelete={key => {
            setPolicyIdToBeDeleted(key);
            toggleDeleteConfirmModal();
          }}
          onAddNew={() => {
            createNewPolicy();
          }}
          isEdit={true}
          isDelete={true}
          isAddNew={true}
          headerRow={policyTabularData[0]?.columns}
          dataRows={policyTabularData.slice(1)}
        />
      )}
    </div>
  );
};

export default PolicyList;
