import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

import "./style.scss";
import { EnterTags } from "../../utils/Constant";

interface TagInputProps {
  placeholder?: string;
  initialTags?: string[];
  onTagsChange?: (tags: string[]) => void;
}

const TagInput: React.FC<TagInputProps> = ({
  placeholder = EnterTags,
  initialTags = [],
  onTagsChange,
}) => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.toLowerCase());
  };

  const addTag = () => {
    if (inputValue.trim()) {
      const newTags = [...tags, inputValue.trim()];
      setTags(newTags);
      setInputValue(""); // Clear the input
      if (onTagsChange) {
        onTagsChange(newTags);
      }
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      addTag();
    } else if (e.key === "Backspace" && !inputValue && tags.length) {
      const newTags = tags.slice(0, -1);
      setTags(newTags);
      if (onTagsChange) {
        onTagsChange(newTags);
      }
    }
  };

  const handleInputBlur = () => {
    addTag(); // Add tag on blur as well
  };

  const handleTagRemove = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    if (onTagsChange) {
      onTagsChange(newTags);
    }
  };

  return (
    <div className="tag-input-container">
      {tags.map((tag, index) => (
        <div key={index} className="tag">
          <span>{tag}</span>
          <span onClick={() => handleTagRemove(index)} className="tag-remove">
            &times;
          </span>
        </div>
      ))}
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onBlur={handleInputBlur}
        placeholder={placeholder}
        className="tag-input"
        maxLength={100}
      />
    </div>
  );
};

TagInput.propTypes = {
  placeholder: PropTypes.string,
  initialTags: PropTypes.arrayOf(PropTypes.string),
  onTagsChange: PropTypes.func,
};

export default TagInput;
