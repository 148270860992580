import { useState } from "react";
import { Button } from "reactstrap";

import { Btn, P, Progressbar } from "../../../AbstractElements";
import SimpleAccordion from "../../../CommonElements/Accordion";
import AnswerStatusIndicator from "../../../CommonElements/AnswerStatusIndicator";
import AssesmentReviewCard from "../../../CommonElements/AssesmentReviewCard";
import BorderTabs from "../../../CommonElements/BorderTabs";
import ChooseOptions from "../../../CommonElements/ChooseOptions";
import CourseSummary from "../../../CommonElements/CourseSummaryCard";
import DateRangeDisplay from "../../../CommonElements/DateRangeDisplay";
import FAB from "../../../CommonElements/FAB";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import MapAssessmentModal from "../../../CommonElements/MapAssessmentModal";
import SelectYearModal from "../../../CommonElements/SelectYearModal";
import StatusDisplay from "../../../CommonElements/StatusDisplay";
import TagInput from "../../../CommonElements/TagInput";
import TargetLevelCard from "../../../CommonElements/TargetLevelCard";
import UserCardDetails from "../../../CommonElements/UserCardDetails";
import YearNavigator from "../../../CommonElements/YearNavigator";
import ActivityLogContainer from "../../../container/ActivityLogsContainer";
import AssessmentModuleList from "../../../container/AssessmentModuleList";
import AssessmentTimerCard from "../../../container/AssessmentTimer";
import CatlogAssessmentHeading from "../../../container/CatlogAssessmentHeading";
import CertificationListContainer from "../../../container/CertificationListContainer";
import EmployeeList from "../../../container/EmployeeList";
import AssessmentCard from "../../../container/GenericCard";
import MCQScoreContainer from "../../../container/MCQScoreContainer";
import ParticipantsDetails from "../../../container/ParticipantDetails";
import QuestionNavigator from "../../../container/QuestionNavigator";
import FailedScoreCard from "../../../container/ScoreCardContainer";
import UserProfileCard from "../../../container/UserProfileCard";
import WronglyAnsweredCard from "../../../container/WronglyAnswered";
import { dynamicImage } from "../../../Service";
import {
  AddResponses,
  Choice,
  Contact,
  CWSAndCatRecId,
  DeskNo,
  Location,
  Mail,
  RMContactNumber,
  RMEmail,
  RMName,
  SUBNo,
} from "../../../utils/Constant";
import { getRandomInteger } from "../../../utils/helper/helper";

import "./style.scss";

const tabs = [
  { title: "Home", icon: "", tabId: "1" },
  { title: "Inbox", icon: "", tabId: "2" },
  { title: "Contact", icon: "", tabId: "3" },
];

const Bucket2SamplePage = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].tabId);
  const [responses, setResponses] = useState<string[]>(["", ""]);
  const [tags, setTags] = useState<string[]>(["Banana", "Orange"]);
  const [data, setData] = useState([
    {
      name: "Learner responses",
      items: [
        { label: "Competent", selected: false },
        { label: "Not Competent", selected: false },
        { label: "Partial Competent", selected: false },
      ],
    },
    {
      name: "RM responses",
      items: [
        { label: "Competent", selected: false },
        { label: "Not Competent", selected: false },
        { label: "Partial Competent", selected: false },
      ],
    },
  ]);
  const [isMapCourseModalOpen, setIsMapCourseModalOpen] = useState(false);
  const [isSelectYearModalOpen, setIsSelectYearModalOpen] = useState(false);
  const [isMapAssessmentModalOpen, setIsMapAssessmentModalOpen] =
    useState(false);
  const [isMapCurriculumModalOpen, setIsMapCurriculumModalOpen] =
    useState(false);

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  const assessmentCardData = {
    header: "Introduction to TypeScript",
    footerDate: "July 18, 2024",
    footerBadge: "New",
  };

  const userCardWithDetail = {
    imagePath: dynamicImage("dashboard-2/user/2.png"),
    name: "John Doe",
    detail: "Software Engineer",
  };

  const userCardWithoutDetail = {
    imagePath: dynamicImage("dashboard-2/user/2.png"),
    name: "Jane Smith",
  };

  const moduleDetails = [
    { label: "Type", value: "Stand alone" },
    { label: "Question", value: "100" },
  ];

  const accordionList = [
    {
      id: "1",
      accordionItemClass: "item-class",
      accordionHeaderClass: "header-class",
      spanClass: "span-class",
      accordionHeading: "Module 1",
      icon: true,
      bodyText: <P>Hii</P>,
    },
  ];

  const handleAddResponse = () => {
    if (responses.length < 4) {
      setResponses([...responses, ""]);
    }
  };

  const handleDeleteResponse = (index: number) => {
    if (responses.length > 2) {
      const newResponses = responses.filter((_, i) => i !== index);
      setResponses(newResponses);
    }
  };

  const handleChangeResponse = (index: number, newValue: string) => {
    const newResponses = responses.map((response, i) =>
      i === index ? newValue : response
    );
    setResponses(newResponses);
  };

  const questions = [
    { number: 1, type: 1 },
    { number: 2, type: 2 },
    { number: 3, type: 3 },
    { number: 4, type: 1 },
    { number: 5, type: 2 },
    { number: 6, type: 3 },
    { number: 7, type: 1 },
    { number: 8, type: 2 },
    { number: 9, type: 3 },
    { number: 10, type: 1 },
    { number: 11, type: 2 },
    { number: 12, type: 3 },
  ];

  const sampleLogs = [
    {
      date: "2024-07-20",
      name: "Activity 1",
      data: [
        {
          sourceLabel: "Source",
          sourceNumber: "Durational",
          previousValue: "2",
          currentValue: "3",
        },
      ],
    },
    {
      date: "2024-07-19",
      name: "Activity 2",
      data: [
        {
          sourceLabel: "Source",
          sourceNumber: "Durational",
          previousValue: "4",
          currentValue: "5",
        },
      ],
    },
  ];

  const handleUpdate = (groupIndex: number, itemIndex: number) => {
    const newData = data.map((group, gIdx) => {
      if (gIdx === groupIndex) {
        return {
          ...group,
          items: group.items.map((item, iIdx) =>
            iIdx === itemIndex ? { ...item, selected: !item.selected } : item
          ),
        };
      }
      return group;
    });
    setData(newData);
  };
  const tabData = [
    { id: "1", label: "New", count: 3 },
    { id: "2", label: "Published", count: 5 },
    { id: "3", label: "Pending", count: 2 },
  ];

  const userCardDetail = [
    {
      imagePath: dynamicImage("dashboard-2/user/2.png"),
      name: "John Doe",
      detail: "Software Engineer",
      id: 1,
    },
    {
      imagePath: dynamicImage("dashboard-2/user/2.png"),
      name: "John Doe",
      detail: "Software Engineer",
      id: 2,
    },
    {
      imagePath: dynamicImage("dashboard-2/user/2.png"),
      name: "John Doe",
      detail: "Software Engineer",
      id: 3,
    },
    {
      imagePath: dynamicImage("dashboard-2/user/2.png"),
      name: "John Doe",
      detail: "Software Engineer",
      id: 4,
    },
    {
      imagePath: dynamicImage("dashboard-2/user/2.png"),
      name: "John Doe",
      detail: "Software Engineer",
      id: 5,
    },
    {
      imagePath: dynamicImage("dashboard-2/user/2.png"),
      name: "John Doe",
      detail: "Software Engineer",
      id: 2,
    },
  ];

  const dummyData = [
    {
      id: 1,
      icon: "graduation-cap",
      count: 20,
      label: "Total Course",
      infoData: [
        { courseName: "WBT", totalCount: 5 },
        { courseName: "VILT", totalCount: 5 },
        { courseName: "ILT", totalCount: 5 },
        { courseName: "Webinar", totalCount: 5 },
      ],
    },
    {
      id: 2,
      icon: "completed-tick",
      count: 12,
      label: "Completed",
      infoData: [
        { courseName: "WBT", totalCount: 3 },
        { courseName: "VILT", totalCount: 4 },
        { courseName: "ILT", totalCount: 3 },
        { courseName: "Webinar", totalCount: 3 },
      ],
    },
    {
      id: 3,
      icon: "upcoming-calendar",
      count: 2,
      label: "Upcoming",
      infoData: [
        { courseName: "WBT", totalCount: 2 },
        { courseName: "VILT", totalCount: 0 },
        { courseName: "ILT", totalCount: 0 },
        { courseName: "Webinar", totalCount: 0 },
      ],
    },
    {
      id: 3,
      icon: "scheduled-calendar",
      count: 2,
      label: "Scheduled",
      infoData: [
        { courseName: "WBT", totalCount: 0 },
        { courseName: "VILT", totalCount: 0 },
        { courseName: "ILT", totalCount: 1 },
        { courseName: "Webinar", totalCount: 1 },
      ],
    },
    {
      id: 4,
      icon: "overdue-calendar",
      count: 4,
      label: "OverDue",
      infoData: [
        { courseName: "WBT", totalCount: 0 },
        { courseName: "VILT", totalCount: 1 },
        { courseName: "ILT", totalCount: 1 },
        { courseName: "Webinar", totalCount: 1 },
      ],
    },
  ];

  const TargetLevelData = {
    targetLevel: "Advanced",
    currentLevel: "Foundational",
    percentageCompleted: 75,
  };

  const TargetLevelInfo = [
    {
      expertiseLevel: "Advanced",
      totalCount: 20,
      completedCount: 0,
      percentageCompleted: 0,
      completedDate: "",
      status: "",
    },
    {
      expertiseLevel: "Foundation",
      totalCount: 20,
      completedCount: 10,
      percentageCompleted: 50,
      completedDate: "2024-07-16T10:34:40Z",
      status: "",
    },
    {
      expertiseLevel: "Pre-Foundation",
      totalCount: 20,
      completedCount: 20,
      percentageCompleted: 100,
      completedDate: "2024-07-22T10:34:40Z",
      status: "",
    },
  ];

  const userProfileData = {
    userProfileUrl: dynamicImage("dashboard-2/user/10.png"),
    name: "Salvador Reynoso",
    designation: "Designer",
    percentage: 65,
    expertiseLevel: "Foundational",
    userDetails: [
      {
        icon: "LocationOnOutlined",
        title: Location,
        detail: "B69 Near School Demo Home",
      },
      {
        icon: "BadgeOutlined",
        title: CWSAndCatRecId,
        detail: "1234567",
      },
      {
        icon: "EmailOutlined",
        title: Mail,
        detail: "Manikandan@divum.in",
      },
      {
        icon: "PhoneOutlined",
        title: Contact,
        detail: "1234567890",
      },
      {
        icon: "PinOutlined",
        title: SUBNo,
        detail: "1234567",
      },
      {
        icon: "PersonOutline",
        title: RMName,
        detail: "Ramesh",
      },
      {
        icon: "EmailOutlined",
        title: RMEmail,
        detail: "Ramesh@divum.in",
      },
      {
        icon: "PhoneOutlined",
        title: RMContactNumber,
        detail: "9876654321",
      },
      {
        icon: "TableRestaurantOutlined",
        title: DeskNo,
        detail: "1234567890",
      },
    ],
    course: [
      { title: "ILP", count: 3 },
      { title: "Certificates", count: 3 },
      { title: "Assessment", count: 3 },
    ],
  };

  const handleTagsChange = (newTags: string[]) => {
    setTags(newTags);
    return newTags;
  };

  const mapCourseModalToggle = () => {
    setIsMapCourseModalOpen(!isMapCourseModalOpen);
  };

  const selectYearModalToggle = () => {
    setIsSelectYearModalOpen(!isSelectYearModalOpen);
  };

  const mapAssessmentModalToggle = () => {
    setIsMapAssessmentModalOpen(!isMapAssessmentModalOpen);
  };

  const mapCurriculumModalToggle = () => {
    setIsMapCurriculumModalOpen(!isMapCurriculumModalOpen);
  };

  return (
    <div className="page-body mb-5">
      <h1 className="mb-2">Border tabs</h1>
      <BorderTabs
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      />
      <P className="mb-2">With Details Profile</P>
      <UserCardDetails
        imagePath={userCardWithDetail.imagePath}
        name={userCardWithDetail.name}
        detail={userCardWithDetail.detail}
      />
      <P className="mt-4 mb-2">Without Details Profile</P>
      <UserCardDetails
        imagePath={userCardWithoutDetail.imagePath}
        name={userCardWithoutDetail.name}
      />
      <P className="mt-4 mb-2">All assessment type cards</P>
      <AssessmentCard
        header={assessmentCardData.header}
        footerDate={assessmentCardData.footerDate}
        footerBadge={assessmentCardData.footerBadge}
        userCardWithoutDetail={userCardWithoutDetail}
        moduleDetails={moduleDetails}
      />
      <SimpleAccordion accordionList={accordionList} showDropdown={true} />
      <div className="choose-option">
        <h1 className="title">{Choice}</h1>
        {responses.map((response, index) => (
          <ChooseOptions
            key={index}
            index={index}
            value={response}
            onChange={handleChangeResponse}
            onDelete={handleDeleteResponse}
          />
        ))}
        <Button
          color="link"
          className="add-response"
          onClick={handleAddResponse}
          disabled={responses.length >= 4}
        >
          {AddResponses}
        </Button>
      </div>
      <JustifyTabs tabs={tabData} activeTab="1" />
      <P className="mt-4 mb-2">Question Number choose</P>
      <P className="mt-4 mb-2">Saftey Assessment Heading</P>
      <CatlogAssessmentHeading
        heading="Saftey Assessment"
        badgeText="Extension request : 2"
        rows={[
          {
            columns: [
              {
                assign: "Assessment Type",
                name: "Competency",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Mapped Question Bank",
                name: "10",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Mapped Module",
                name: "10",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Overall Question Count",
                name: "10",
                key: getRandomInteger(1, 100),
              },
            ],
          },
          {
            columns: [
              {
                assign: "Eastimated Timer",
                name: "1H 20M",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Re-attempt Policy",
                name: "policy",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Score Required",
                name: "Yes",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Frequency",
                name: "10",
                key: getRandomInteger(1, 100),
              },
            ],
          },
          {
            columns: [
              { assign: "Occurence", name: "2", key: getRandomInteger(1, 100) },
            ],
          },
        ]}
      />
      <P className="mt-4 mb-2">Assessment Module List</P>
      <AssessmentModuleList
        heading="Module List"
        isEdit={false}
        isDelete={false}
        headerRow={[
          {
            assign: "Module Name",
            name: "Saftey",
            key: getRandomInteger(1, 100),
          },
          {
            assign: "No Of Question",
            name: "100",
            key: getRandomInteger(1, 100),
          },
          { assign: "Weightage", name: "20%", key: getRandomInteger(1, 100) },
        ]}
        dataRows={[
          {
            columns: [
              {
                assign: "Module Name",
                name: "Saftey",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Question",
                name: "100",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Weightage",
                name: "20%",
                key: getRandomInteger(1, 100),
              },
            ],
          },
          {
            columns: [
              {
                assign: "Module Name",
                name: "Saftey",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Question",
                name: "100",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Weightage",
                name: "20%",
                key: getRandomInteger(1, 100),
              },
            ],
          },
          {
            columns: [
              {
                assign: "Module Name",
                name: "Saftey",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Question",
                name: "100",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Weightage",
                name: "20%",
                key: getRandomInteger(1, 100),
              },
            ],
          },
        ]}
      />

      <P className="mt-4 mb-2">Tags</P>
      <TagInput initialTags={tags} onTagsChange={handleTagsChange} />
      <P className="mt-4 mb-2">Employee List</P>
      <EmployeeList userCardWithDetail={userCardDetail} />
      <P className="mt-4 mb-2">Status Display</P>
      <StatusDisplay label="Competent" number={"10"} />

      <P className="mt-4 mb-2">Participants details</P>
      <ParticipantsDetails sections={[]} title="Participants Details" />

      <P className="mt-4 mb-2">Progressbar</P>
      <Progressbar color="dark" value="25" style={{ height: "8px" }} />

      <P className="mt-4 mb-2">Answer Status Indicator</P>
      <div className="d-flex gap-5">
        <AnswerStatusIndicator color="primary" text="Competent" />
        <AnswerStatusIndicator color="dark" text="Not Competent" />
        <AnswerStatusIndicator color="white" text="Partially Competent" />
      </div>
      <P className="mt-4 mb-2">Question Navigator</P>
      <QuestionNavigator moduleName="Module 1" questions={questions} />
      <P className="mt-4 mb-2">MCQ Container</P>
      <MCQScoreContainer score="60%" mcqStatus="60/80" manualStatus="0/20" />
      <P className="mt-4 mb-2">SGA</P>
      <AssessmentModuleList
        heading="Technician"
        onAddNew={() => ""}
        isEdit={true}
        isDelete={false}
        isAddNew={false}
        headerRow={[
          {
            assign: "Level",
            name: "Foundational",
            key: getRandomInteger(1, 100),
          },
          {
            assign: "No Of Assessment",
            name: "100",
            key: getRandomInteger(1, 100),
          },
          { assign: "No Of Course", name: "3", key: getRandomInteger(1, 100) },
          { assign: "Grade", name: "A", key: getRandomInteger(1, 100) },
          { assign: "Duraion", name: "5", key: getRandomInteger(1, 100) },
          {
            assign: "Effective From",
            name: "30-07-2024",
            key: getRandomInteger(1, 100),
          },
          {
            assign: "Re-attemmpt Policy",
            name: "30-07-2024",
            key: getRandomInteger(1, 100),
          },
          {
            assign: "Re-attemmpt Policy",
            name: "30-07-2024",
            key: getRandomInteger(1, 100),
          },
        ]}
        dataRows={[
          {
            columns: [
              {
                assign: "Level",
                name: "Foundational",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Assessment",
                name: "100",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Course",
                name: "3",
                key: getRandomInteger(1, 100),
              },
              { assign: "Grade", name: "A", key: getRandomInteger(1, 100) },
              { assign: "Duraion", name: "5", key: getRandomInteger(1, 100) },
              {
                assign: "Effective From",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Re-attemmpt Policy",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Re-attemmpt Policy",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
            ],
          },
          {
            columns: [
              {
                assign: "Level",
                name: "Foundational",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Assessment",
                name: "100",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Course",
                name: "3",
                key: getRandomInteger(1, 100),
              },
              { assign: "Grade", name: "A", key: getRandomInteger(1, 100) },
              { assign: "Duraion", name: "5", key: getRandomInteger(1, 100) },
              {
                assign: "Effective From",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Re-attemmpt Policy",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Re-attemmpt Policy",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
            ],
          },
          {
            columns: [
              {
                assign: "Level",
                name: "Foundational",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Assessment",
                name: "100",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "No Of Course",
                name: "3",
                key: getRandomInteger(1, 100),
              },
              { assign: "Grade", name: "A", key: getRandomInteger(1, 100) },
              { assign: "Duraion", name: "5", key: getRandomInteger(1, 100) },
              {
                assign: "Effective From",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Re-attemmpt Policy",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
              {
                assign: "Re-attemmpt Policy",
                name: "30-07-2024",
                key: getRandomInteger(1, 100),
              },
            ],
          },
        ]}
      />
      <P className="mt-4 mb-2">Activity Logs</P>
      <ActivityLogContainer logs={sampleLogs} />

      <P className="mt-4 mb-2">Score Card</P>
      <FailedScoreCard
        score={60}
        message="Better luck next time, You Failed"
        testName="Catterpillar Mechanical Reasoning Test"
      />
      <P className="mt-4 mb-2">Time Remaining</P>
      <AssessmentTimerCard time={{ hours: 0, minutes: 0, seconds: 0 }} />
      <P className="mt-4 mb-2">Wrongly Answered</P>
      <WronglyAnsweredCard
        questionNumber={1}
        questionText="Which is best football club of all time?"
      />
      <P className="mt-4 mb-2">Assessment Review Card</P>
      <AssesmentReviewCard groups={data} onUpdate={handleUpdate} />

      <FAB
        onClick={handleAddResponse}
        title="Questions"
        buttonType="dark d-md-none d-lg-none"
      />
      <P className="mt-4 mb-2">Date Range Display</P>
      <DateRangeDisplay startDate="Aug 30" endDate="Sep 30" />
      <P className="mt-4 mb-2">Course Summary Card</P>
      <CourseSummary data={dummyData} />

      <p className="mt-4 mb-2">Year Navigator</p>
      <YearNavigator pastYears={5} futureYears={5} />

      <p className="mt-4 mb-2">Target Level Card</p>
      <TargetLevelCard cardData={TargetLevelData} infoData={TargetLevelInfo} />

      <P className="mt-4 mb-2">Certification List Container</P>
      <CertificationListContainer
        title={" Project Delivery Methods and Contracts management"}
        certifiedOn={"2022-07-16T10:34:40Z"}
        expiredOn={"2024-07-16T10:34:40Z"}
      />
      <CertificationListContainer
        title={" Project Delivery Methods and Contracts management"}
        certifiedOn={"2022-05-16T10:34:40Z"}
        expiredOn={""}
      />

      <p className="mt-4 mb-2">User Profile</p>
      <UserProfileCard userData={userProfileData} />
      <p className="mt-4 mb-2">Map Course Modal</p>
      <>
        <Btn color="primary" onClick={mapCourseModalToggle}>
          Map Course
        </Btn>
      </>

      <p className="mt-4 mb-2">Select Year Modal</p>
      <>
        <Btn color="primary" onClick={selectYearModalToggle}>
          Select Year
        </Btn>
        <SelectYearModal
          isOpen={isSelectYearModalOpen}
          toggle={selectYearModalToggle}
          userId={""}
          selectedCourse={undefined}
        />
      </>

      <p className="mt-4 mb-2">Map Assessment Modal</p>
      <>
        <Btn color="primary" onClick={mapAssessmentModalToggle}>
          Map Assessment
        </Btn>
        <MapAssessmentModal
          isOpen={isMapAssessmentModalOpen}
          toggle={mapAssessmentModalToggle}
        />
      </>

      <p className="mt-4 mb-2">Map Curriculum Modal</p>
      <>
        <Btn color="primary" onClick={mapCurriculumModalToggle}>
          Map Curriculum
        </Btn>
      </>
    </div>
  );
};

export default Bucket2SamplePage;
