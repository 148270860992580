import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { LI, SVG, UL } from "../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  handlePined,
  setToggleSidebar,
} from "../../ReduxToolkit/Reducers/LayoutSlice";
import {
  MenuItem,
  MenuListType,
  SidebarItemTypes,
} from "../../Types/Layout/SidebarType";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";

const Menulist: React.FC<MenuListType> = ({
  menu,
  setActiveMenu,
  activeMenu,
  level,
  className,
}) => {
  const isMobile = useIsMobile();
  const { toggleSidebar } = useAppSelector(state => state.layout);
  const { pinedMenu } = useAppSelector(state => state.layout);
  const { sidebarIconType } = useAppSelector(state => state.themeCustomizer);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const ActiveNavLinkUrl = (path?: string, active?: boolean) => {
    return location.pathname === path ? (active ? active : true) : "";
  };

  const shouldSetActive = ({ item }: SidebarItemTypes) => {
    let returnValue = false;
    if (item?.path === location.pathname) returnValue = true;
    if (!returnValue && item?.children) {
      item?.children.every(subItem => {
        returnValue = shouldSetActive({ item: subItem });
        return !returnValue;
      });
    }
    return returnValue;
  };
  const handleClick = (item: string) => {
    const temp = activeMenu;
    temp[level] = item !== temp[level] ? item : "";
    setActiveMenu([...temp]);
  };
  useEffect(() => {
    menu?.forEach((item: any) => {
      const gotValue = shouldSetActive({ item });
      if (gotValue) {
        const temp = [...activeMenu];
        temp[level] = item.title;
        setActiveMenu(temp);
      }
    });
  }, []);

  const getPath = (path: string) => {
    if (
      path === "/course-management" &&
      !hasPermissionToComponent("COURSE_MANAGEMENT.COURSE_REQUEST")
    ) {
      return "/course-management/course-list";
    } else if (path === "/course-management") {
      return "/course-management/course-request";
    } else if (path === "/assessments") {
      return "/assessments/assessments-list";
    }
    return path;
  };

  const handleItemClick = (item: MenuItem) => {
    if (isMobile && !item?.children) {
      dispatch(setToggleSidebar(!toggleSidebar));
    }
    handleClick(item.title);
  };

  return (
    <>
      {menu?.map((item, index) => (
        <LI
          key={index}
          className={`${level === 0 ? "sidebar-list" : ""} ${pinedMenu.includes(item.title || "") ? "pined" : ""}  
          ${
            (item.children
              ? item.children
                  .map(innerItem => ActiveNavLinkUrl(innerItem.path))
                  .includes(true)
              : ActiveNavLinkUrl(item.path)) || activeMenu[level] === item.title
              ? "active"
              : ""
          } `}
        >
          {level === 0 && (
            <i
              className="fa fa-thumb-tack text-black thumb-icon-margin"
              onClick={() => dispatch(handlePined(item.title))}
            ></i>
          )}
          <Link
            className={`${!className && level !== 2 ? "sidebar-link sidebar-title" : ""} 
            ${
              (item.children
                ? item.children
                    .map(innerItem => ActiveNavLinkUrl(innerItem.path))
                    .includes(true)
                : ActiveNavLinkUrl(item.path)) ||
              activeMenu[level] === item.title
                ? "active"
                : ""
            }`}
            to={getPath(item.path)}
            onClick={() => handleItemClick(item)}
          >
            {item.icon && (
              <SVG
                className={`${sidebarIconType}-icon`}
                iconId={`${sidebarIconType}-${item.icon}`}
              />
            )}
            <span className={item.lanClass && item.lanClass}>{item.title}</span>
            <div>
              {item.children &&
                (activeMenu[level] === item.title ? (
                  <div className="according-menu">
                    <i className="fa fa-angle-down text-dark" />
                  </div>
                ) : (
                  <div className="according-menu">
                    <i className="fa fa-angle-right text-white" />
                  </div>
                ))}
            </div>
          </Link>
          {item.children && (
            <UL
              className={`simple-list ${level !== 0 ? "nav-sub-childmenu submenu-content" : "sidebar-submenu "}`}
              style={{
                display: `${
                  (item.children
                    ? item.children
                        .map(innerItem => ActiveNavLinkUrl(innerItem.path))
                        .includes(true)
                    : ActiveNavLinkUrl(item.path)) ||
                  activeMenu[level] === item.title
                    ? "block"
                    : "none"
                }`,
              }}
            >
              <Menulist
                menu={item.children}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                level={level + 1}
                className="sidebar-submenu"
                handleMenuClick={handleItemClick}
              />
            </UL>
          )}
        </LI>
      ))}
    </>
  );
};

export default Menulist;
