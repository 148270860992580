import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GET,
  GET_SCORM_DATA,
  GET_SCORM_DATA_API,
  POST,
  SYNC_SCORM_DATA,
  SYNC_SCORM_DATA_API,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { ScormData, ScormUpsertObject } from "../../Api/entities/ScormEntity";
import { SomethingWentWrong } from "../../utils/Constant";

interface PolicyInterface {
  scormData: ScormData;
  loading: boolean;
  error: string | null;
  scormResponse: ScormUpsertObject;
  syncResposne: any;
}

const initialState: PolicyInterface = {
  loading: false,
  error: null,
  scormData: {
    "cmi.core._children": "",
    "cmi.core.student_id": "",
    "cmi.core.student_name": "",
    "cmi.core.lesson_location": "",
    "cmi.core.credit": "",
    "cmi.core.lesson_status": "",
    "cmi.core.entry": "",
    "cmi.core.score_children": "",
    "cmi.core.score.raw": "",
    "cmi.core.score.max": "",
    "cmi.core.score.min": "",
    "cmi.core.total_time": "",
    "cmi.core.lesson_mode": "",
    "cmi.core.exit": "",
    "cmi.core.session_time": "",
    "cmi.suspend_data": "",
    "cmi.launch_data": "",
    "cmi.comments": "",
    "cmi.comments_from_lms": "",
    "cmi.objectives._children": "",
    "cmi.objectives._count": "",
    "cmi.objectives.n.id": "",
    "cmi.objectives.n.score._children": "",
    "cmi.objectives.n.score.raw": "",
    "cmi.objectives.n.score.max": "",
    "cmi.objectives.n.score.min": "",
    "cmi.objectives.n.status": "",
    "cmi.student_data._children": "",
    "cmi.student_data.mastery_score": "",
    "cmi.student_data.max_time_allowed": "",
    "cmi.student_data.time_limit_action": "",
    "cmi.student_preference._children": "",
    "cmi.student_preference.audio": "",
    "cmi.student_preference.language": "",
    "cmi.student_preference.speed": "",
    "cmi.student_preference.text": "",
    "cmi.interactions._children": "",
    "cmi.interactions._count": "",
    "cmi.interactions.n.id": "",
    "cmi.interactions.n.objectives._count": "",
    "cmi.interactions.n.objectives.n.id": "",
    "cmi.interactions.n.time": "",
    "cmi.interactions.n.type": "",
    "cmi.interactions.n.correct_responses._count": "",
    "cmi.interactions.n.correct_responses.n.pattern": "",
    "cmi.interactions.n.weighting": "",
    "cmi.interactions.n.student_response": "",
    "cmi.interactions.n.result": "",
    "cmi.interactions.n.latency : ": "",
  },
  scormResponse: {
    course_id: "",
    scorm_data: null,
  },
  syncResposne: null,
};

export const getScormData = createAsyncThunk(
  GET_SCORM_DATA,
  async ({ courseId }: { courseId: string }) => {
    return await apiRequest(GET, GET_SCORM_DATA_API(courseId));
  }
);

export const syncScormData = createAsyncThunk(
  SYNC_SCORM_DATA,
  async ({ scormContent }: { scormContent: ScormData }) => {
    const response = await apiRequest(
      POST,
      SYNC_SCORM_DATA_API(),
      scormContent
    );
    return response.data;
  }
);

const LearnerSlice = createSlice({
  name: "scormData",
  reducers: {
    setScormToInitialValues: state => {
      state.scormData = initialState.scormData;
      state.scormResponse = initialState.scormResponse;
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getScormData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScormData.fulfilled, (state, action) => {
        state.loading = false;
        state.scormResponse = action.payload;
      })
      .addCase(getScormData.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(syncScormData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(syncScormData.fulfilled, (state, action) => {
        state.loading = false;
        state.syncResposne = action.payload;
      })
      .addCase(syncScormData.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setScormToInitialValues } = LearnerSlice.actions;

export default LearnerSlice.reducer;
