import PropTypes from "prop-types";
import { Col, FormGroup, Input, Row } from "reactstrap";

import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import { EndDate, Enter, StartDate } from "../../../../../../utils/Constant";
import { getTomorrowDate } from "../../../../../../utils/helper/helper";

const ScheduleDetailsForm = ({
  start_date,
  end_date,
  errors,
  onDateChange,
  type,
}) => {
  const getNextDay = date => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0];
  };

  return (
    <div>
      <Row className="mt-1">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={StartDate}
              tooltipText={`${Enter} ${StartDate}`}
              important
            />
            <Input
              name="start_date"
              type="date"
              placeholder={`${Enter} ${StartDate}`}
              value={start_date}
              min={getTomorrowDate()}
              onChange={onDateChange}
              invalid={!!errors.start_date}
              disabled={type === "past" || type === "current"}
            />
            <div className="invalid-feedback">{errors.start_date}</div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={EndDate}
              tooltipText={`${Enter} ${EndDate}`}
              important
            />
            <Input
              name="end_date"
              type="date"
              placeholder={`${Enter} ${EndDate}`}
              value={end_date}
              min={start_date ? getNextDay(start_date) : getTomorrowDate()}
              onChange={onDateChange}
              invalid={!!errors.end_date}
              disabled={type === "past" || type === "current"}
            />
            <div className="invalid-feedback">{errors.end_date}</div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

ScheduleDetailsForm.propTypes = {
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  onDateChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ScheduleDetailsForm;
