import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, FormGroup } from "reactstrap";

import { Btn, MUIIcons, H4, H2 } from "../../../../../../AbstractElements";
import Divider from "../../../../../../CommonElements/Divider";
import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../../../CommonElements/MultiSelectDropdown";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import Comments from "../../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  getCourseContent,
  updateCommentStatus,
  updateCourseContent,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import { getResourceList } from "../../../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Add,
  Comment,
  Competency,
  EnterComments,
  ResourceRequired,
  Resources,
  Save,
  SaveAsDraft,
  Select,
} from "../../../../../../utils/Constant";
import {
  showToast,
  sortCommentsByDate,
} from "../../../../../../utils/helper/helper";

const CreateWebinarCompetativeStatement = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { courseId } = location.state || "";
  const { resourceList } = useAppSelector(state => state.master);
  const [resourceListData, setResourceListData] = useState(null);
  const [sliderModal, setSliderModal] = useState(false);
  const selectedResourceRef = useRef([]);
  const [userMap, setUserMap] = useState(null);
  const [iconComments, setIconComments] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [isComentRead, setIsComentRead] = useState(null);
  const { courseContent } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);
  const [preSelectedResource, setPreSelectedResource] = useState([]);
  const basicInputFormValue = {
    prerequisite: "",
    create_date: "",
    version_number: "",
    condition: "",
    personnel_requirement: "",
    media_demonstration: "",
    classroom_environment: "",
    estimated_delivery: "",
    class_room_ratio: "",
    practical_class_ratio: "",
    resources: "",
    reference: "",
    review: "",
    assessment: "",
  };

  useEffect(() => {
    dispatch(getResourceList());
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    const resources = [];
    resourceList?.resources?.forEach(sites => {
      const userObj = {
        name: sites.id,
        label: sites.name,
        value: sites.name,
      };
      resources.push(userObj);
    });
    setResourceListData(resources);
  }, [resourceList]);

  useEffect(() => {
    const resource = [];
    const resourceIds = [];
    if (courseContent && courseContent?.competency_statement) {
      courseContent?.competency_statement?.tool_aid?.tool?.forEach(
        (item: any) => {
          resourceIds.push(item?.id);
          resource.push({
            value: item?.name,
            name: item?.id,
            label: item?.name,
          });
        }
      );
    }
    selectedResourceRef.current = resourceIds;
    setPreSelectedResource(resource);
  }, [dispatch, courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.competency_statement) {
      comment = sortCommentsByDate(courseContent.comment.competency_statement);
    }
    setCommentList(comment);
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "competency_statement"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
  }, [courseContent]);

  const handleFinish = async type => {
    if (
      selectedResourceRef.current === null ||
      selectedResourceRef.current?.length === 0
    ) {
      showToast(ResourceRequired, "error");
      return;
    }

    const { ...rest } = courseContent;
    const resourcesMap = [];
    const referenceMap = [];
    const prerequisiteMap = [];

    resourceListData?.forEach(element => {
      if (selectedResourceRef.current.includes(element.name)) {
        resourcesMap.push({ id: element.name, name: element.label });
      }
    });

    const flatCourseContent = {
      ...rest,
      ...basicInputFormValue,
      status: "draft",
      min_attendance: courseContent.additional_information.min_attendance,
      max_attendance: courseContent.additional_information.max_attendance,
      suggested_reading: courseContent.additional_information.suggested_reading,
      qualification_certification:
        courseContent.additional_information.qualification_certification,
      assessment_criteria:
        courseContent.additional_information.assessment_criteria,
      theory_time_ratio:
        parseInt(courseContent?.additional_information?.theory_time_ratio) || 0,
      practical_time_ratio:
        parseInt(courseContent?.additional_information?.practical_time_ratio) ||
        0,
      tooling_aids_required:
        courseContent.additional_information.tooling_aids_required,
      date: "2024-03-03",
      prerequisite: prerequisiteMap,
      version: courseContent?.competency_statement?.version || "",
      conditions: basicInputFormValue.condition,
      min_requirements: basicInputFormValue.personnel_requirement,
      estimated_time: basicInputFormValue.estimated_delivery || null,
      classroom_lab: basicInputFormValue.classroom_environment,
      resources: resourcesMap,
      reference: referenceMap,
      resources_activity: basicInputFormValue.resources.toString(),
      intended_audience: [],
      class_room_ratio: basicInputFormValue.class_room_ratio || 0,
      practical_class_ratio: basicInputFormValue.practical_class_ratio || 0,
      practical_activity_details: "",
      registration_requirement: "",
      competency_statement_status: type,
    };

    await dispatch(updateCourseContent({ courseContent: flatCourseContent }));
    await dispatch(getCourseContent({ id: courseId }));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details`,
      { state: { courseId: courseId } }
    );
  };

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "competency_statement",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "competency_statement",
        comment: iconComments,
      },
    };

    await dispatch(addCommentToStages({ comment: payload }));
    await dispatch(getCourseContent({ id: courseId }));
    setIconComments("");
  };

  const getMultiDropdownResourceData = (value: any[]) => {
    const resourceMap = [];

    resourceListData.forEach((element: any) => {
      if (value.includes(element.name)) {
        resourceMap.push({
          name: element.name,
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedResource(resourceMap);
    selectedResourceRef.current = value;
  };

  return (
    <div className="page-body">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <H4 className="mt-2">{`${Add} ${Competency}`}</H4>
          <div className="comment-icon-container">
            <MUIIcons
              size={24}
              onClick={sliderToggle}
              className="primary-icon-color mt-2 pointer"
              iconName="ChatBubbleOutlineOutlined"
            />
            {isComentRead != null && isComentRead && (
              <span className="red-dot"></span>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <LabelTooltip
              label={Resources}
              tooltipText={`${Select} ${Resources}`}
              important
            />
            <MultiSelectDropdown
              onChange={values => {
                getMultiDropdownResourceData(values);
              }}
              options={resourceListData}
              placeholder={"Select Resource"}
              defaultSelected={preSelectedResource}
            />
          </FormGroup>
        </CardBody>
        <CardFooter className="d-flex gap-3 justify-content-end">
          <div
            className="edit-details-button"
            color="primary"
            onClick={() => handleFinish("draft")}
          >
            {SaveAsDraft}
          </div>
          <Btn color="primary" onClick={() => handleFinish("completed")}>
            {Save}
          </Btn>
        </CardFooter>
      </Card>
    </div>
  );
};

export default CreateWebinarCompetativeStatement;
