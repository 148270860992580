import { LI, UL } from "../../AbstractElements";

import { ZeroConfigurationTableColumnsType } from "./propTypes";

interface TableActionType {
  id: string;
}

const TableAction = ({ id }: TableActionType) => {
  return (
    <UL className="action simple-list flex-row" id={id}>
      <LI className="edit">
        <i className="icon-pencil-alt" />
      </LI>
      <LI className="delete">
        <i className="icon-trash" />
      </LI>
    </UL>
  );
};

export const DummyColumn = [
  {
    name: "Name",
    selector: (row: ZeroConfigurationTableColumnsType) => row["name"],
    sortable: true,
  },
  {
    name: "Position",
    selector: (row: ZeroConfigurationTableColumnsType) => `${row.position}`,
    sortable: true,
  },
  {
    name: "Office",
    selector: (row: ZeroConfigurationTableColumnsType) => `${row.office}`,
    sortable: true,
  },
  {
    name: "Age",
    selector: (row: ZeroConfigurationTableColumnsType) => `${row.age}`,
    sortable: true,
  },
  {
    name: "Start Date",
    selector: (row: ZeroConfigurationTableColumnsType) => row["startDate"],
    sortable: true,
  },
  {
    name: "Salery",
    selector: (row: ZeroConfigurationTableColumnsType) => `${row.salary}`,
    sortable: true,
  },
  {
    name: "Action",
    cell: (row: ZeroConfigurationTableColumnsType) => (
      <TableAction id={row.action} />
    ),
    sortable: true,
  },
];

export const DummyData = [
  {
    id: 1,
    name: "Airi Satou",
    position: "Accountant",
    office: "Tokyo",
    age: 33,
    startDate: "2008/11/28",
    salary: "$162,700",
    action: "t-1",
  },
  {
    id: 2,
    name: "Ashton Cox",
    position: "Junior Technical Author",
    office: "San Francisco",
    age: 66,
    startDate: "2009/01/12",
    salary: "$86,000",
    action: "t-2",
  },
];

export const ilpCoursesCount = {
  ilt: 5,
  vilt: 5,
  webinar: 5,
  wbt: 5,
};

export const ilpCardData = [
  {
    courseId: "1",
    domainName: ["Domain 1"],
    status: "waiting_for_rm_approval",
    courseName:
      "Project Delivery Methods and Contracts in Construction Management check",
    completedOn: "",
    scheduledOn: "2024-07-16T10:34:40Z",
    assignedBy: "RM Assigned",
    courseType: "ILT",
    badgeTextColor: true,
    reAttemptCount: 0,
  },
  {
    courseId: "2",
    domainName: ["Domain 2"],
    status: "in_progress",
    courseName:
      "Caterpillar advanced Contracts Project Delivery Methods and Contracts in Construction Management check check",
    completedOn: "",
    scheduledOn: "2024-08-16T10:34:40Z",
    assignedBy: "Self",
    courseType: "VILT",
    badgeTextColor: true,
    reAttemptCount: 0,
  },
  {
    courseId: "3",
    domainName: ["Domain 3"],
    status: "pass",
    courseName: "Machine operational model",
    completedOn: "2024-08-16T10:34:40Z",
    scheduledOn: "",
    assignedBy: "Self",
    courseType: "Webinar",
    percentage: "65",
    badgeTextColor: true,
    reAttemptCount: 0,
  },
  {
    courseId: "4",
    domainName: ["Domain 4"],
    status: "failed",
    courseName:
      "Project Delivery Methods and Contracts in Construction Management",
    completedOn: "2024-07-16T10:34:40Z",
    scheduledOn: "",
    assignedBy: "Self",
    courseType: "WBT",
    badgeTextColor: true,
    reAttemptCount: 2,
  },
  {
    courseId: "5",
    domainName: ["Domain 5"],
    status: "over_due",
    courseName: "Caterpillar advanced Contracts",
    completedOn: "",
    scheduledOn: "2024-07-31T10:34:40Z",
    assignedBy: "SGA",
    courseType: "ILT",
    badgeTextColor: false,
    reAttemptCount: 0,
  },
  {
    courseId: "6",
    domainName: ["Domain 6"],
    status: "yet_to_start",
    courseName: "Machine operational model",
    completedOn: "",
    scheduledOn: "2024-08-16T10:34:40Z",
    assignedBy: "Self",
    courseType: "ILT",
    badgeTextColor: true,
    reAttemptCount: 0,
  },
];

export const ilpCourseSummaryData = data => {
  return [
    {
      id: 1,
      icon: "graduation-cap",
      count: data.total_courses,
      label: "Total Course",
      infoData: [
        { courseName: "WBT", totalCount: data.course_types.total_courses.wbt },
        {
          courseName: "VILT",
          totalCount: data.course_types.total_courses.vilt,
        },
        { courseName: "ILT", totalCount: data.course_types.total_courses.ilt },
        {
          courseName: "Webinar",
          totalCount: data.course_types.total_courses.webinar,
        },
      ],
    },
    {
      id: 2,
      icon: "completed-tick",
      count: data.completed_courses,
      label: "Completed",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.completed_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.completed_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.completed_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.completed_courses.webinar,
        },
      ],
    },
    {
      id: 3,
      icon: "upcoming-calendar",
      count: data.upcoming_courses,
      label: "Upcoming",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.upcoming_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.upcoming_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.upcoming_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.upcoming_courses.webinar,
        },
      ],
    },
    {
      id: 4,
      icon: "scheduled-calendar",
      count: data.scheduled_courses,
      label: "Scheduled",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.scheduled_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.scheduled_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.scheduled_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.scheduled_courses.webinar,
        },
      ],
    },
    {
      id: 5,
      icon: "overdue-calendar",
      count: data.overdue_courses,
      label: "Overdue",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.overdue_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.overdue_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.overdue_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.overdue_courses.webinar,
        },
      ],
    },
  ];
};

export const ILPPendingSkillTableData = [
  {
    courseName: "Project Delivery Methods and Contracts",
    percentageCompleted: 75,
    SkillDetails: [
      {
        lo: ["Learning Outcome 1", "Learning Outcome 2", "Learning Outcome 3"],
        type: "Field assessment",
        learnerDocs: "Field-document.pdf",
        trainerDocs: "Field-document.pdf",
        status: "Approved",
      },
      {
        lo: ["Learning Outcome 1", "Learning Outcome 2"],
        type: "Field assessment",
        learnerDocs: "Field-document.pdf",
        trainerDocs: "Field-document.pdf",
        status: "Rejected",
      },
    ],
  },
  {
    courseName: "Caterpillar advanced Contracts",
    percentageCompleted: 0,
    SkillDetails: [
      {
        lo: ["Learning Outcome 1", "Learning Outcome 2"],
        type: "OJT assessment",
        learnerDocs: "Field-document.pdf",
        trainerDocs: "",
        status: "Pending for review",
      },
      {
        lo: ["Learning Outcome 1", "Learning Outcome 2"],
        type: "Field assessment",
        learnerDocs: "Field-document.pdf",
        trainerDocs: "Field-document.pdf",
        status: "Approved",
      },
    ],
  },
];

export const CertificationAcquired = [
  {
    title: "Project Delivery Methods and Contracts management",
    certifiedOn: "2022-07-16T10:34:40Z",
    expiredOn: "2024-07-16T10:34:40Z",
  },
  {
    title: "Caterpillar advanced Contracts",
    certifiedOn: "2022-05-16T10:34:40Z",
    expiredOn: "",
  },
];

export const calculateValuePercentage = (value: number, totalCourses) => {
  if (!totalCourses || totalCourses === 0) return 0;
  return Math.round((value / totalCourses) * 100);
};

export const multiProgressData = courseData => {
  const totalCourses = courseData.total_courses;
  const percentageData = [
    {
      title: "Completed",
      percentage: calculateValuePercentage(
        courseData.completed_courses,
        totalCourses
      ),
      className: "success-color",
    },
    {
      title: "Upcoming",
      percentage: calculateValuePercentage(
        courseData.upcoming_courses,
        totalCourses
      ),
      className: "warning-color",
    },
    {
      title: "Scheduled",
      percentage: calculateValuePercentage(
        courseData.scheduled_courses,
        totalCourses
      ),
      className: "assigned-color",
    },
    {
      title: "Overdue",
      percentage: calculateValuePercentage(
        courseData.overdue_courses,
        totalCourses
      ),
      className: "error-color",
    },
  ];

  return percentageData;
};

export const ILPSgaCardData = [
  {
    role: "Technician",
    expertiseLevel: "Pre-Foundational",
    percentageCompleted: 60,
  },
  {
    role: "designer",
    expertiseLevel: "Foundational",
    percentageCompleted: 20,
  },
];

export const MapCourseData = [
  {
    courseType: "ILT",
    courses: [
      "Project Delivery Methods and Help",
      "System Design",
      "Effective Communication",
    ],
  },
  {
    courseType: "VILT",
    courses: [
      "Agile Methodologies",
      "Advanced System Design",
      "Remote Team Management",
    ],
  },
  {
    courseType: "Webinar",
    courses: [
      "Introduction to Cybersecurity",
      "Data Privacy Essentials",
      "Cloud Computing Overview",
    ],
  },
  {
    courseType: "WBT",
    courses: [
      "Basics of JavaScript",
      "HTML & CSS Fundamentals",
      "React.js for Beginners",
    ],
  },
];

export const MapSgaData = [
  "Leadership Training",
  "Software Development",
  "Educational Psychology",
];

export const CourseCount = {
  totalCourse: 100,
  PendingCourse: 50,
  CompletedCourse: 5,
};

export const AssessmentCount = {
  completed: 5,
  pending: 5,
  Total: 5,
};

export const ILPAssessmentCardData = [
  {
    id: 1,
    startDate: "2024-09-07 00:00:00",
    endDate: "2024-09-18 00:00:00",
    endTime: "2024-09-09 12:49:12.968933",
    name: "web development",
    estimatedTime: "1hr 30min",
    totalNoOfQuestions: 60,
    modulesCount: 5,
    status: "Completed",
    finalScore: 80,
  },
  {
    id: 2,
    startDate: "2024-09-07 00:00:00",
    endDate: "2024-09-18 00:00:00",
    endTime: "2024-09-09 12:49:12.968933",
    name: "App development",
    estimatedTime: "1hr",
    totalNoOfQuestions: 60,
    modulesCount: 5,
    status: "Submitted",
    finalScore: 0,
  },
];
