import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Tooltip } from "reactstrap";

import {
  Badges,
  MUIIcons,
  H3,
  H4,
  H5,
  DefaultButton,
} from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import CommonModal from "../../CommonElements/Modal";
import {
  Confirm,
  DeleteFileHeader,
  DeleteMaterialFile,
  Play,
} from "../../utils/Constant";
import { getEllipsedFileName, sanitizeString } from "../../utils/helper/helper";

export interface MaterialDataItem {
  paths?: string[];
  documents: string[];
  greeting: string;
}

interface MaterialDetailsProps {
  title: string;
  data: MaterialDataItem[];
  showDelete?: boolean;
  showPlay?: boolean;
  onDeleteFile?: (fileType: number, fileIndex: number) => void;
  onPlayFile?: (fileType: number, fileIndex: number) => void;
}

const MaterialDetails: React.FC<MaterialDetailsProps> = ({
  title,
  data,
  showDelete = false,
  showPlay = false,
  onDeleteFile,
  onPlayFile,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteFileInfo, setDeleteFileInfo] = useState<{
    fileType: number;
    fileIndex: number;
  } | null>(null);

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`Failed to download file from ${url}: `, error);
        }
      }
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      setDeleteFileInfo(null);
    }
  };

  const handleDeleteClick = (fileType: number, fileIndex: number) => {
    setDeleteFileInfo({ fileType, fileIndex });
    toggleModal();
  };

  const playScorm = (fileType: number, fileIndex: number) => {
    onPlayFile(fileType, fileIndex);
  };

  const handleConfirmDelete = () => {
    if (deleteFileInfo && onDeleteFile) {
      onDeleteFile(deleteFileInfo.fileType, deleteFileInfo.fileIndex);
      toggleModal();
    }
  };

  return (
    <>
      <CommonModal
        sizeTitle={DeleteFileHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleConfirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteMaterialFile}
      </CommonModal>
      <Card className="p-2">
        {title && (
          <CardHeader>
            <H4>{title}</H4>
          </CardHeader>
        )}
        <CardBody>
          {data?.map((item, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col lg="3" sm="3">
                  {item.documents && <H5 className="mb-2">{item.greeting}</H5>}
                </Col>
                <Col>
                  <Row>
                    {item.documents?.map((doc, docIndex) => {
                      const uniqueId = `${index}-${docIndex}`;
                      return (
                        <Col key={uniqueId} lg="3" sm="3" className="mb-3">
                          <H3>
                            <Badges color="light text-dark">
                              <div className="d-flex align-items-center">
                                <MUIIcons
                                  size={24}
                                  iconName="InsertDriveFileOutlined"
                                />
                                <span
                                  className="m-1"
                                  id={sanitizeString(
                                    `tooltip-course-name-${uniqueId}`
                                  )}
                                >
                                  {getEllipsedFileName(doc)}
                                </span>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpen[uniqueId] || false}
                                  target={sanitizeString(
                                    `tooltip-course-name-${uniqueId}`
                                  )}
                                  toggle={() => toggleTooltip(uniqueId)}
                                >
                                  {doc}
                                </Tooltip>
                                {showDelete && (
                                  <MUIIcons
                                    iconName="DeleteOutlineOutlined"
                                    className="primary-icon-color pointer"
                                    size={24}
                                    onClick={() =>
                                      handleDeleteClick(index, docIndex)
                                    }
                                  />
                                )}
                              </div>
                              {showPlay && (
                                <div className="d-flex justify-content-center m-1">
                                  <DefaultButton
                                    className="d-flex justify-content-center w-100"
                                    onClick={() => playScorm(index, docIndex)}
                                    color="primary"
                                  >
                                    {Play}
                                  </DefaultButton>
                                </div>
                              )}
                            </Badges>
                          </H3>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col lg="1" sm="1">
                  {item?.documents?.length > 0 && (
                    <MUIIcons
                      size={24}
                      className="primary-icon-color pointer"
                      iconName="FileDownloadOutlined"
                      onClick={() => handleDownload(item?.paths)}
                    />
                  )}
                </Col>
              </Row>
              {index !== data.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

export default MaterialDetails;
