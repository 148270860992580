import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Input, Row, Col, Container } from "reactstrap";

import { P } from "../../AbstractElements";

interface CheckboxItem {
  label: string;
  selected: boolean;
}

interface Group {
  name: string;
  items: CheckboxItem[];
}

interface AssesmentReviewCardProps {
  title?: string;
  groups: Group[];
  onUpdate: (groupIndex: number, itemIndex: number) => void;
}

const AssesmentReviewCard: React.FC<AssesmentReviewCardProps> = ({
  title,
  groups,
  onUpdate,
}) => {
  return (
    <Container>
      <Row className="d-flex justify-content-between">
        <Col className="text-center">{title}</Col>
        {groups?.length > 0 &&
          groups[0]?.items?.map((checkbox, index) => (
            <Col xs="3" sm="3" key={index} className="text-center">
              <P className="text-gray">{checkbox.label}</P>
            </Col>
          ))}
      </Row>
      {groups.map((group, groupIndex) => (
        <Row key={groupIndex} className="response-container">
          <Col>
            <P className="text-gray">{group.name}</P>
          </Col>
          {group?.items?.map((checkbox, itemIndex) => (
            <Col className="d-flex justify-content-center" key={itemIndex}>
              <FormGroup
                check
                className={`checkbox-item ${checkbox.selected ? "selected" : ""}`}
                onClick={() => onUpdate(groupIndex, itemIndex)}
              >
                <Input type="checkbox" checked={checkbox.selected} readOnly />
              </FormGroup>
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

AssesmentReviewCard.propTypes = {
  title: PropTypes.string,
  groups: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AssesmentReviewCard;
