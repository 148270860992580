import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Badges, MUIIcons, H4 } from "../../AbstractElements";
import DateRangeDisplay from "../../CommonElements/DateRangeDisplay";
import Divider from "../../CommonElements/Divider";
import StatusDisplay from "../../CommonElements/StatusDisplay";

type RowData = {
  id: number;
  startDate: string;
  endDate: string;
  statusLabel: string;
  statusNumber: string;
  badgesLabel: string;
  badgesValue: string;
  icons: { name: string; size: number }[];
};

type ScheduledDetailsProps = {
  title: string;
  rows: RowData[];
  onDeleteClick?: (id) => void;
  onEditClick?: (id) => void;
  showDelete?: boolean;
};

const ScheduledDetails: React.FC<ScheduledDetailsProps> = ({
  title,
  rows,
  onDeleteClick,
  onEditClick,
  showDelete = true,
}) => {
  return (
    <Card>
      <CardBody className="m-2">
        <H4 className="mt-2">{title}</H4>
        <Divider />
        {rows.map((row, rowIndex) => {
          return (
            <div key={rowIndex}>
              <Row>
                <Col lg="7" className="mt-1">
                  <Row className="align-items-center">
                    <Col className="mt-3">
                      <DateRangeDisplay
                        startDate={row.startDate}
                        endDate={row.endDate}
                      />
                    </Col>
                    <Col className="mt-3">
                      <StatusDisplay
                        label={row.statusLabel}
                        number={row.statusNumber}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="3"></Col>
                <Col lg="2" className="d-flex gap-4 mt-3 align-items-center">
                  {parseInt(row?.badgesValue) > 0 && (
                    <Badges status="draft">
                      {row.badgesLabel} : {row.badgesValue}
                    </Badges>
                  )}
                  <MUIIcons
                    size={24}
                    className="primary-icon-color pointer"
                    iconName="EditOutlined"
                    onClick={() => onEditClick(row?.id)}
                  />
                  {showDelete && (
                    <MUIIcons
                      iconName="DeleteOutlineOutlined"
                      className="primary-icon-color pointer"
                      size={24}
                      onClick={() => onDeleteClick(row?.id)}
                    />
                  )}
                </Col>
              </Row>
              <Divider />
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};

ScheduledDetails.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  showDelete: PropTypes.bool,
};

export default ScheduledDetails;
