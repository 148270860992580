import React from "react";

import { DefaultButton } from "../../AbstractElements";

export interface ProgressStep {
  color: string;
  number: string;
  className: string;
}

interface DynamicProgressWithNumberStepsProps {
  progressNumberList: ProgressStep[];
}

const DynamicProgressWithNumberSteps: React.FC<
  DynamicProgressWithNumberStepsProps
> = ({ progressNumberList }) => {
  return (
    <>
      {progressNumberList?.map((step, index) => (
        <DefaultButton
          key={index}
          size="sm"
          color={step.color}
          className={`position-absolute top-0 p-0 ${step.className} translate-middle rounded-circle`}
        >
          {step.number}
        </DefaultButton>
      ))}
    </>
  );
};

export default DynamicProgressWithNumberSteps;
