import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";
import { Col } from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";
import { FileSizeError, UploadOrDragFiles } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import P from "../Paragraph";

const MultiFileUpload = ({
  onFileChange,
  note,
  accept = ".pdf,.docx,.txt,.pptx",
  multiple = true,
  materials = false,
  flushData = false,
}: {
  onFileChange: (files: File, status?: string) => void;
  note?: string;
  accept?: string;
  multiple?: boolean;
  materials?: boolean;
  flushData?: boolean;
}) => {
  const MAX_SIZE_BYTES = 10485760;
  const dropzoneRef = useRef(null);

  useEffect(() => {
    if (flushData && dropzoneRef.current) {
      const filesCopy = [...dropzoneRef.current.files];
      filesCopy.forEach(file => {
        file.remove();
      });
    }
  }, [flushData]);

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { file },
    status
  ) => {
    if (status === "done") {
      if (file.size > MAX_SIZE_BYTES) {
        return;
      }
      if (onFileChange) {
        onFileChange(file, status);
      }
    } else if (status === "removed") {
      if (onFileChange) {
        onFileChange(file, status);
      }
    }
  };

  return (
    <Col className="mt-2" lg={materials ? 6 : 12}>
      <P className="primary-text-color">{note}</P>
      <Dropzone
        ref={dropzoneRef}
        onChangeStatus={handleChangeStatus}
        maxFiles={multiple ? 10 : 1}
        maxSizeBytes={MAX_SIZE_BYTES}
        multiple={multiple}
        inputContent={UploadOrDragFiles}
        accept={accept}
        getFilesFromEvent={event => {
          const target = event.target as HTMLInputElement;
          const files = target.files ? Array.from(target.files) : [];
          const largeFiles = files.filter(file => file.size > MAX_SIZE_BYTES);
          if (largeFiles.length > 0) {
            showToast(FileSizeError, "error");
          }
          return files.filter(file => file.size <= MAX_SIZE_BYTES);
        }}
        styles={{
          dropzone: { minHeight: 200, maxHeight: 250 },
          dropzoneActive: { borderColor: "green" },
        }}
      />
    </Col>
  );
};

MultiFileUpload.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  materials: PropTypes.bool,
  flushData: PropTypes.bool,
};

export default MultiFileUpload;
