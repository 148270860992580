import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GET,
  GET_POLICY_LIST,
  DELETE_POLICY,
  DELETE_POLICY_API,
  DELETE,
  UPSERT_POLICY_API,
  POST,
  CREATE_POLICY,
  UPDATE_POLICY,
  PUT,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  PolicyListResponse,
  UpsertPolicyPayload,
} from "../../Api/entities/PolicyEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface PolicyInterface {
  basicInputFormValue: UpsertPolicyPayload;
  policyList: PolicyListResponse;
  deletePolicy: {};
  createPolicy: {};
  loading: boolean;
  error: string | null;
}

const initialState: PolicyInterface = {
  basicInputFormValue: {
    name: "",
    no_of_attempt: null,
    cooling_period: null,
    pass_percentage: null,
    is_reattempt: false,
    max_score: null,
    min_score: null,
  },
  policyList: { policy: [] },
  deletePolicy: {},
  createPolicy: {},
  loading: false,
  error: null,
};

export const getReAttemptPolicyList = createAsyncThunk(
  GET_POLICY_LIST,
  async () => {
    const response = await apiRequest(GET, UPSERT_POLICY_API());
    return response.data;
  }
);

export const createReAttemptPolicy = createAsyncThunk(
  CREATE_POLICY,
  async ({ policyContent }: { policyContent: UpsertPolicyPayload }) => {
    const response = await apiRequest(POST, UPSERT_POLICY_API(), policyContent);
    return response;
  }
);

export const updateReAttemptPolicyList = createAsyncThunk(
  UPDATE_POLICY,
  async ({ policyContent }: { policyContent: UpsertPolicyPayload }) => {
    const response = await apiRequest(PUT, UPSERT_POLICY_API(), policyContent);
    return response;
  }
);

export const deleteReAttemptPolicy = createAsyncThunk(
  DELETE_POLICY,
  async ({ id }: { id: number }) => {
    const respone = await apiRequest(DELETE, DELETE_POLICY_API(id));
    return respone;
  }
);

const PolicySlice = createSlice({
  name: "policy",
  reducers: {
    setBasicInputFormValue(state, action) {
      state.basicInputFormValue = action.payload;
    },
    setPolicyInputFormValueToInitials: state => {
      state.basicInputFormValue = initialState.basicInputFormValue;
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getReAttemptPolicyList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReAttemptPolicyList.fulfilled, (state, action) => {
        state.loading = false;
        state.policyList = action.payload;
      })
      .addCase(getReAttemptPolicyList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteReAttemptPolicy.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteReAttemptPolicy.fulfilled, (state, action) => {
        state.loading = false;
        state.deletePolicy = action;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteReAttemptPolicy.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(createReAttemptPolicy.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createReAttemptPolicy.fulfilled, (state, action) => {
        state.loading = false;
        state.createPolicy = action;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createReAttemptPolicy.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateReAttemptPolicyList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateReAttemptPolicyList.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateReAttemptPolicyList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setBasicInputFormValue, setPolicyInputFormValueToInitials } =
  PolicySlice.actions;
export default PolicySlice.reducer;
