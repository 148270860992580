import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H4 } from "../../AbstractElements";
import DetailHeader from "../../CommonElements/DetailHeader";
import Divider from "../../CommonElements/Divider";

interface Detail {
  assign: string;
  name: string;
}

interface RowData {
  details: Detail[];
}

interface CourseDetailsProps {
  level: 1 | 2 | 3 | 4;
  data: RowData[];
  headerText: string;
}

const CourseDetails: React.FC<CourseDetailsProps> = ({
  level,
  data,
  headerText,
}) => {
  const renderRows = (rows: RowData[]) => {
    return (
      <>
        {rows?.map((rowData, rowIndex) => {
          return (
            <React.Fragment key={rowIndex}>
              <Row className="mt-3 mb-2">
                {rowData.details?.map((detail, colIndex) => (
                  <Col
                    className="mt-3"
                    lg={rowData?.details?.length === 1 ? "12" : "4"}
                    xs="6"
                    sm="6"
                    key={colIndex}
                  >
                    <DetailHeader assign={detail?.assign} name={detail?.name} />
                  </Col>
                ))}
              </Row>
              {rowIndex < rows.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <Card>
      <CardBody className="p-4 mt-2">
        <H4>{headerText}</H4>
        <Divider />
        {renderRows(data.slice(0, level))}
      </CardBody>
    </Card>
  );
};

export default CourseDetails;
