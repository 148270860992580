import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Input, Row, Col, Form } from "reactstrap";

import { PUBLISHED } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  getAssessmentByStatus,
  mapAssessment,
} from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Assessment,
  AssessmentRequired,
  AssessmentType,
  AssessmentTypeRequired,
  Dates,
  EndDate,
  EndDateRequired,
  MapAssessment,
  Select,
  StartDate,
  StartDateRequired,
  Submit,
} from "../../utils/Constant";
import {
  assessmentTypes,
  assessmentTypesMap,
  getTomorrowDate,
  mapListToOptions,
} from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";

interface MapAssessmentModalProps {
  isOpen: boolean;
  toggle: (isMapped?: boolean) => void;
  userId?: number;
}

const MapAssessmentModal: React.FC<MapAssessmentModalProps> = ({
  isOpen,
  toggle,
  userId,
}) => {
  const [assessmentOptions, setAssessmentOptions] = useState([]);
  const [formValues, setFormValues] = useState({
    assessment_type: "",
    assessment: "",
    start_date: "",
    end_date: "",
  });
  const [errors, setErrors] = useState({
    assessment_type: "",
    assessment: "",
    start_date: "",
    end_date: "",
  });
  const dispatch = useAppDispatch();
  const { assessmentList } = useAppSelector(state => state.learningPlan);

  useEffect(() => {
    if (formValues.assessment_type) {
      dispatch(
        getAssessmentByStatus({
          status: PUBLISHED,
          requestType: formValues.assessment_type,
          userId: userId,
        })
      );
      setFormValues(prevState => ({
        ...prevState,
        assessment: "",
        start_date: "",
        end_date: "",
      }));
    }
  }, [formValues.assessment_type]);

  useEffect(() => {
    const options = mapListToOptions(assessmentList?.assessments, "name", "id");
    setAssessmentOptions(options);
  }, [assessmentList]);

  const getNextDay = date => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0];
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const isSelftAssessment =
      assessmentTypesMap.competency !== formValues.assessment_type;
    const newErrors = {
      assessment_type: "",
      assessment: "",
      start_date: "",
      end_date: "",
    };

    let isError = false;
    if (!formValues.assessment_type) {
      newErrors.assessment_type = AssessmentTypeRequired;
      isError = true;
    }

    if (!formValues.assessment) {
      newErrors.assessment = AssessmentRequired;
      isError = true;
    }

    if (isSelftAssessment && !formValues.start_date) {
      newErrors.start_date = StartDateRequired;
      isError = true;
    }

    if (isSelftAssessment && !formValues.end_date) {
      newErrors.end_date = EndDateRequired;
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const getMapAssessmentModalBody = () => {
    return (
      <>
        <Form className="custom-input" noValidate>
          <div className="mb-3">
            <LabelTooltip
              label={AssessmentType}
              tooltipText={`${Select} ${AssessmentType}`}
              important={true}
              placement={"bottom"}
            />
            <Input
              type="select"
              value={formValues?.assessment_type}
              onChange={handleChange}
              name="assessment_type"
              invalid={!!errors?.assessment_type}
            >
              <option value="" disabled>
                {Select} {AssessmentType}
              </option>
              {assessmentTypes.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
            {errors?.assessment_type && (
              <div className="invalid-feedback">{errors?.assessment_type}</div>
            )}
          </div>

          <div className="mb-3">
            <LabelTooltip
              label={Assessment}
              tooltipText={`${Select} ${Assessment}`}
              important={true}
              placement={"bottom"}
            />
            <Input
              type="select"
              value={formValues?.assessment}
              onChange={handleChange}
              name="assessment"
              disabled={!formValues?.assessment_type}
              invalid={!!errors?.assessment}
            >
              {assessmentOptions.map((assessment, index) => (
                <option key={index} value={assessment.value}>
                  {assessment.label}
                </option>
              ))}
            </Input>
            {errors?.assessment && (
              <div className="invalid-feedback">{errors?.assessment}</div>
            )}
          </div>

          {assessmentTypesMap.competency !== formValues.assessment_type && (
            <div className="mb-3">
              <Row>
                <Col md={6}>
                  <LabelTooltip
                    label={StartDate}
                    tooltipText={`${Select} ${StartDate}`}
                    important={true}
                    placement={"bottom"}
                  />
                  <Input
                    type="date"
                    name="start_date"
                    placeholder={`${Select} ${Dates}`}
                    min={getTomorrowDate()}
                    onChange={handleChange}
                    value={formValues?.start_date}
                    invalid={!!errors?.start_date}
                  />
                  {errors?.start_date && (
                    <div className="invalid-feedback">{errors?.start_date}</div>
                  )}
                </Col>
                <Col md={6}>
                  <LabelTooltip
                    label={EndDate}
                    tooltipText={`${Select} ${EndDate}`}
                    important={true}
                    placement={"bottom"}
                  />
                  <Input
                    type="date"
                    name="end_date"
                    placeholder={`${Select} ${Dates}`}
                    onChange={handleChange}
                    value={formValues?.end_date}
                    min={
                      formValues?.start_date
                        ? getNextDay(formValues?.start_date)
                        : getTomorrowDate()
                    }
                    invalid={!!errors?.end_date}
                  />
                  {errors?.end_date && (
                    <div className="invalid-feedback">{errors?.end_date}</div>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Form>
      </>
    );
  };

  const handleSubmit = () => {
    if (validateForm()) {
      return;
    }

    dispatch(
      mapAssessment({
        assessmentPayload: {
          type: "assessment",
          user_id: userId,
          id: Number(formValues.assessment),
          start_date: formValues.start_date ? formValues.start_date : null,
          end_date: formValues.end_date ? formValues.end_date : null,
        },
      })
    ).then(res => {
      if (res.payload) {
        toggle(true);
      }
    });
  };
  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={MapAssessment}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getMapAssessmentModalBody()}
    </CommonModal>
  );
};

MapAssessmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default MapAssessmentModal;
