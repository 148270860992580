import React, { useEffect, useState } from "react";
import "./style.scss";
import { Input, Modal, ModalBody } from "reactstrap";

import Btn from "../Button";
import MUIIcons from "../MUIIcon/MUIIcons";

interface SearchBarProps {
  placeholder?: string;
  onSearch: (query: string) => void;
  className?: string;
  showSearch?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search",
  onSearch,
  showSearch,
  className,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [query, setQuery] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    onSearch(newQuery);
  };

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
    setQuery("");
    onSearch("");
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.body.style.overflow = "auto";
    }
  }, [isPopoverOpen]);

  return (
    <div className="mt-2">
      <div
        className={`search-bar ${showSearch ? "search-web" : ""} ${className}`}
      >
        {showSearch ? (
          <>
            <Btn className="search-button" onClick={() => onSearch(query)}>
              <MUIIcons
                iconName="SearchOutlined"
                size={24}
                className="search-icon primary-icon-color"
              />
            </Btn>
            <Input
              type="text"
              className="search-input"
              placeholder={placeholder}
              value={query}
              onChange={handleInputChange}
            />
          </>
        ) : (
          <>
            <MUIIcons
              className="m-2 primary-icon-color pointer"
              iconName="SearchOutlined"
              size={24}
              onClick={() => {
                togglePopover();
              }}
            />

            {isPopoverOpen && (
              <Modal
                isOpen={isPopoverOpen}
                toggle={togglePopover}
                className="search-modal"
              >
                <ModalBody className="d-flex flex-row align-items-center">
                  <MUIIcons
                    className="m-2 primary-icon-color pointer"
                    iconName="SearchOutlined"
                    size={24}
                    onClick={() => onSearch(query)}
                  />

                  <Input
                    type="text"
                    className="search-input"
                    placeholder={placeholder}
                    value={query}
                    onChange={handleInputChange}
                  />
                  <MUIIcons
                    size={24}
                    className="m-2 close-search primary-icon-color pointer"
                    iconName="CloseOutlined"
                    onClick={handleClose}
                  />
                </ModalBody>
              </Modal>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
