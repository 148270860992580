import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

import { H4 } from "../../AbstractElements";
import JustifyTabs from "../../CommonElements/JustifyTabs";
import SearchBar from "../../CommonElements/SearchBar";
import UserProfileCardDetails from "../../CommonElements/UserProfileCardDetails";
import { ListEmployee } from "../../utils/Constant";
import "./style.scss";
import { UserListTabs } from "../../utils/helper/helper";
import NoDataFound from "../NoDataFound";

export interface UserCardDetails {
  id: number;
  name: string;
  detail: string;
  cwsAndCatRecId: number;
  userProfileUrl: string;
  expertiseLevel: string;
  location: string;
  rmName: string;
  phoneNumber: number;
  mailId: string;
  jobRoleId: number;
}

interface UserListProps {
  activeUsers: UserCardDetails[];
  suspendedUsers: UserCardDetails[];
}

const UserList: React.FC<UserListProps> = ({ activeUsers, suspendedUsers }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");

  const [activeTab, setActiveTab] = useState<string>(
    statusParam ? statusParam : "active"
  );
  const [searchValue, setSearchValue] = useState<string | number>("");

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
      setSearchValue("");
    }
  }, [statusParam, activeTab]);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    setSearchValue("");
  };

  const handleCardClick = (user: any) => {
    return navigate("/user-management/user-details", {
      state: {
        userId: user?.id,
        jobRoleId: user?.jobRoleId,
      },
    });
  };

  const handleSearch = (
    searchValue: string | number,
    users: UserCardDetails[]
  ) => {
    const lowerCaseSearchValue =
      typeof searchValue === "string"
        ? searchValue.toLowerCase()
        : searchValue.toString();

    return users?.filter(user =>
      [
        user.name,
        user.cwsAndCatRecId.toString(),
        user.mailId,
        user.phoneNumber.toString(),
      ].some(field => field.toLowerCase().includes(lowerCaseSearchValue))
    );
  };

  const handleSearchInputChange = (value: string | number) => {
    setSearchValue(value);
  };

  const renderUserCards = (users: UserCardDetails[]) => {
    const filteredUsers = handleSearch(searchValue, users);

    if (filteredUsers?.length === 0) {
      return <NoDataFound />;
    }

    return filteredUsers?.map(user => (
      <div
        key={user.id}
        onClick={() => handleCardClick(user)}
        className={"pointer user-list__card"}
      >
        <UserProfileCardDetails
          userProfileUrl={user.userProfileUrl}
          name={user.name}
          detail={user.detail}
          expertiseLevel={user.expertiseLevel}
          location={user.location}
          rmName={user.rmName}
          phoneNumber={user.phoneNumber}
          mailId={user.mailId}
          cwsAndCatRecId={user.cwsAndCatRecId}
          uniqueKey={user.id}
        />
      </div>
    ));
  };

  return (
    <Col className="user-list">
      <Card className="p-2">
        <CardBody>
          <H4>{ListEmployee}</H4>
          <div className="mt-3 d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={UserListTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
          </div>

          <SearchBar
            showSearch={true}
            className="mt-4 mb-4 custom-userList-search-bar"
            key={activeTab}
            onSearch={handleSearchInputChange}
          />

          {activeTab === "active" && renderUserCards(activeUsers)}
          {activeTab === "suspended" && renderUserCards(suspendedUsers)}
        </CardBody>
      </Card>
    </Col>
  );
};

UserList.propTypes = {
  activeUsers: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
      cwsAndCatRecId: PropTypes.number.isRequired,
      userProfileUrl: PropTypes.string.isRequired,
      expertiseLevel: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      rmName: PropTypes.string.isRequired,
      phoneNumber: PropTypes.number.isRequired,
      mailId: PropTypes.string.isRequired,
      jobRoleId: PropTypes.number.isRequired,
    })
  ).isRequired,
  suspendedUsers: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
      cwsAndCatRecId: PropTypes.number.isRequired,
      userProfileUrl: PropTypes.string.isRequired,
      expertiseLevel: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      rmName: PropTypes.string.isRequired,
      phoneNumber: PropTypes.number.isRequired,
      mailId: PropTypes.string.isRequired,
      jobRoleId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default UserList;
