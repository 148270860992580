import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../CommonElements/JustifyTabs";
import SearchBar from "../../CommonElements/SearchBar";
import GenericCard from "../../container/GenericCard";
import NoDataFound from "../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getAssignedAssessmentForLearner } from "../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  AssessmentType,
  CompletedOn,
  CoolingPeriod,
  Days,
  End,
  FinalScore,
  Mins,
  Modules,
  Note,
  Questions,
  Resume,
  Retake,
  Select,
  Start,
  StartNote,
  SubmittedOn,
  Time,
} from "../../utils/Constant";
import {
  AssessmentTabs,
  formatCustomDate,
  calculateDayDifference,
  formatDate,
  assessmentStatus,
  examStatus,
  assessmentTabStatus,
  assessmentTypesMap,
  assessmentTypes,
} from "../../utils/helper/helper";
import "./style.scss";
import useIsMobile from "../../utils/helper/responsive";

const AssessmentList = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const [activeTab, setActiveTab] = useState<string>(
    statusParam ? statusParam : assessmentTabStatus.current
  );
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { learnerAssessmentList } = useAppSelector(state => state.assessment);
  const isMobile = useIsMobile();

  useEffect(() => {
    getAssessmentList(selectedFilterValues["assessment_type"]);
  }, [activeTab]);

  const getAssessmentList = value => {
    dispatch(
      getAssignedAssessmentForLearner({
        status: [activeTab],
        assessmentType: value,
      })
    ).then(response => {
      if (response?.payload?.assessments?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  const handleExam = assessment => {
    if (assessment?.assessment_type === assessmentTypesMap.competency) {
      return navigate("/self-assessment", {
        state: {
          participantId: assessment?.participant_id,
        },
      });
    }
    if (assessment?.status === assessmentStatus.yetToStart) {
      //Came via Start
      return navigate("/assessment-instruction", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment?.name,
          termsAndCondition: assessment?.term_and_condition,
        },
      });
    } else {
      //Came via Resume
      return navigate("/learning-assessment", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment.name,
        },
      });
    }
  };

  const getButtonTitle = status => {
    if (status === assessmentStatus.inProgress) {
      return Resume;
    } else if (status === assessmentStatus.yetToStart) {
      return Start;
    } else if (status === assessmentStatus.retake) {
      return Retake;
    }
  };

  const getButtonType = assessment => {
    const { status, cooling_period_date } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);
    const isCoolingPeriod = coolingPeriod > 0;

    if (
      activeTab === assessmentTabStatus.underReview ||
      activeTab === assessmentTabStatus.past
    ) {
      return null;
    }

    if (activeTab === assessmentTabStatus.upcoming) {
      return {
        title: Start,
        onClick: () => {
          return;
        },
        disabled: true,
      };
    }

    if (activeTab === assessmentTabStatus.current) {
      return {
        title: getButtonTitle(status),
        disabled: isCoolingPeriod,
        onClick: isCoolingPeriod ? null : () => handleExam(assessment),
      };
    }

    return null;
  };

  const renderAssessmentCard = (assessment, index) => {
    const {
      name,
      start_date,
      end_date,
      estimated_time,
      cooling_period_date,
      module_count,
      overall_question_count,
      end_time,
      score,
      status,
    } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);
    return (
      <Col key={index} sm={12} lg={4}>
        <Link
          to={"/assessment-list/assessment-details"}
          state={{ assessment, activeTab: activeTab }}
        >
          <GenericCard
            id={index}
            header={name}
            moduleDetails={[
              {
                label: Start,
                value: start_date ? formatCustomDate(start_date) : "-",
              },
              {
                label: End,
                value: end_date ? formatCustomDate(end_date) : "-",
              },
              {
                label: Time,
                value: estimated_time ? `${estimated_time} ${Mins}` : "-",
              },
              {
                label: Questions,
                value: overall_question_count ? overall_question_count : "-",
              },
              {
                label: Modules,
                value: module_count ? module_count : "-",
              },
            ]}
            isHideMoreOption={true}
            footerBadgeRight={
              coolingPeriod && activeTab !== assessmentTabStatus.past
                ? `${CoolingPeriod} ${coolingPeriod} ${Days}`
                : ""
            }
            footerBadge={
              activeTab === assessmentTabStatus.past &&
              `${FinalScore} ${score}%`
            }
            footerBadgeStatus={
              activeTab === assessmentTabStatus.past &&
              status === examStatus.passed
                ? "draft"
                : "rejected"
            }
            btnType={getButtonType(assessment)}
            altText={
              activeTab === assessmentTabStatus.upcoming &&
              `${Note}: ${StartNote}`
            }
            footerDate={
              activeTab === assessmentTabStatus.past
                ? `${CompletedOn} ${formatDate(end_time)}`
                : activeTab === assessmentTabStatus.underReview
                  ? `${SubmittedOn} ${formatDate(end_time)}`
                  : null
            }
          />
        </Link>
      </Col>
    );
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    navigate(`/assessment-list?status=${tabId}`);
  };

  const handleSearch = (query: string) => {
    const filteredAssessment = learnerAssessmentList?.assessments?.filter(
      assessment => assessment.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredAssessment || []);
    setNoDataFound(!filteredAssessment || filteredAssessment?.length === 0);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    getAssessmentList(values["assessment_type"]);
  };

  const handleClear = () => {
    getAssessmentList(null);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };
  return (
    <div className="page-body assessment-list page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={AssessmentTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>

            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-1">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: AssessmentType,
                      key: "assessment_type",
                      tooltipText: `${Select} ${AssessmentType}`,
                      options: [
                        { value: "", label: Select },
                        ...assessmentTypes,
                      ],
                      isMultiSelect: false,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">
        {noDataFound ? (
          <NoDataFound />
        ) : searchResults?.length > 0 ? (
          searchResults?.map((assessment, index) =>
            renderAssessmentCard(assessment, index)
          )
        ) : (
          learnerAssessmentList?.assessments?.map((assessment, index) =>
            renderAssessmentCard(assessment, index)
          )
        )}
      </Row>
    </div>
  );
};

export default AssessmentList;
