import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Input, Row } from "reactstrap";

import { Btn, MUIIcons, P } from "../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  courseTypeMap,
  PUBLISHED,
  sourceTypeMap,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import MapCourseModal from "../../../CommonElements/MapCourseModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import LearningPlanCourseCard, {
  LearningPlanCardData,
} from "../../../container/LearningPlanCourseCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getUserSpecificCourseList } from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  approveMappedYear,
  getILPCourse,
  mapYearAPI,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Add,
  Approve,
  ApproveYearBody,
  CourseType,
  Decline,
  DeclineYearBody,
  ILT,
  ModifyYear,
  PleaseSelectYear,
  Select,
  Submit,
  VILT,
  WBT,
  Webinar,
  Year,
} from "../../../utils/Constant";
import {
  courseFilterDropdownList,
  generateFiscalYearSeries,
  showToast,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

const AssignedILP = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { ilpCourseList } = useAppSelector(state => state.learningPlan);

  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<LearningPlanCardData[]>(
    []
  );
  const [noDataFound, setNoDataFound] = useState(false);
  const [fyYear, setFyYear] = useState(new Date().getFullYear().toString());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseFilterData, setCourseFilterData] = useState({});
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const fiscalYears = generateFiscalYearSeries(0, 10);
  // const [comments, setComments] = useState("");
  const [selectedYear, setSelectedYear] = useState<string>("");

  const toggleActionModal = () => {
    if (isActionModalOpen) {
      setSelectedCourseId("");
      setSelectedOption("");
      setSelectedYear("");
      // setComments("");
    }
    setIsActionModalOpen(!isActionModalOpen);
  };

  useEffect(() => {
    fetchPublishedCourses();
  }, [dispatch]);

  const fetchPublishedCourses = () => {
    const courseData = {};
    dispatch(
      getUserSpecificCourseList({
        status: [PUBLISHED],
        course_type: ALL_COURSE_FILTER_STATUS,
        user_id: userId,
      })
    ).then(response => {
      response?.payload?.course_list?.forEach(item => {
        if (!courseData[item?.course_type]) {
          courseData[item?.course_type] = [];
        }
        courseData[item?.course_type]?.push(item);
      });
    });
    setCourseFilterData(courseData);
  };

  useEffect(() => {
    fetchCourses();
  }, [dispatch, fyYear, selectedItems]);

  const fetchCourses = async () => {
    const courseType =
      selectedItems.length > 0 ? selectedItems : ALL_COURSE_FILTER_STATUS;
    await dispatch(
      getILPCourse({
        courseType: courseType,
        selfEnrollment: false,
        fyYear: fyYear,
        userId: userId,
      })
    );
  };

  useEffect(() => {
    const mappedCourseData = ilpCourseList?.mapped_courses?.map(course => {
      const domainNames =
        ilpCourseList?.domain[course?.course_id]?.map(
          domainItem => domainItem.name
        ) || [];

      return {
        courseId: course.id.toString(),
        courseName: course.course_name,
        domainName: domainNames,
        status:
          course.status === "requested"
            ? hasPermissionToComponent("USER_MANAGEMENT.APPROVE_MODIFIED_YEAR")
              ? "waiting_rm"
              : hasPermissionToComponent("USER_MANAGEMENT.MODIFY_YEAR")
                ? "requested_rm"
                : course.status
            : course.status,
        badgeTextColor: true,
        scheduledOn: course.created_at,
        completedOn: null,
        assignedBy: sourceTypeMap[course.source_type],
        courseType: courseTypeMap[course.course_type],
      };
    });

    setSearchResults(mappedCourseData);
    setNoDataFound(mappedCourseData?.length === 0);
  }, [ilpCourseList]);

  const handleSearch = (text: string) => {
    const filteredCourses = searchResults.filter(course =>
      course.courseName.toLowerCase().includes(text.toLowerCase())
    );
    setSearchResults(filteredCourses);
    setNoDataFound(filteredCourses.length === 0);
  };

  const handleDone = (values: { [key: string]: string[] }) => {
    if (values["course_type"]) {
      const newSelectedItems = values["course_type"];
      setSelectedItems(newSelectedItems);
      fetchCourses();
    }
  };

  const handleClear = () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
    fetchCourses();
  };

  const handleYearChange = (year: string) => {
    setFyYear(year);
  };

  const onDropdownClick = (id: string, name: string) => {
    setSelectedCourseId(id);
    setSelectedOption(name);
    toggleActionModal();
  };

  const renderCourses = (data: LearningPlanCardData[]) => {
    return data && data.length > 0 ? (
      <LearningPlanCourseCard
        data={data}
        onDropdownClick={onDropdownClick}
        showDropDown={true}
      />
    ) : (
      <NoDataFound />
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePrimaryClick = async () => {
    if (selectedOption === ModifyYear) {
      if (!validateModifyYear()) return;

      const year =
        parseInt(selectedYear.split(" ")[1].split("-")[0], 10) + 2000;
      const payload = {
        type: "modify",
        id: selectedCourseId,
        year: year,
        user_id: userId,
      };
      await dispatch(mapYearAPI({ content: payload }));
    }

    if (selectedOption === Approve) {
      await dispatch(
        approveMappedYear({
          content: { type: "approved", id: selectedCourseId },
        })
      );
    }

    if (selectedOption === Decline) {
      await dispatch(
        approveMappedYear({
          content: { type: "rejected", id: selectedCourseId },
        })
      );
    }

    await fetchCourses();
    toggleActionModal();
  };

  const validateModifyYear = () => {
    if (selectedYear === "") {
      showToast(PleaseSelectYear, "error");
      return false;
    }
    // if (comments === "") {
    //   showToast(PleaseAddComment, "error");
    //   return false;
    // }
    return true;
  };

  const renderModalBody = () => {
    if (selectedOption === ModifyYear) {
      return (
        <>
          <div className="mb-3">
            <span className="text-black d-block mb-1">{Year}</span>
            <Input
              type="select"
              value={selectedYear}
              onChange={e => setSelectedYear(e.target.value)}
            >
              <option value="" disabled>
                {Select} {Year}
              </option>
              {fiscalYears
                .filter(year => {
                  const currentYear = new Date().getFullYear();
                  const currentFiscalYear = `FY ${String(currentYear).slice(-2)}-${String(currentYear + 1).slice(-2)}`;
                  return year !== currentFiscalYear;
                })
                .map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
            </Input>
          </div>

          {/* <FormGroup>
            <LabelTooltip
              label={AddComment}
              tooltipText={`${Enter} ${Comment}`}
              important
            />
            <Input
              onChange={(e) => {
                if (e.target.value.length <= 255 || e.target.value === "") {
                  setComments(e.target.value);
                }
              }}
              rows={4}
              name="objective"
              type="textarea"
              placeholder={`${Enter} ${Comment}`}
            />
          </FormGroup> */}
        </>
      );
    }

    if (selectedOption === Approve) {
      return <P>{ApproveYearBody}</P>;
    }

    if (selectedOption === Decline) {
      return <P>{DeclineYearBody}</P>;
    }
  };

  return (
    <>
      <CommonModal
        sizeTitle={selectedOption}
        modalBodyClassName=""
        isOpen={isActionModalOpen}
        toggle={toggleActionModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryClick}
        primaryBtnText={Submit}
      >
        {renderModalBody()}
      </CommonModal>
      <MapCourseModal
        userId={userId}
        data={courseFilterData}
        isOpen={isModalOpen}
        toggle={toggleModal}
        fetchCourses={fetchCourses}
      />
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="7" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {ILT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.ilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {VILT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.vilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Webinar} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.webinar}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {WBT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.wbt}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end gap-3 align-items-center"
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: CourseType,
                        key: "course_type",
                        tooltipText: `${Select} ${CourseType}`,
                        options: courseFilterDropdownList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
                <YearNavigator
                  pastYears={20}
                  futureYears={20}
                  onYearChange={handleYearChange}
                />
              </div>
              <div>
                {userId &&
                  hasPermissionToComponent("USER_MANAGEMENT.ASSIGN_ILP") && (
                    <>
                      <Btn
                        icon={
                          <MUIIcons
                            size={16}
                            iconName="AddCircleOutlineOutlined"
                          />
                        }
                        color="primary"
                        onClick={toggleModal}
                      >
                        {Add}
                      </Btn>
                    </>
                  )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {noDataFound ? <NoDataFound /> : renderCourses(searchResults)}
    </>
  );
};

AssignedILP.propTypes = {
  userId: PropTypes.number,
};
export default AssignedILP;
