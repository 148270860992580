import React, { useState } from "react";
import { Card, CardBody, Tooltip } from "reactstrap";

import { Badges, MUIIcons, H4, P } from "../../AbstractElements";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import Divider from "../../CommonElements/Divider";
import UserCardDetails from "../../CommonElements/UserCardDetails";
import { DeleteOption } from "../../utils/Constant";
import {
  UserCardDetailsProps,
  UserCardProps,
} from "../../utils/helper/propTypes";
import AssessmentModuleCard from "../GenericCardModules";

interface GenericCardProps {
  id?: string;
  header: string;
  footerDate?: string;
  footerBadge?: string;
  userCardWithoutDetail?: UserCardProps;
  moduleDetails?: UserCardDetailsProps[];
  onHeaderDropdownClick?: (e) => void;
  isHideMoreOption?: boolean;
  status?: string;
  title?: string;
  footerBadgeRight?: string;
  btnType?: any;
  altText?: string;
  footerBadgeStatus?: string;
}

const GenericCard: React.FC<GenericCardProps> = ({
  id,
  header,
  footerDate,
  footerBadge,
  userCardWithoutDetail,
  moduleDetails,
  onHeaderDropdownClick,
  isHideMoreOption,
  status,
  title,
  footerBadgeRight,
  btnType,
  altText,
  footerBadgeStatus,
}) => {
  const truncatedHeader =
    header?.length > 30 ? `${header?.substring(0, 30)}...` : header;
  const [tooltipCourseOpen, setTooltipCourseOpen] = useState(false);
  const toggleCourseTooltip = () => setTooltipCourseOpen(!tooltipCourseOpen);

  return (
    <Card className="course-card-height">
      {status && (
        <CommonCardHeader
          status={status}
          badge="text"
          headClass="card-no-border pb-0"
          title={[title]}
        />
      )}
      <CardBody className="m-1">
        <div className="d-flex justify-content-between">
          <span id={`tooltip-course-name-${id}`}>
            <H4 className="header-text truncated-header" data-fulltext={header}>
              {truncatedHeader}
            </H4>
          </span>
          <Tooltip
            placement="top"
            isOpen={tooltipCourseOpen}
            target={`tooltip-course-name-${id}`}
            toggle={toggleCourseTooltip}
          >
            {header}
          </Tooltip>
          {!isHideMoreOption && (
            <CardHeaderDropDown
              onHeaderDropdownClick={onHeaderDropdownClick}
              mainTitle={true}
              firstItem={DeleteOption}
            />
          )}
        </div>
        {userCardWithoutDetail && (
          <div className="mt-3">
            <UserCardDetails
              imagePath={userCardWithoutDetail.imagePath}
              name={userCardWithoutDetail.name}
            />
          </div>
        )}
        <div className="mt-3">
          <AssessmentModuleCard details={moduleDetails} />
        </div>
        {(footerBadge || footerDate) && (
          <div className="card-bottom-section mt-auto">
            <Divider />
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
                {footerDate && (
                  <MUIIcons
                    className="text-gray mt-1"
                    size={18}
                    iconName="CalendarTodayOutlined"
                  />
                )}
                <P className="text-muted mt-1">{footerDate}</P>
              </div>
              {footerBadge && (
                <Badges
                  status={`${footerBadgeStatus ? footerBadgeStatus : "published"}`}
                  className="mt-1"
                >
                  {footerBadge}
                </Badges>
              )}
            </div>
          </div>
        )}

        {(footerBadgeRight || btnType?.title || altText) && (
          <>
            <Divider />
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {footerBadgeRight && (
                  <Badges status="published" className="mt-1">
                    {footerBadgeRight}
                  </Badges>
                )}

                {altText && <P className="text-muted mt-1">{altText}</P>}
              </div>

              {btnType?.title && (
                <div className="d-flex gap-2">
                  <div
                    className={`edit-details-button  ${btnType.disabled ? "disabled" : ""}`}
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      btnType.onClick();
                    }}
                  >
                    {btnType?.title}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default GenericCard;
