const Loader = () => {
  return (
    <>
      <div className="loader-wrapper">
        <div className="loader loader-1">
          <div className="loader-outter" />
          <div className="loader-inner" />
          <div className="loader-inner-1" />
        </div>
      </div>
    </>
  );
};

export default Loader;
