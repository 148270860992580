import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import Btn from "../../../CommonElements/Button";
import MapSgaModal from "../../../CommonElements/MapSgaModal";
import MUIIcons from "../../../CommonElements/MUIIcon/MUIIcons";
import SearchBar from "../../../CommonElements/SearchBar";
import NoDataFound from "../../../container/NoDataFound";
import SgaCard from "../../../container/SgaCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getListOfSga,
  getMappedSgaList,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { Add } from "../../../utils/Constant";
import { scrollToTop } from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

const ILPSga = ({ userId }) => {
  const dispatch = useAppDispatch();

  const { mappedSga, listOfSga } = useAppSelector(state => state.learningPlan);
  const { roleList, proficiencyList } = useAppSelector(state => state.master);

  const [isMapSgaModalOpen, setIsMapSgaModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [filteredSga, setFilteredSga] = useState([]);

  useEffect(() => {
    scrollToTop();
    if (isMapSgaModalOpen) {
      dispatch(getListOfSga());
    }
  }, [isMapSgaModalOpen]);

  useEffect(() => {
    if (mappedSga) {
      setSearchResults(mappedSga?.sga || []);
      setNoDataFound((mappedSga?.sga || []).length === 0);
    }
  }, [mappedSga]);

  useEffect(() => {
    (async () => {
      await dispatch(getRolesList({}));
      await dispatch(getProficiencyList());
      await dispatch(getMappedSgaList({ userId }));
    })();
  }, [dispatch, userId]);

  useEffect(() => {
    if (!Array.isArray(listOfSga)) return;

    const mappedSgaIds = new Set(mappedSga?.sga?.map(uSga => uSga.sga_id));

    const filteredArray = listOfSga.reduce((acc, item) => {
      const roleName = getRoleNameById(item.role_id);

      item.levels.forEach(level => {
        if (!mappedSgaIds.has(level.id)) {
          const levelNames = item.levels.map(level =>
            getLevelNameById(level.level_id)
          );
          acc.push({
            id: level.id,
            roleName,
            levelNames,
          });
        }
      });

      return acc;
    }, []);

    setFilteredSga(filteredArray);
  }, [listOfSga, mappedSga, proficiencyList, roleList]);

  const getRoleNameById = (id: number) => {
    const role = roleList?.job_role?.find(role => role.id === id);
    return role ? role.name : "";
  };

  const getLevelNameById = (id: number) => {
    const level = proficiencyList?.proficiency?.find(level => level.id === id);
    return level ? level.level_name : "";
  };

  const calculateCompletionPercentage = (
    totalAssessments: number,
    passedAssessments: number
  ): number => {
    if (totalAssessments <= 0) {
      return 0;
    }
    const percentage = (passedAssessments / totalAssessments) * 100;
    return Math.ceil(percentage);
  };

  const mapSgaModalToggle = isMapped => {
    setIsMapSgaModalOpen(!isMapSgaModalOpen);
    if (isMapped === true) {
      dispatch(getMappedSgaList({ userId }));
    }
  };

  const handleSearch = (text: string) => {
    const filteredSga =
      mappedSga?.sga?.filter(sga =>
        getRoleNameById(sga?.role_id).toLowerCase().includes(text.toLowerCase())
      ) || [];
    setSearchResults(filteredSga);
    setNoDataFound(filteredSga.length === 0);
  };

  const sgaOptions = filteredSga
    ?.map(item =>
      item.levelNames.map(levelName => ({
        label: `${item.roleName} - ${levelName}`,
        id: item.id,
      }))
    )
    .flat();

  return (
    <div className="sga-page">
      <Card className="mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col
              xs="12"
              lg="12"
              className="d-flex flex-column flex-lg-row justify-content-end"
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                {userId &&
                  hasPermissionToComponent("USER_MANAGEMENT.MAP_SGA") && (
                    <>
                      <Btn
                        icon={
                          <MUIIcons
                            size={16}
                            iconName="AddCircleOutlineOutlined"
                          />
                        }
                        color="primary"
                        onClick={mapSgaModalToggle}
                      >
                        {Add}
                      </Btn>
                      <MapSgaModal
                        sga={sgaOptions}
                        isOpen={isMapSgaModalOpen}
                        toggle={mapSgaModalToggle}
                        userId={userId}
                      />
                    </>
                  )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {noDataFound ? (
        <NoDataFound />
      ) : (
        searchResults?.map((item, index) => (
          <SgaCard
            key={index}
            role={getRoleNameById(item.role_id)}
            expertiseLevel={getLevelNameById(item.level_id)}
            percentageCompleted={calculateCompletionPercentage(
              item.total_assessment,
              item.passed_assessment
            )}
          />
        ))
      )}
    </div>
  );
};

ILPSga.propTypes = {
  userId: PropTypes.number,
};
export default ILPSga;
