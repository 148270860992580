import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import { Btn, H5, P } from "../../../../../AbstractElements";
import SimpleAccordion from "../../../../../CommonElements/Accordion";
import Divider from "../../../../../CommonElements/Divider";
import FileHandler from "../../../../../CommonElements/FileHandler";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import Graph from "../../../../../CommonElements/Graph";
import CommonModal from "../../../../../CommonElements/Modal";
import StatusDisplay from "../../../../../CommonElements/StatusDisplay";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  endLearnerAssessment,
  getAssessmentResultForReview,
  reviewLearnerAssessment,
} from "../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  Close,
  Competent,
  LearnerResponses,
  NotCompetent,
  PartialCompetent,
  PleaseReviewAll,
  Questions,
  RMResponses,
  SaveAsDraft,
  SelfAssessmentSummary,
  Submit,
} from "../../../../../utils/Constant";
import {
  competencyAnswersMap,
  getChartData,
  showToast,
} from "../../../../../utils/helper/helper";

const competencyAnswers = {
  [Competent]: "competent",
  [NotCompetent]: "not_competent",
  [PartialCompetent]: "partial_competent",
};

const ReviewCompetencyAssessment = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { participantId, review } = location.state || "";
  const { assessmentResultForReview } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [learnerResponses, setLearnerResponses] = useState({});
  const [trainerResponses, setTrainerResponses] = useState({});
  const [accordionData, setAccordionData] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [dataChange, setDataChange] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [moduleData, setModuleData] = useState({});
  const [responseCount, setResponseCount] = useState({
    competent: 0,
    not_competent: 0,
    partial_competent: 0,
  });

  useEffect(() => {
    setLearnerResponses({});
    setTrainerResponses({});
    setAccordionData([]);
    if (participantId) {
      dispatch(getAssessmentResultForReview({ participant_id: participantId }));
    }
  }, [participantId]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (assessmentResultForReview) {
      const modules = {};
      let count = 0;
      const selected = {};
      const learnerRes = {};
      const response = {
        competent: 0,
        not_competent: 0,
        partial_competent: 0,
      };
      assessmentResultForReview?.modules?.forEach(item => {
        count = count + item?.questions?.length;
        if (!modules[item?.module_id]) {
          modules[item?.module_id] = {
            id: item?.module_id,
            title: item?.module_name,
            questions: item?.questions,
          };
        } else {
          modules[item?.module_id].questions.concat(item?.questions);
        }

        item?.questions?.forEach(question => {
          if (!learnerRes[item?.module_id]) {
            learnerRes[item?.module_id] = {};
          }
          learnerRes[item?.module_id][question?.id] =
            question?.participant_response;

          response[question?.participant_response] =
            response[question?.participant_response] + 1;

          if (
            question?.reviewer_response &&
            !trainerResponses[item?.module_id]?.[question?.id]
          ) {
            if (!selected[item?.module_id]) {
              selected[item?.module_id] = {};
            }
            selected[item?.module_id][question?.id] =
              competencyAnswersMap[question?.reviewer_response];
          }
        });
      });

      setLearnerResponses(learnerRes);
      if (selected) {
        setDataChange(true);
        setTrainerResponses(prevResponses => ({
          ...prevResponses,
          ...selected,
        }));
      }
      setResponseCount(response);
      setModuleData(modules);
      setQuestionCount(count);
    }
  }, [assessmentResultForReview, participantId, dataChange]);

  useEffect(() => {
    const accordionData = Object.values(moduleData).map((module: any) => ({
      id: module?.id,
      accordionItemClass: "item-class",
      accordionHeaderClass: "header-class",
      spanClass: "span-class",
      accordionHeading: module.title,
      icon: true,
      bodyText: renderModule(module),
    }));
    setAccordionData(accordionData);
  }, [
    moduleData,
    assessmentResultForReview,
    participantId,
    dataChange,
    trainerResponses,
    learnerResponses,
  ]);

  const handleCheckboxChange = (moduleId, questionId, responseType, value) => {
    setDataChange(true);
    if (responseType === "learner") {
      setLearnerResponses(prevState => ({
        ...prevState,
        [moduleId]: {
          ...prevState[moduleId],
          [questionId]: value,
        },
      }));
    } else if (responseType === "trainer") {
      setTrainerResponses(prevState => ({
        ...prevState,
        [moduleId]: {
          ...prevState[moduleId],
          [questionId]: value,
        },
      }));
    }
  };

  const renderCheckboxGroup = (moduleId, questionId, responseType) => {
    setDataChange(false);
    const responseValue =
      responseType === "learner"
        ? learnerResponses[moduleId]?.[questionId]
        : trainerResponses[moduleId]?.[questionId];

    return (
      <div className="d-flex gap-4">
        <div className="form-check text-dark">
          <Input
            type="checkbox"
            className="custom-checkbox"
            checked={responseValue === Competent}
            onChange={() =>
              handleCheckboxChange(
                moduleId,
                questionId,
                responseType,
                Competent
              )
            }
          />
          <Label check>{Competent}</Label>
        </div>
        <div className="form-check text-dark">
          <Input
            type="checkbox"
            className="custom-checkbox"
            checked={responseValue === NotCompetent}
            onChange={() =>
              handleCheckboxChange(
                moduleId,
                questionId,
                responseType,
                NotCompetent
              )
            }
          />
          <Label check>{NotCompetent}</Label>
        </div>
        <div className="form-check text-dark">
          <Input
            type="checkbox"
            className="custom-checkbox"
            checked={responseValue === PartialCompetent}
            onChange={() =>
              handleCheckboxChange(
                moduleId,
                questionId,
                responseType,
                PartialCompetent
              )
            }
          />
          <Label check>{PartialCompetent}</Label>
        </div>
      </div>
    );
  };

  const renderModule = module => {
    return (
      <div className="mt-3">
        <Card className="generic-module-card">
          <CardBody className="m-2">
            <Row>
              <Col lg={!review ? 7 : 5}>
                <P className="fw-bold">{Questions}</P>
              </Col>
              <Col>
                <P className="fw-bold">{LearnerResponses}</P>
              </Col>
              {review && (
                <Col>
                  <P className="fw-bold">{RMResponses}</P>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
        {module?.questions?.map(question => {
          return (
            <div className="m-2 mt-3" key={question?.id}>
              <Row>
                <Col lg={!review ? 6 : 4}>{question?.question_text}</Col>
                <Col lg={1}>
                  {question?.media_url ? (
                    <FileHandler showIcon mediaUrl={question?.media_url} />
                  ) : null}
                </Col>
                {review ? (
                  <Col className="d-flex gap-3">
                    <Label>
                      {competencyAnswersMap[question?.participant_response]}
                    </Label>
                  </Col>
                ) : (
                  <Col className="d-flex gap-3">
                    {renderCheckboxGroup(module?.id, question.id, "learner")}
                  </Col>
                )}
                {review && (
                  <Col className="d-flex gap-3">
                    {renderCheckboxGroup(module?.id, question.id, "trainer")}
                  </Col>
                )}
              </Row>
              <Divider />
            </div>
          );
        })}
      </div>
    );
  };

  const onSubmit = () => {
    const answered = [];

    Object.keys(learnerResponses)?.forEach(item => {
      Object.keys(learnerResponses[item])?.forEach(answer => {
        answered.push({
          question_id: answer,
          answer_text: competencyAnswers[learnerResponses[item][answer]],
        });
      });
    });

    const payload = {
      participant_id: participantId,
      responses: answered,
    };
    dispatch(endLearnerAssessment({ data: payload }));
    navigate(`${process.env.PUBLIC_URL}/assessments/learner-assessments`);
  };

  const onReview = async type => {
    const modules = [];
    let count = 0;
    const trainerResponse = [];
    const learnerResponse = [];
    if (Object.keys(trainerResponses).length > 0) {
      Object.keys(trainerResponses)?.forEach(item => {
        const obj = {
          module_id: parseInt(item),
          questions: [],
        };
        Object.keys(trainerResponses[item])?.forEach(question => {
          trainerResponse.push(
            competencyAnswers[trainerResponses[item][question]]
          );
          count = count + 1;
          obj.questions.push({
            id: parseInt(question),
            reviewer_response:
              competencyAnswers[trainerResponses[item][question]],
          });
        });
        modules.push(obj);
      });
    }

    if (Object.keys(learnerResponses).length > 0) {
      Object.keys(learnerResponses)?.forEach(item => {
        Object.keys(learnerResponses[item])?.forEach(question => {
          learnerResponse.push(learnerResponses[item][question]);
        });
      });
    }

    if (type === "review_completed") {
      if (
        questionCount !== count ||
        learnerResponse?.length !== trainerResponse?.length
      ) {
        showToast(PleaseReviewAll, "error");
        return;
      }
    }

    const data = {
      participant_id: participantId,
      assessment_result_id: assessmentResultForReview?.assessment_result_id,
      is_competency: true,
      status: type,
      modules: modules,
    };

    await dispatch(reviewLearnerAssessment({ data: data }));

    if (type === "review_completed") {
      const data = getChartData(trainerResponse, learnerResponse);
      setChartData(data);
      toggleModal();
      return;
    }

    navigate(`${process.env.PUBLIC_URL}/assessments/assessment-details`, {
      state: {
        type: "competency",
        assessmentId: assessmentResultForReview?.assessment_id,
      },
    });
  };

  return (
    <div className="page-body mb-5">
      <CommonModal
        sizeTitle={SelfAssessmentSummary}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        primaryBtnText={Submit}
        onPrimaryBtnClick={() => {
          return navigate(
            `${process.env.PUBLIC_URL}/assessments/assessment-details`,
            {
              state: {
                type: "competency",
                assessmentId: assessmentResultForReview?.assessment_id,
              },
            }
          );
        }}
      >
        <Graph options={chartData?.options} series={chartData?.series} />
      </CommonModal>
      <Card>
        <CardBody className="d-flex justify-content-end gap-5 m-2">
          <StatusDisplay
            label={Competent}
            number={responseCount?.competent?.toString()}
          />
          <StatusDisplay
            label={NotCompetent}
            number={responseCount?.not_competent?.toString()}
          />
          <StatusDisplay
            label={PartialCompetent}
            number={responseCount?.partial_competent?.toString()}
          />
        </CardBody>
      </Card>
      {accordionData && <SimpleAccordion accordionList={accordionData} />}
      {!review && (
        <div>
          <FixedFooter>
            <Link to="/assessments/learner-assessments">
              <div
                className="edit-details-button"
                onClick={() => {
                  return;
                }}
              >
                <H5 className="primary-text-color pointer">{Close}</H5>
              </div>
            </Link>

            <Btn color="primary" onClick={onSubmit}>
              {Submit}
            </Btn>
          </FixedFooter>
        </div>
      )}
      {review && (
        <div>
          <FixedFooter>
            <div
              className="edit-details-button"
              onClick={() => onReview("draft")}
            >
              <H5 className="primary-text-color pointer">{SaveAsDraft}</H5>
            </div>

            <Btn
              color="primary"
              onClick={() => {
                onReview("review_completed");
              }}
            >
              {Submit}
            </Btn>
          </FixedFooter>
        </div>
      )}
    </div>
  );
};

export default ReviewCompetencyAssessment;
