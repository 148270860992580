import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { MUIIcons, H4, H5, P, H2 } from "../../AbstractElements";
import { courseTypeMap } from "../../Api/constants";
import DetailHeaderContainer from "../../CommonElements/DetailHeaderContainer";
import Divider from "../../CommonElements/Divider";
import IconHeader from "../../CommonElements/IconHeader";
import ImageList from "../../CommonElements/MultiImage";
import SliderModal from "../../CommonElements/SliderModal";
import VerticalDivider from "../../CommonElements/VericalDivider";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { getCourseContent } from "../../ReduxToolkit/Reducers/CourseSlice";
import { setCourseBasicInputFormValueToInitials } from "../../ReduxToolkit/Reducers/CourseWizardSlice";
import {
  Milestone,
  Modify,
  Objective,
  RejectReason,
  ViewRMDetails,
} from "../../utils/Constant";
import { getRandomInteger } from "../../utils/helper/helper";
import { sortCommentsByDate } from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";
import "./style.scss";
import Comments from "../Comments";
import CourseTimeLine from "../CourseTimeLine";

interface CourseCardProps {
  header: string;
  rm: string;
  statusBadge: React.ReactNode;
  imageData: any;
  courseContent: Course;
  userMap: any;
}

const BasicCourseDetails: React.FC<CourseCardProps> = ({
  header,
  rm,
  statusBadge,
  imageData,
  courseContent,
  userMap,
}) => {
  const [sliderModal, setSliderModal] = useState(false);
  const [modalContent, setModalContent] = useState<"rejects" | "milestone">(
    "milestone"
  );
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const sliderToggle = () => {
    setSliderModal(!sliderModal);
  };

  const getSliderModal = () => {
    let rejectedReasons = [];
    if (courseContent?.comment?.course) {
      rejectedReasons = sortCommentsByDate(courseContent?.comment?.course);
    }

    return (
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{modalContent === "milestone" ? Milestone : RejectReason}</H2>
        <Divider />
        {modalContent === "milestone" ? (
          <CourseTimeLine
            userMap={userMap}
            timeLines={courseContent?.milestone}
          />
        ) : (
          <Comments userMap={userMap} comments={rejectedReasons} />
        )}
      </SliderModal>
    );
  };
  return (
    <Card className="p-2">
      <CardBody>
        <div>
          <div className="d-flex align-items-center gap-2">
            <div className="w-100 d-flex justify-content-between">
              <div>
                <H4>{header}</H4>
                <P className="text-muted mt-2">
                  <b className="text-dark">{Objective} : </b>
                  {rm}
                </P>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div onClick={sliderToggle}>
                  <MUIIcons
                    className="m-2 primary-icon-color pointer"
                    iconName="ReportProblemOutlined"
                    size={20}
                    onClick={() => {
                      setModalContent("rejects");
                      setSliderModal(true);
                    }}
                  />
                  {getSliderModal()}
                </div>

                {hasPermissionToComponent("VIEW_TIME_LINE_COURSE") &&
                  courseContent?.milestone && (
                    <div onClick={sliderToggle}>
                      <MUIIcons
                        className="m-2 primary-icon-color pointer"
                        iconName="TimelineOutlined"
                        size={20}
                        onClick={() => {
                          setModalContent("milestone");
                          setSliderModal(true);
                        }}
                      />
                      {getSliderModal()}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <Divider />
          <Row className="mt-3">
            <Col lg="4" xs="12" sm="12">
              <Row>
                <Col lg="5" xs="5" sm="5" md="5" className="d-flex mb-4">
                  <IconHeader assign={"Status"} name={statusBadge} />
                </Col>
                <Col lg="1" xs="1" sm="1" md="1">
                  <VerticalDivider />
                </Col>
                <Col lg="5" xs="5" sm="5" md="4" className="mb-4">
                  <DetailHeaderContainer
                    assign={"Course ID"}
                    name={courseContent?.course_id}
                    key={getRandomInteger(1, 100)}
                  />
                </Col>
                {!isMobile && (
                  <Col lg="1" md="1" xs="1" sm="1">
                    <VerticalDivider />
                  </Col>
                )}
              </Row>
            </Col>

            <Col lg="4" sm="12" xs="12">
              <Row>
                <Col sm="5" xs="5" className="d-flex mb-4">
                  <DetailHeaderContainer
                    assign={"Course Type"}
                    name={courseTypeMap[courseContent?.course_type]}
                    key={getRandomInteger(1, 100)}
                  />
                </Col>
                <Col sm="1" xs="1" lg="1">
                  <VerticalDivider />
                </Col>
                <Col sm="5" xs="5" className="mb-4">
                  <IconHeader
                    assign="SME"
                    name={<ImageList userMap={userMap} data={imageData?.sme} />}
                  />
                </Col>
                {!isMobile && (
                  <Col sm="1" xs="1" lg="1">
                    <VerticalDivider />
                  </Col>
                )}
              </Row>
            </Col>

            <Col lg="4" sm="12" xs="12">
              <Row>
                <Col lg="4" sm="5" xs="5" className="d-flex mb-4">
                  <IconHeader
                    assign="Course Owner"
                    name={
                      <ImageList userMap={userMap} data={imageData?.trainer} />
                    }
                  />
                </Col>
                <Col sm="2" xs="2" lg="1">
                  <VerticalDivider />
                </Col>
                {hasPermissionToComponent("VIEW_RM_REQUEST") &&
                  courseContent?.course_request_id && (
                    <Col className="mt-4" lg="3" sm="5" xs="5">
                      <Link
                        to="/course-management/request-details"
                        state={{
                          courseRequestId: courseContent?.course_request_id,
                        }}
                      >
                        <H5 className="primary-text-color pointer mb-1">
                          {ViewRMDetails}
                        </H5>
                      </Link>
                    </Col>
                  )}
                {hasPermissionToComponent("EDIT_COURSE_DETAILS") && (
                  <Col className="mt-3" lg="4" sm="5" xs="5">
                    <Link
                      to="/course-management/add-course"
                      state={{ courseId: courseContent?.id, edit: true }}
                    >
                      <div
                        className="edit-details-button"
                        onClick={() => {
                          dispatch(
                            getCourseContent({
                              id: courseContent?.id?.toString(),
                            })
                          );
                          dispatch(setCourseBasicInputFormValueToInitials());
                        }}
                      >
                        <H5 className="primary-text-color pointer">{Modify}</H5>
                      </div>
                    </Link>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default BasicCourseDetails;
