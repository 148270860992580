import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { PUBLISHED } from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import SearchBar from "../../../CommonElements/SearchBar";
import QuestionBankCard from "../../../container/GenericCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getAssessmentsToBeReviewed,
  setAssessmentCatlogPayloadToInitials,
} from "../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  AssessmentType,
  CreatedOn,
  Mins,
  ModificationRequest,
  Module,
  Question,
  Select,
  Time,
  Type,
} from "../../../utils/Constant";
import {
  assessmentTypesNames,
  assessmentTypes,
  formatCustomDate,
} from "../../../utils/helper/helper";

import "./style.scss";

const ScheduledAssessments = () => {
  const dispatch = useAppDispatch();
  const { assessmentsToBeReviewed } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});

  const handleSearch = (query: string) => {
    const filteredAssessment = assessmentsToBeReviewed?.assessments?.filter(
      assessment => assessment.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredAssessment || []);
    setNoDataFound(!filteredAssessment || filteredAssessment?.length === 0);
  };

  useEffect(() => {
    getAssessments(selectedFilterValues["assessment_type"]);
  }, [dispatch]);

  const getAssessments = async value => {
    dispatch(
      getAssessmentsToBeReviewed({
        assessmentType: value,
      })
    ).then((response: any) => {
      if (response?.payload?.assessments?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const onHeaderDropdownClick = e => {
    e.preventDefault();
    toggleModal();
  };

  const renderAssessmentCatlogCard = (assessment: AssessmentCatlog, index) => {
    return (
      <Col key={index} sm={12} lg={4}>
        <Link
          to={"/assessments/assessment-details"}
          onClick={() => {
            dispatch(setAssessmentCatlogPayloadToInitials());
          }}
          state={{
            assessmentId: assessment?.assessment_id,
            type: assessment?.assessment_type,
            review: true,
          }}
        >
          <QuestionBankCard
            id={index}
            header={assessment?.name}
            footerBadge={
              assessment?.status === PUBLISHED &&
              assessment?.requests?.modification > 0 &&
              ModificationRequest
            }
            footerDate={CreatedOn + formatCustomDate(assessment?.created_at)}
            onHeaderDropdownClick={onHeaderDropdownClick}
            isHideMoreOption
            moduleDetails={[
              {
                label: Type,
                value:
                  assessmentTypesNames[assessment?.assessment_type?.toString()],
              },
              {
                label: Question,
                value: assessment?.overall_question_count?.toString(),
              },
              {
                label: Module,
                value: assessment?.module_count?.toString(),
              },
              {
                label: Time,
                value: assessment?.estimated_time
                  ? `${assessment?.estimated_time?.toString() + Mins}`
                  : "-",
              },
            ]}
          />
        </Link>
      </Col>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    getAssessments(values["assessment_type"]);
  };

  const handleClear = () => {
    getAssessments(null);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  return (
    <div className="page-body page-body-margin schedule-assessments">
      <Card className="p-1">
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-1">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: AssessmentType,
                      key: "assessment_type",
                      tooltipText: `${Select} ${AssessmentType}`,
                      options: [
                        { value: "", label: Select },
                        ...assessmentTypes,
                      ],
                      isMultiSelect: false,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">
        {noDataFound ? (
          <NoDataFound />
        ) : searchResults?.length > 0 ? (
          searchResults?.map((assessment, index) =>
            renderAssessmentCatlogCard(assessment, index)
          )
        ) : (
          assessmentsToBeReviewed?.assessments?.map((asessment, index) =>
            renderAssessmentCatlogCard(asessment, index)
          )
        )}
      </Row>
    </div>
  );
};

export default ScheduledAssessments;
