import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { Btn, MUIIcons, H4, H2 } from "../../../../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  LO_REJECTED_SME,
  LO_REJECTED_TM,
  LO_SUBMITTED,
  NEW_STATUS,
} from "../../../../../../Api/constants";
import SimpleAccordion from "../../../../../../CommonElements/Accordion";
import Divider from "../../../../../../CommonElements/Divider";
import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../../../CommonElements/MultiSelectDropdown";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import Comments from "../../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  createLearningOutcome,
  updateLearningOutcome,
} from "../../../../../../ReduxToolkit/Reducers/CourseStagesSlice";
import {
  getCompetencyList,
  getDomainList,
  getLearningOutcomeList,
  getProficiencyList,
} from "../../../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddLearningOutcomes,
  Comment,
  Competency,
  CompetencyRequired,
  Complete,
  Domain,
  DomainRequired,
  Enter,
  EnterComments,
  FacilitatorInformation,
  FacilitatorInformationRequired,
  LevelRequired,
  PleaseProvideRequiredInformation,
  ProficiencyLevel,
  Select,
  SelectAll,
  SelectLearningOutcome,
} from "../../../../../../utils/Constant";
import {
  facilitatorDefaultData,
  richTextSupportedItems,
  scrollToTop,
  showToast,
  sortCommentsByDate,
} from "../../../../../../utils/helper/helper";

const CreateLO = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { courseId } = location.state || "";
  const { domainList, competencyList, proficiencyList, learningOutcomeList } =
    useAppSelector(state => state.master);
  const [isComentRead, setIsComentRead] = useState(null);
  const [sliderModal, setSliderModal] = useState(false);
  const [dropdownValues, setDropdownValues] = useState({
    domain: "",
    competency: "",
    proficiency: "",
  });
  const { usersList } = useAppSelector(state => state.user);
  const { courseContent } = useAppSelector(state => state.course);
  const [comments, setComments] = useState("");
  const [commentList, setCommentList] = useState([]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [allOutcomeIds, setAllOutcomeIds] = useState([]);
  const [facilitatorInformation, setFacilitatorInformation] = useState("");

  const [domainListData, setDomainListData] = useState([]);
  const [competencyListData, setCompetencyListData] = useState([]);
  const [proficiencyListData, setProficiencyListData] = useState([]);
  const [outcomeListData, setOutcomeListData] = useState([]);
  const [selectedDomainIds, setSelectedDomainIds] = useState([]);
  const [selectedCompetencyIds, setSelectedCompetencyIds] = useState([]);
  const [selectedLevelIds, setSelectedLevelIds] = useState([]);
  const [userMap, setUserMap] = useState(null);
  const [errors, setErrors] = useState({
    domain: "",
    competency: "",
    level: "",
    facilitatorInformation: "",
  });

  useEffect(() => {
    dispatch(getCourseContent({ id: courseId }));
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getDomainList());
    dispatch(getProficiencyList());
  }, [dispatch, courseId]);

  useEffect(() => {
    const outcomes = [];
    const outcomeIds = [];
    learningOutcomeList?.learning_outcomes?.forEach(outcome => {
      outcomeIds.push(outcome?.id);
      outcomes.push({
        id: outcome?.id,
        accordionItemClass: "item-class",
        accordionHeaderClass: "header-class",
        iconWithTitle: <span>Icon with title</span>,
        spanClass: "span-class",
        accordionHeading: outcome?.outcome,
        icon: true,
        bodyText: outcome?.function,
      });
    });
    setAllOutcomeIds(outcomeIds);
    setOutcomeListData(outcomes);
  }, [learningOutcomeList]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.learning_outcome) {
      comment = sortCommentsByDate(courseContent.comment.learning_outcome);
    }
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "learning_outcome"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
    if (courseContent) {
      const selected = [];
      const dropIds = {
        domain: [],
        competency: [],
        proficiency: [],
      };
      courseContent?.learning_outcome?.forEach((item: any) => {
        selected.push(item.id);
        dropIds.domain.push(item?.domain_id);
        dropIds.competency.push(item?.competency_id);
        dropIds.proficiency.push(item?.proficiency_level_id);
      });
      if (dropIds.domain && dropIds.domain?.length > 0) {
        const domainArray = getUniqueArrayFromString(
          dropIds.domain?.toString()
        );
        dispatch(getCompetencyList({ domainId: domainArray?.toString() }));
      }
      setDropdownValues({
        domain: dropIds?.domain?.toString(),
        competency: dropIds?.competency?.toString(),
        proficiency: dropIds?.proficiency?.toString(),
      });
      setSelectedIds(selected);
      setFacilitatorInformation(
        courseContent?.facilitator_information || facilitatorDefaultData
      );
    }
    setCommentList(comment);
  }, [dispatch, courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  function getUniqueArrayFromString(inputString) {
    const arrayFromString = inputString.split(",");

    const uniqueArray = [...new Set(arrayFromString)];

    return uniqueArray;
  }

  useEffect(() => {
    const domains = [];
    const domainNames = [];
    const domainArray = getUniqueArrayFromString(dropdownValues?.domain);
    domainList?.domain?.forEach(element => {
      if (domainArray.includes(element.id.toString())) {
        domainNames.push({
          name: element.id.toString(),
          value: element.domain_name,
          label: element.domain_name,
        });
      }
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setSelectedDomainIds(domainNames);
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    const competencies = [];
    const competencyNames = [];
    const competencyArray = getUniqueArrayFromString(
      dropdownValues?.competency
    );
    competencyList?.competency?.forEach(element => {
      if (competencyArray.includes(element.id.toString())) {
        competencyNames.push({
          name: element.id.toString(),
          label: element.competency_name,
          value: element.competency_name,
        });
      }
      competencies.push({
        name: element.id.toString(),
        label: element.competency_name,
        value: element.competency_name,
      });
    });
    setSelectedCompetencyIds(competencyNames);
    setCompetencyListData(competencies);
  }, [competencyList]);

  useEffect(() => {
    const proficiencies = [];
    const levelNames = [];
    const levelArray = getUniqueArrayFromString(dropdownValues?.proficiency);
    proficiencyList?.proficiency?.forEach(element => {
      if (levelArray.includes(element.id.toString())) {
        levelNames.push({
          name: element.id.toString(),
          label: element.level_name,
          value: element.level_name,
        });
      }
      proficiencies.push({
        name: element.id.toString(),
        label: element.level_name,
        value: element.level_name,
      });
    });
    setSelectedLevelIds(levelNames);
    setProficiencyListData(proficiencies);
  }, [proficiencyList]);

  useEffect(() => {
    if (
      dropdownValues.domain !== "" &&
      dropdownValues.competency !== "" &&
      dropdownValues.proficiency !== ""
    ) {
      const domainArray = getUniqueArrayFromString(dropdownValues.domain);

      const competencyArray = getUniqueArrayFromString(
        dropdownValues.competency
      );

      const levelArray = getUniqueArrayFromString(dropdownValues.proficiency);

      dispatch(
        getLearningOutcomeList({
          domainId: domainArray?.toString(),
          competencyId: competencyArray?.toString(),
          proficiencyId: levelArray?.toString(),
        })
      );
    }
  }, [dispatch, selectedDomainIds, selectedCompetencyIds, selectedLevelIds]);

  const handleFacilitatorInformationChange = data => {
    setFacilitatorInformation(data);
  };

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "learning_outcome",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCheckBoxChange = id => {
    setSelectedIds(prevSelectedIds =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter(selectedId => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSelectAll = e => {
    if (e.target.checked) setSelectedIds(allOutcomeIds);
    else setSelectedIds([]);
  };

  const handleComplete = async () => {
    let isValid = true;

    const requiredFields = {
      domain: selectedDomainIds?.length === 0 ? DomainRequired : "",
      competency: selectedCompetencyIds?.length === 0 ? CompetencyRequired : "",
      level: selectedLevelIds?.length === 0 ? LevelRequired : "",
      facilitatorInformation:
        facilitatorInformation === "" ? FacilitatorInformationRequired : "",
    };

    const newErrors = {
      domain: requiredFields.domain || "",
      competency: requiredFields.competency || "",
      level: requiredFields.level || "",
      facilitatorInformation: requiredFields.facilitatorInformation || "",
    };

    isValid = !Object.values(newErrors).some(error => error !== "");

    if (!isValid) {
      setErrors(newErrors);
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
      return;
    }

    if (selectedIds.length === 0) {
      setErrors(newErrors);
      showToast(SelectLearningOutcome, "error");
      scrollToTop();
      return;
    }

    const learningOutcome = selectedIds.map(id => {
      const outcome = outcomeListData.find(item => item.id === id);
      return {
        outcome: outcome?.accordionHeading,
        function: outcome?.bodyText,
        learning_outcome_id: id,
      };
    });

    const data = {
      course_id: courseId,
      facilitator_information: facilitatorInformation,
      learning_outcome: learningOutcome,
    };

    if (
      courseContent?.status === LO_SUBMITTED ||
      courseContent?.status === LO_REJECTED_SME ||
      courseContent?.status === LO_REJECTED_TM ||
      (courseContent?.learning_outcome &&
        courseContent?.learning_outcome?.length > 0)
    ) {
      await dispatch(updateLearningOutcome({ learningOutcome: data }));
    } else await dispatch(createLearningOutcome({ learningOutcome: data }));
    dispatch(getCourseContent({ id: courseId }));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details`,
      { state: { courseId: courseId } }
    );
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setComments(e.target.value);
  };

  const onSendComments = async () => {
    if (comments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "learning_outcome",
        comment: comments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const isAllSelected = allOutcomeIds.every(element =>
    selectedIds.includes(element)
  );

  const getMultiDropdownDataData = (name: string, value: any[]) => {
    setOutcomeListData([]);
    setSelectedIds([]);
    if (name === "domain") {
      const domainMap = [];
      const domainValues = [];
      domainListData.forEach((element: any) => {
        if (value.includes(element.name)) {
          domainValues.push(element.name?.toString());
          domainMap.push({
            name: element.name?.toString(),
            value: element.label,
            label: element.label,
          });
        }
      });
      setDropdownValues({
        ...dropdownValues,
        domain: domainValues?.toString(),
      });
      setSelectedDomainIds(domainMap);
    }
    if (name === "competency") {
      const competencyMap = [];
      const competencyValues = [];
      competencyListData.forEach((element: any) => {
        if (value.includes(element.name)) {
          competencyValues.push(element.name?.toString());
          competencyMap.push({
            name: element.name?.toString(),
            value: element.label,
            label: element.label,
          });
        }
      });
      setDropdownValues({
        ...dropdownValues,
        competency: competencyValues?.toString(),
      });
      setSelectedCompetencyIds(competencyMap);
    }

    if (name === "level") {
      const levelMap = [];
      const levelValues = [];
      proficiencyListData.forEach((element: any) => {
        if (value.includes(element.name)) {
          levelValues.push(element.name?.toString());
          levelMap.push({
            name: element.name?.toString(),
            value: element.label,
            label: element.label,
          });
        }
      });
      setDropdownValues({
        ...dropdownValues,
        proficiency: levelValues?.toString(),
      });
      setSelectedLevelIds(levelMap);
    }
  };

  return (
    <div className="page-body">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={comments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <H4 className="mt-2">{AddLearningOutcomes}</H4>
          <div className="comment-icon-container">
            <MUIIcons
              size={24}
              onClick={sliderToggle}
              className="primary-icon-color mt-2 pointer"
              iconName="ChatBubbleOutlineOutlined"
            />
            {isComentRead != null && isComentRead && (
              <span className="red-dot"></span>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <Row className="mt-4">
            <Col sm="12" lg="4">
              <FormGroup>
                <LabelTooltip
                  label={Domain}
                  tooltipText={`${Select} ${Domain}`}
                  important
                />
                <MultiSelectDropdown
                  onChange={values => {
                    setCompetencyListData([]);
                    const domainArray = getUniqueArrayFromString(
                      values?.toString()
                    );
                    if (domainArray?.length > 0 && domainArray[0] != "")
                      dispatch(
                        getCompetencyList({ domainId: domainArray?.toString() })
                      );
                    getMultiDropdownDataData("domain", values);
                  }}
                  options={domainListData}
                  placeholder={"Select Domain"}
                  defaultSelected={selectedDomainIds}
                />
                <div className="mt-1 text-sm text-danger">{errors.domain}</div>
              </FormGroup>
            </Col>
            <Col sm="12" lg="4">
              <FormGroup>
                <LabelTooltip
                  label={Competency}
                  tooltipText={`${Select} ${Competency}`}
                  important
                />
                <MultiSelectDropdown
                  onChange={values => {
                    getMultiDropdownDataData("competency", values);
                  }}
                  options={competencyListData}
                  placeholder={"Select Competency"}
                  defaultSelected={selectedCompetencyIds}
                />
                <div className="mt-1 text-sm text-danger">
                  {errors.competency}
                </div>
              </FormGroup>
            </Col>
            <Col sm="12" lg="4">
              <FormGroup>
                <LabelTooltip
                  label={ProficiencyLevel}
                  tooltipText={`${Select} ${ProficiencyLevel}`}
                  important
                />
                <MultiSelectDropdown
                  onChange={values => {
                    getMultiDropdownDataData("level", values);
                  }}
                  options={proficiencyListData}
                  placeholder={"Select Proficiency Level"}
                  defaultSelected={selectedLevelIds}
                />
                <div className="mt-1 text-sm text-danger">{errors.level}</div>
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-5">
            <div className="form-check text-dark">
              {allOutcomeIds?.length > 0 && (
                <div>
                  <Input
                    checked={isAllSelected}
                    className="border-primary custom-checkbox"
                    id="selectAll"
                    type="checkbox"
                    onChange={e => handleSelectAll(e)}
                  />
                  <Label className="text-dark" htmlFor="selectAll" check>
                    {SelectAll}
                  </Label>
                </div>
              )}
            </div>
            <div className="form-group"></div>
          </div>
          <div className="mt-3">
            <SimpleAccordion
              onCheckBoxChange={onCheckBoxChange}
              selectedIds={selectedIds}
              accordionList={outcomeListData}
              showCheckbox
            />
          </div>
          <div>
            <FormGroup className="mt-4">
              <LabelTooltip
                label={FacilitatorInformation}
                tooltipText={`${Enter} ${FacilitatorInformation}`}
                important
              />
              <CKEditor
                onChange={(_event, editor) => {
                  const data = editor.getData();
                  handleFacilitatorInformationChange(data);
                }}
                config={{
                  toolbar: {
                    items: richTextSupportedItems,
                  },
                }}
                editor={ClassicEditor}
                data={facilitatorInformation || facilitatorDefaultData}
              />
              <div className="mt-1 text-sm text-danger">
                {errors.facilitatorInformation}
              </div>
            </FormGroup>
          </div>
        </CardBody>
        {(courseContent?.status === NEW_STATUS ||
          courseContent?.status === LO_SUBMITTED ||
          courseContent?.status === LO_REJECTED_TM ||
          courseContent?.status === LO_REJECTED_SME) && (
          <CardFooter className="d-flex justify-content-end">
            <Row>
              <Col xs="auto">
                <Btn color="primary" onClick={handleComplete}>
                  {Complete}
                </Btn>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default CreateLO;
