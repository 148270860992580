import React from "react";
import { Nav, NavLink } from "reactstrap";

interface TabItem {
  label: string;
  href: string;
  tabId: string;
  onClick?: () => void;
}

interface VerticalTabsProps {
  tabItems: TabItem[];
  activeTab: string;
  setActiveTab: (tabId: string) => void;
}

const VerticalTabs: React.FC<VerticalTabsProps> = ({
  tabItems,
  activeTab,
  setActiveTab,
}) => {
  return (
    <Nav pills className="flex-column nav-success pointer">
      {tabItems.map(item => (
        <NavLink
          key={item.tabId}
          className={activeTab === item.tabId ? "active" : ""}
          onClick={() => {
            setActiveTab(item.tabId);
            if (item.onClick) item.onClick();
          }}
        >
          {item.label}
        </NavLink>
      ))}
    </Nav>
  );
};

export default VerticalTabs;
